import Field, { FieldProps } from '@/components/Field';
import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getFlags } from '@/api/services/filters/flags';

type TripFlagDropdownProps = Omit<QueryDropdownProps, 'query'>;
const TripFlagDropdown = ({ name = 'tripFlags', ...props }: TripFlagDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getFlags();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Flags' } }} />;
};

export const TripFlagDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & TripFlagDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <TripFlagDropdown {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default TripFlagDropdown;
