import './styles.scss';

import { DATE_FE_FORMAT_FULL, TIME_FORMAT_FULL } from '@/constants';
import { Datetime, getClasses } from 'utils';
import Field, { FieldProps } from '@/components/Field';
import { InputGroup, InputGroupProps } from 'react-bootstrap';
import React, { useMemo } from 'react';

import DateInput from 'components/DateInput';
import { InputProps } from '@/components/Input';
import TimeInput from 'components/TimeInput';

export type DatetimeInputProps = {
  value: string;
  disabled?: boolean | [boolean?, boolean?];
} & InputGroupProps &
  Omit<InputProps, 'disabled'>;

const DatetimeInput = ({ name, value, onChange, disabled, ...inputGroupProps }: DatetimeInputProps): JSX.Element => {
  const datetime = useMemo((): Datetime => new Datetime(value), [value]);

  const handleDateChange = (date) => {
    if (Array.isArray(date)) date = date[0];
    if (!date) return;
    datetime.setDate(date);
    onChange(datetime.toString());
  };
  const handleTimeChange = (event) => {
    const { value: time } = event?.target || {};
    console.log('TIME', time);
    if (!time) return;
    datetime.setTime(time);
    onChange(datetime.toString());
  };

  return (
    <InputGroup {...inputGroupProps} className={getClasses('DatetimeInput', inputGroupProps.className)} name={name ? name : undefined}>
      <DateInput.New
        name={name ? `${name}-DateInput` : undefined}
        value={[value ? datetime.format(DATE_FE_FORMAT_FULL) : '']}
        onChange={handleDateChange}
        placeholder="--/--/----"
        disabled={disabled === true || disabled?.[0] === true}
      />
      <TimeInput
        name={name ? `${name}-TimeInput` : undefined}
        value={value ? datetime.format(TIME_FORMAT_FULL) : ''}
        onChange={handleTimeChange}
        suffixIcon={null}
        disabled={disabled === true || disabled?.[1] === true}
      />
    </InputGroup>
  );
};

export type DatetimeInputFieldProps = DatetimeInputProps & Omit<FieldProps, 'children'>;
export const DatetimeInputField = ({ label, feedback, valid, required, ...inputProps }: DatetimeInputFieldProps): JSX.Element => (
  <Field label={label} feedback={feedback} valid={valid} required={required}>
    <DatetimeInput {...inputProps} />
  </Field>
);

export default DatetimeInput;
