import { RefObject, useRef, useState } from 'react';
import useIntersectionObserver, { IntersectionObserverDependencies } from '@/hooks/useIntersectionObserver';

export const useIsVisibleWithRef = (
  dependencies?: IntersectionObserverDependencies,
  options?: Partial<IntersectionObserverInit> | ((ref: RefObject<HTMLDivElement>) => Partial<IntersectionObserverInit>)
): boolean => {
  const [isInView, setIsInView] = useState(false);

  useIntersectionObserver(
    (entries: IntersectionObserverEntry[]): void => {
      setIsInView(entries.some((entry: IntersectionObserverEntry): boolean => entry.isIntersecting));
    },
    dependencies,
    options
  );

  return isInView;
};

const useIsVisible = (
  dependencies?: unknown[],
  options?: Partial<IntersectionObserverInit> | ((ref: RefObject<HTMLDivElement>) => Partial<IntersectionObserverInit>)
): [RefObject<HTMLDivElement>, boolean] => {
  const ref = useRef(null);
  const isInView = useIsVisibleWithRef([ref, ...(dependencies || [])], options);
  return [ref, isInView];
};

export default useIsVisible;
