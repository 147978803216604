import Field, { FieldProps } from '@/components/Field';
import React, { MutableRefObject, useRef } from 'react';
import { TimePicker, TimeRangePickerProps } from 'antd';

import { Button } from 'react-bootstrap';
import { TIME_FORMAT_FULL } from '@/constants';
import dayjs from 'dayjs';
import { onEnter } from '@/utils';
import useClassNames from '@/hooks/useClassNames';

export type TimeInputProps = {
  className?: string;
  name: string;
  value: string;
  onChange: any;
  onBlur?: any;
  isValid?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  suffixIcon?: React.ReactNode;
};

const TimeInput = ({
  className,
  name,
  value,
  onChange,
  isValid,
  isInvalid,
  readOnly,
  disabled,
  onBlur,
  suffixIcon = <i className="sv sv-clock" />,
}: TimeInputProps): JSX.Element => {
  const ref: MutableRefObject<any> = useRef();
  const classes = useClassNames(
    className,
    'flex-grow-1 border',
    isValid !== undefined && !!isValid ? 'isValid border-success' : undefined,
    isInvalid !== undefined && !!isInvalid ? 'isInvalid border-danger' : undefined
  );
  const handleChange = (value: any): void => {
    if (value && typeof value !== 'string') {
      value = value.format(TIME_FORMAT_FULL);
    }
    onChange({ target: { name, value } });
  };
  const handleBlur = (): void => {
    const event = { target: ref?.current?.nativeElement?.querySelector('input') };
    // If the value does not contain a colon and 4 characters long then
    // assume it's a 24-hour format and let handleChange function to return the proper format with colon.
    // Example: 1255 -> 12:55
    if (!!event.target.value.length && !event.target.value.includes(':')) {
      event.target.value = event.target.value.padStart(4, '0');
    }
    handleChange(event?.target?.value);
    if (onBlur) return onBlur(event);
  };
  const handleEnter = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    handleChange(event?.target?.value);
    ref.current.blur();
  };

  return (
    <TimePicker
      className={classes}
      name={name}
      value={value ? dayjs(value, 'hh:mm') : undefined}
      onChange={handleChange}
      onKeyDown={onEnter(handleEnter)}
      onBlur={handleBlur}
      size="small"
      suffixIcon={suffixIcon}
      allowClear={false}
      inputReadOnly={!!readOnly}
      disabled={disabled}
      placeholder="--:--"
      format="HH:mm"
      showNow={false}
      needConfirm={false}
      renderExtraFooter={(): JSX.Element => (
        <div className="ant-picker-footer">
          <ul className="ant-picker-ranges d-flex w-100 justify-content-between p-0">
            <li className="ant-picker-now">
              <Button
                variant="icon"
                className="ant-picker-now-btn p-0 text-primary"
                aria-disabled="false"
                onClick={(): void => handleChange(dayjs().format(TIME_FORMAT_FULL))}
              >
                Now
              </Button>
            </li>
            <li className="ant-picker-ok">
              <Button
                size="sm"
                onClick={(): void => {
                  ref.current.focus();
                  ref.current.blur();
                }}
              >
                OK
              </Button>
            </li>
          </ul>
        </div>
      )}
      ref={ref}
    />
  );
};

export const TimeInputField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & TimeInputProps): React.ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <TimeInput className="w-100" {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export type TimeInputRangeProps = { value: Array<string | number | Date | dayjs.Dayjs> } & TimeRangePickerProps;
const TimeRangePicker = ({ value, ...props }: TimeInputRangeProps): React.JSX.Element => (
  <TimePicker.RangePicker
    {...props}
    value={Array.isArray(value) && value?.length === 2 ? [dayjs(value[0], 'hh:mm'), dayjs(value[1], 'hh:mm')] : undefined}
    suffixIcon={<i className="sv sv-time {font-size:1.5rem;}" />}
    allowClear={false}
    format="HH:mm"
  />
);
TimeInput.Range = TimeRangePicker;

export default TimeInput;
