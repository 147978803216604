import { Col, Row, RowProps } from 'react-bootstrap';

import CalendarDate from '@/components/Calendar/CalendarDate';
import { DAYS_OF_THE_WEEK } from '@/constants';
import { Dayjs } from 'dayjs';
import { getClasses } from '@/utils';
import { useMemo } from 'react';

export type CalendarWeekProps = {
  dates?: Dayjs[];
} & RowProps;

const CalendarWeek = ({ dates, ...rowProps }: CalendarWeekProps): JSX.Element => {
  // Get the classes for the week.
  const classes = getClasses('Calendar-Week', rowProps.className);
  // Generate the display for the week.
  const display = useMemo<JSX.Element[]>((): JSX.Element[] => generateDates(dates), [dates]);

  return (
    <Row {...rowProps} className={classes}>
      {display}
    </Row>
  );
};

const generateDates = (dates: Dayjs[]): JSX.Element[] => {
  // Return the dates if provided.
  if (dates?.length > 0) return dates.map((day: Dayjs, index: number): JSX.Element => <CalendarDate value={day} key={index} />);

  // Return the day names if no dates are provided.
  return DAYS_OF_THE_WEEK.map(
    (day: string): JSX.Element => (
      <Col key={day} className="Calendar-Day">
        <div className="Calendar-Date">{day[0]}</div>
      </Col>
    )
  );
};

export default CalendarWeek;
