import { TripKindEnum, TripStatusEnum, TripTypeEnum } from '@/models/gen/graphql';
import { Validation, diffKey } from '@/utils';

const keys = [
  'airportCode',
  'payerProviderId',
  'providerId',
  'servicerIataAirlineCode',
  'puLocationId',
  'doLocationId',
  'scheduled',
  'scheduledDays',
  'flightNumber',
  'loopName',
  'attendants',
  'pilots',
  'type',
  'kind',
  'status',
  'crewId',
  'firstName',
  'lastName',
  'stops',
];
const getValidityType = (isValid: undefined | boolean, diff: boolean = false): Validation.ValidityType => {
  if (isValid === undefined) return Validation.ValidityType.INVARIANT;
  if (diff) return isValid ? Validation.ValidityType.VALID_WARNING : Validation.ValidityType.INVALID_WARNING;
  return isValid ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID;
};
export const EditTripsModalValidator = (trip: any, trips: any[] = [], upsProviderId: string): any => {
  const validateKey = (key: string, val: any): any => {
    const isDiff = diffKey(key, [trip, ...Object.values(trips)]).length > 1;
    let isValid = undefined;
    const message = '';
    switch (key) {
      case 'airportCode': {
        isValid = !!val;
        break;
      }
      case 'payerProviderId': {
        isValid = !!val;
        break;
      }
      case 'providerId': {
        isValid = !!val;
        break;
      }
      case 'servicerIataAirlineCode': {
        isValid = !!val;
        break;
      }
      case 'puLocationId': {
        isValid = !!val;
        break;
      }
      case 'doLocationId': {
        isValid = !!val;
        break;
      }
      case 'scheduled': {
        isValid = !!val;
        break;
      }
      case 'scheduledDays': {
        isValid = Array.isArray(val) && val?.length > 0;
        break;
      }
      case 'loopName':
      case 'flightNumber': {
        isValid =
          trip?.flightNumber === 0 ||
          new RegExp(/^(\d{1,4})$/).test(trip?.flightNumber || '') ||
          (trip?.loopName !== '' && trip?.loopName?.length > 0);
        break;
      }
      case 'type': {
        isValid = Object.values(TripTypeEnum).includes(val);
        break;
      }
      case 'kind': {
        isValid = Object.values(TripKindEnum).includes(val);
        break;
      }
      case 'status': {
        isValid = Object.values(TripStatusEnum).includes(val);
        break;
      }
      case 'crewId':
      case 'firstName':
      case 'lastName': {
        isValid = trip?.payerProviderId === upsProviderId ? !!val : undefined;
        break;
      }
      case 'stops': {
        isValid =
          !val?.length || !val.map((stop: any): boolean => !!Validation.isValidUUID(stop?.locationId) && !!stop?.scheduled).includes(false);
        break;
      }
    }
    return { valid: getValidityType(isValid, isDiff), message };
  };
  const result = {};
  keys.forEach((key: string): any => (result[key] = validateKey(key, trip?.[key])));
  return result;
};
EditTripsModalValidator.keys = keys;
