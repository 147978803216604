import { RefObject, useEffect, useRef } from 'react';

/* Observer Options
  View full documentation at: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
*/
const DEFAULT_OBSERVER_OPTIONS: IntersectionObserverInit = {
  root: null, // Defaults to the browser viewport if not provided.
  rootMargin: '0px 0px 0px 0px', // Offset from the viewport to trigger at.
  threshold: 0.5, // 50% of the element is visible.
};

export type IntersectionObserverDependencies = [RefObject<HTMLDivElement>, ...unknown[]];
export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  dependencies: IntersectionObserverDependencies,
  options?: Partial<IntersectionObserverInit> | ((ref: RefObject<HTMLDivElement>) => Partial<IntersectionObserverInit>)
): void => {
  const rootRef = useRef<HTMLDivElement>(document.querySelector('.RouteContent') || document.querySelector('#root'));
  const [ref, ...deps] = dependencies;
  if (options instanceof Function) {
    options = { ...DEFAULT_OBSERVER_OPTIONS, root: rootRef.current, ...options(ref) };
  } else {
    options = { ...DEFAULT_OBSERVER_OPTIONS, root: rootRef.current, ...options };
  }

  useEffect((): (() => void) => {
    const observer = new IntersectionObserver(callback, options);
    const currentRef = ref?.current;
    if (currentRef) observer.observe(currentRef);
    return (): void => {
      if (currentRef) observer.unobserve(currentRef);
    };
    /* Ignoring for Syntax
      ES Lint complains that it is unable to view the types of the dependencies to
      more accurately warn us of potential issues. We are ignoring this warning because
      we don't care what the deps are, only if they change.
    */ // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps]);
};

export default useIntersectionObserver;
