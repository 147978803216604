import { RunAssignVehicleToDriverInput, Trip } from '@/models/gen/graphql';
import { handleError, parsePhoneNumber, titleCase } from '@/utils';
import { useCallback, useEffect, useState } from 'react';

import { AssignVehicleDropdown } from '@/components/VehicleDropdown';
import { Button } from 'react-bootstrap';
import React from 'react';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import assignVehicleToDriverBulk from '@/api/services/vehicles/assignVehicleToDriver';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

const AssignVehicleCell = (): React.JSX.Element => {
  const { onRefetch } = useTripsTableContext();
  const { data: { id, trackFlight, scheduled, airportCode, driverId, vehicle } = {} } = useSimpleTableRowContext<Trip>();
  const { actual } = trackFlight || {};
  const [value, setValue] = useState('');
  const [editing, setEditing] = useState(false);

  const handleChange = useCallback(
    async (value: string): Promise<void> => {
      try {
        if (!value || value === vehicle?.id) return;
        if (!id) throw new Error('No trip found.');
        setValue(value);
        const input: RunAssignVehicleToDriverInput = {
          userId: driverId || '',
          vehicleId: value,
          tripId: id || null,
          scheduled: scheduled || null,
        };
        await assignVehicleToDriverBulk(input);
        onRefetch();
      } catch (err) {
        handleError(err, { notification: { title: 'Assign Vehicle To Trip' } });
        setValue(vehicle?.id || '');
      }
    },
    [vehicle?.id, id, driverId, scheduled, onRefetch]
  );

  useEffect(() => {
    if (value === (vehicle?.id || '')) return;
    setValue(vehicle?.id || '');
  }, [value, vehicle?.id]);

  return (
    <SimpleTableCell name="VEHICLE">
      <div className="w-100 h-100">
        {editing && (
          <AssignVehicleDropdown
            name="vehicle"
            value={value}
            className="DropdownCell h-100 {padding-left:0;padding-right:0!;text-align:center;}>input"
            onChange={(val) => {
              handleChange(val);
              setEditing(false);
            }}
            onBlur={() => setEditing(false)}
            airportCode={airportCode}
            scheduled={scheduled}
            actual={actual}
            details={{
              id: vehicle?.id,
              trackingId: vehicle?.trackingId,
            }}
            options={{
              onToggle: setEditing,
              lazyLoadItems: true,
              openOnMount: true,
              showLoadingSpinner: false,
              showRefreshButton: false,
              showChevron: false,
              showClearButton: false,
              autoSelect: false,
              disabled: !driverId,
              locale: { 'Select...': vehicle?.trackingId || '--' },
            }}
          />
        )}
        {!editing && (
          <Tooltip
            className="w-100 h-100"
            content={
              vehicle && (
                <>
                  <div className="text-center">{`Vehicle #${vehicle?.trackingId || '--'}`}</div>
                  <div>{`Phone: ${parsePhoneNumber(vehicle?.phoneNumber || '', ' ')}`}</div>
                  <div className="text-center">{`Capacity: ${vehicle?.capacity || '--'}`}</div>
                  <div className="text-center">{titleCase(`${vehicle?.make || ''} ${vehicle?.model || ''}`)}</div>
                </>
              )
            }
          >
            <Button
              variant="icon"
              className="w-100 h-100 p-0 text-center"
              onClick={() => {
                if (driverId) setEditing(true);
              }}
            >
              {vehicle?.trackingId || '--'}
            </Button>
          </Tooltip>
        )}
      </div>
    </SimpleTableCell>
  );
};

export default React.memo(AssignVehicleCell);
