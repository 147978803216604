import { Datetime, getClasses, zeroPadFlightNumber } from '@/utils';
import React, { HTMLAttributes } from 'react';
import { TrackFlightTypeEnum, Trip } from '@/models/gen/graphql';
import useAirlineTripSettings, { AirlineColumnKeyEnum } from '@/features/AirlineTrips/components/TripSettingsModal/hook';

import ActualCell from '@/features/AirlineTrips/components/TripsTable/Cells/ActualTimeCell';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import LocationCell from '@/features/AirlineTrips/components/TripsTable/Cells/LocationCell';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import { isUpcomingTrip } from '@/features/AirlineTrips/components/TripsTable/utils';
import useAirlineTripTableState from '@/features/AirlineTrips/components/TripsTable/hook';

type AirlineTripsTableRowProps = {} & HTMLAttributes<HTMLDivElement>;

const AirlineTripsTableRow = (
  { id, ...divProps }: AirlineTripsTableRowProps,
  ref: React.ForwardedRef<HTMLTableRowElement>
): React.JSX.Element => {
  const columns = useAirlineTripSettings(({ state }) => state.columns);
  const data = useAirlineTripTableState(({ state }) => state.trips.get(id) || ({} as Trip));

  const tripScheduledDatetime = data.scheduled ? new Datetime(data.scheduled) : undefined;
  const tripScheduledUtcDatetime = data.scheduledUtc ? new Datetime(data.scheduledUtc) : undefined;
  const tripFlightNumber = data.flightNumber ? zeroPadFlightNumber(data.flightNumber) : '--';
  const tripKind = data?.kind ? data?.kind : undefined;
  const tripType = data?.type ? data?.type : undefined;
  const tripStatus = data?.status ? data?.status : undefined;
  const tripIsLate = data?.isLate ? 'LATE' : undefined;
  const tripIsUpcoming = tripScheduledUtcDatetime && isUpcomingTrip(tripScheduledUtcDatetime) ? 'UPCOMING' : undefined;
  const tripIsCompleted = data?.state?.completion ? 'COMPLETED' : undefined;
  const tripIsDeleted = data?.deletedAt ? 'DELETED' : undefined;
  const tripIsCurbside = data?.curbsideAt ? 'CURBSIDE' : undefined;
  const tripIsPickedup = data?.puCompletedDatetime ? 'PICKEDUP' : undefined;

  const classes = getClasses(
    divProps?.className,
    tripKind,
    tripType,
    tripStatus,
    tripIsLate,
    tripIsUpcoming,
    tripIsCompleted,
    tripIsDeleted,
    tripIsCurbside,
    tripIsPickedup
  );

  return (
    <SimpleTableRow {...divProps} data={data} className={classes} ref={ref}>
      {columns.get(AirlineColumnKeyEnum.TYPE) && (
        <SimpleTableCell name="TYPE" className="w-md-fixed">
          {data.type || '--'}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_DATE) && (
        <SimpleTableCell name="SCHEDULED_DATE" className="w-sm-grow">
          {tripScheduledDatetime.format(DATE_FE_FORMAT_SHORT)}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_TIME) && (
        <SimpleTableCell name="SCHEDULED_TIME" className="w-sm-grow">
          {tripScheduledDatetime.time || '--'}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.ETA) && (
        <SimpleTableCell name="ETA" className="w-md-fixed">
          {data.etaMinutes || '--'}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.ACTUAL) && (
        <SimpleTableCell name="ACTUAL" className="flex-column gap-1 w-sm-grow">
          <ActualCell
            actual={data.trackFlight?.actual}
            arrivalGate={data.trackFlight?.arrivalGate}
            arrivalTerminal={data.trackFlight?.arrivalTerminal}
            label={data.trackFlight?.label}
            kind={data.kind}
            scheduled={data.scheduled}
          />
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.AIRPORT_CODE) && (
        <SimpleTableCell name="AIRPORT_CODE" className="w-md-fixed">
          {data.airportCode || '--'}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.IATA_CODE) && (
        <SimpleTableCell name="IATA_CODE" className="w-sm-fixed">
          {data.servicerIataAirlineCode || '--'}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.FLIGHT_NUMBER) && (
        <SimpleTableCell
          name="FLIGHT_NUMBER"
          className={`${data.trackFlight?.type === TrackFlightTypeEnum.International ? 'bg-purple bg-opacity-15 text-dark' : undefined}`}
        >
          {data.loopName || tripFlightNumber}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.PILOT) && (
        <SimpleTableCell name="PILOT" className="w-sm-fixed">
          {data.pilots || 0}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.ATTENDANT) && (
        <SimpleTableCell name="ATTENDANT" className="w-sm-fixed">
          {data.attendants || 0}
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.PU_LOCATION) && (
        <SimpleTableCell name="PU_LOCATION" className="w-lg-grow">
          <LocationCell
            name={data.puLocation?.name}
            address={data.puLocation?.address}
            stateCode={data.puLocation?.stateCode}
            zipCode={data.puLocation?.zipCode}
            cityName={data.puLocation?.cityName}
            phoneNumber={data.puLocation?.phoneNumber}
            id={data.puLocationId}
          />
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.DO_LOCATION) && (
        <SimpleTableCell name="DO_LOCATION" className="w-lg-grow">
          <LocationCell
            name={data.doLocation?.name}
            address={data.doLocation?.address}
            stateCode={data.doLocation?.stateCode}
            zipCode={data.doLocation?.zipCode}
            cityName={data.doLocation?.cityName}
            phoneNumber={data.doLocation?.phoneNumber}
            id={data.doLocationId}
          />
        </SimpleTableCell>
      )}
    </SimpleTableRow>
  );
};

export default React.memo(React.forwardRef(AirlineTripsTableRow));
