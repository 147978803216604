import { ReportInput, ReportSeriesEnum } from '@/models/gen/graphql';
import ReportQuery, { ReportQueryAsCsv } from '@/api/queries/reports/ReportQuery';
import createGraphApiHook, { CreateGraphApiHookReturn } from '@/hooks/createGraphApiHook';

import graphApi from '../..';
import { handleError } from '@/utils';

export const runReportQuery = (reportType: string, depth?: number) => graphApi(ReportQuery(reportType, depth));

export const reportQuery =
  (reportType: string, depth?: number): ((input: ReportInput) => Promise<any>) =>
  async (input: ReportInput): Promise<any> => {
    input.series = input?.series || ReportSeriesEnum.SeriesDefault;
    input.csvColumns = Object.values(input?.csvColumns || {})?.length > 0 ? input?.csvColumns : null;
    // TODO: OMG, FIX THIS
    input.reason = Object.values(input?.reason || {})?.length > 0 ? input?.reason : null;
    input.buckets = Object.values(input?.buckets || {})?.length > 0 ? input?.buckets : null;
    input.complexFilters = Object.values(input?.complexFilters || {})?.length > 0 ? input?.complexFilters : null;
    input.userSessionTimezone = `-${[
      Math.round(new Date().getTimezoneOffset() / 60)
        .toString()
        .padStart(2, '0'),
      '00',
    ].join(':')}`;
    Object.entries(input).forEach(([key, value]: [string, string | string[]]) => {
      switch (key) {
        case 'startDatetime':
        case 'endDatetime':
        case 'groupBy':
        case 'status':
          value = Array.isArray(value) ? value[0] : value;
          break;
      }
      input[key] = value;
    });
    const res = (await runReportQuery(reportType, depth)[1]({ input } as never)) as Record<string, any>;
    return res?.reportQuery?.[reportType] || {};
  };

export const useReportQuery = (reportType: string, depth?: number): CreateGraphApiHookReturn<any> =>
  createGraphApiHook(reportQuery(reportType, depth))();

export const runReportQueryAsCsv = (reportType: string, depth?: number) => graphApi(ReportQueryAsCsv(reportType, depth));
export const reportQueryAsCsv =
  (reportType: string, depth?: number): ((input: ReportInput) => Promise<any>) =>
  async (input: ReportInput): Promise<any> => {
    try {
      const res = (await runReportQueryAsCsv(reportType, depth)[1]({ input } as never)) as any;
      return res?.reportQuery?.[`${reportType}AsCsv`]?.output || '';
    } catch (err) {
      handleError(err, { notification: { title: 'Export CSV' } });
    }
  };

// TODO: Inplement Zod validation for input.
export const reportQueryInputKeys = [
  'airlines',
  'airports',
  'buckets',
  'clients',
  'companies',
  'complexFilters',
  'csvColumns',
  'currencies',
  'details',
  'doLocations',
  'drivers',
  'endDatetime',
  'groupBy',
  'kinds',
  'puLocations',
  'rateTypes',
  'rates',
  'rating',
  'reason',
  'reportType',
  'series',
  'startDatetime',
  'status',
  'thresholds',
  'types',
  'userSessionTimezone',
];

export default reportQuery;
