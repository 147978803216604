import { Badge, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import { Datetime, Validation } from '@/utils';
import React, { useMemo } from 'react';
import { Stop, TrackFlightLabelEnum, TripKindEnum } from '@/models/gen/graphql';
import { TripStop, TripStopTimeSchedule } from '@/components/EditTripsModal/TripStop';

import ActivityView from '@/features/Trips/components/TripModal/views/activity';
import DateSelector from '@/components/DateSelector';
import { DatetimeInputField } from '@/components/DatetimeInput';
import DragAndDrop from '@/components/DragAndDrop';
import DynamicContent from '@/components/DynamicContent';
import FormField from '@/components/FormField';
import { Link } from 'react-router-dom';
import SelectAirlineIata from '@/components/SelectAirlineIata';
import SelectAirport from '@/components/SelectAirport';
import SelectClient from '@/components/SelectClient';
import SelectFlag from '@/components/SelectFlag';
import SelectKind from '@/components/SelectKind';
import SelectStatus from '@/components/SelectStatus';
import SelectType from '@/components/SelectType';
import SwitchInput from '@/components/SwitchInput';
import TippyWhen from '@/components/TippyWhen';
import { TripModalViewProps } from '@/features/Trips/components/TripModal';
import { TripViewProps } from '@/features/Trips/components/TripModal/views/trip';
import equal from 'fast-deep-equal/es6/react';
import useTripView from '@/features/Trips/components/TripModal/views/trip/hook';

const TripModalView = (props: TripViewProps) => {
  const [
    { data, title, mode, trip, validity, loopOrFlightNumber, selectedCount, uuid, isValid },
    { onChange, setState, onSaveAndCopy, handleSubmit, handleHide, onBlurFlightNumber, onAddStop },
  ] = useTripView(props);
  const valid = isValid && !equal(trip, data) && (trip?.pilots > 0 || trip?.attendants > 0);

  const MemoizedTripStop = useMemo(() => {
    const TripsStopComponent = (props: any): JSX.Element => (
      <TripStop
        onDelete={(): any =>
          setState((current: any): any => ({
            ...current,
            trip: {
              ...(current?.trip || {}),
              stops: (current?.trip?.stops || []).filter((_stop: any, i: number): boolean => i !== props?.index),
            },
          }))
        }
        onChange={(event: any): void => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange(event) : undefined;
            case 'airportFilter':
              onChange({ target: { name: `trip.stops.${props?.index}.locationId`, value: '' } });
            default:
              return onChange(event);
          }
        }}
        valid={{
          locationId: Validation.isValidUUID(props?.data?.locationId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
        }}
        options={{
          filterLocationByAirport: true,
        }}
        {...props}
      />
    );
    return TripsStopComponent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: replace all FormField components with their respective primitives (Input, Dropdown, etc)
  return (
    <>
      <TripViewHeader manifestImportId={trip?.manifestImportId} title={title} />
      <Row>
        <Col xs={12} md={3}>
          <FormField
            label="Type:"
            placeholder="Select Type"
            name="trip.type"
            value={trip?.type || ''}
            onChange={onChange}
            valid={validity?.type?.valid}
            searchable
            options={{
              input: {
                as: SelectType,
              },
            }}
          />
          <FormField
            label="Client:"
            placeholder="Select Client"
            name="trip.payerProviderId"
            value={trip?.payerProviderId || ''}
            onChange={(event) => {
              const value = event?.target?.value;
              setState((current) => {
                return { ...(current || {}), trip: { ...(current?.trip || {}), payerProviderId: value, providerId: value } };
              });
            }}
            valid={validity?.payerProviderId?.valid}
            searchable
            options={{
              input: {
                as: SelectClient,
              },
            }}
          />
          <FormField
            label="Airline:"
            placeholder="Select Airline"
            name="trip.servicerIataAirlineCode"
            value={trip?.servicerIataAirlineCode || ''}
            onChange={onChange}
            valid={validity?.servicerIataAirlineCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirlineIata,
              },
            }}
          />
          <FormField
            label="Airport:"
            placeholder="Select Airport"
            name="trip.airportCode"
            value={trip?.airportCode || ''}
            onChange={(event: any): void => {
              const { value } = event?.target || {};
              if (!value) return;
              setState((current: any): any => ({
                ...(current || {}),
                trip: {
                  ...(current?.trip || {}),
                  airportCode: value,
                  puLocationId: '',
                  doLocationId: '',
                  doAirportFilter: value,
                  stops: (current?.trip?.stops || []).map((stop: any): any => ({
                    ...stop,
                    airportFilter: value,
                    locationId: '',
                  })),
                },
              }));
            }}
            valid={validity?.airportCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirport,
              },
            }}
          />
          <FormField
            label={'Arrival/\nDeparture:'}
            placeholder="Select Kind"
            name="trip.kind"
            value={trip?.kind || ''}
            onChange={(event): void => {
              const { value } = event?.target || {};
              setState((current: any): any => {
                const { puLocationId = '', doLocationId = '' } = current?.trip || {};
                return {
                  ...(current || {}),
                  trip: {
                    ...(current?.trip || {}),
                    kind: value,
                    puLocationId: doLocationId,
                    doLocationId: puLocationId,
                  },
                };
              });
            }}
            valid={validity?.kind?.valid}
            searchable
            options={{
              input: {
                as: SelectKind,
              },
            }}
          />
        </Col>
        <Col xs={12} md={4}>
          <FormField
            label="Flight Number:"
            name="loopOrFlightNumber"
            value={loopOrFlightNumber}
            onChange={(event) => {
              const { value } = event?.target || {};
              const isLoopName = value.length > 4 || value.match(/\D/g);
              const flightNumber = !isLoopName ? value : undefined;
              const loopName = isLoopName ? value : undefined;
              setState((current: any): any => ({
                ...current,
                loopOrFlightNumber: value,
                trip: { ...(current?.trip || {}), flightNumber, loopName },
              }));
            }}
            onBlur={onBlurFlightNumber}
            valid={validity?.flightNumber?.valid || validity?.loopName?.valid}
          />
          <div className="d-flex gap-3 {margin:-1.2rem|0;}">
            <FormField
              label="PLT:"
              type="number"
              name="trip.pilots"
              value={trip?.pilots || 0}
              onChange={onChange}
              valid={trip?.pilots > 0 || trip?.attendants > 0}
              min={0}
              max={255}
            />
            <FormField
              label="F/A:"
              type="number"
              name="trip.attendants"
              value={trip?.attendants || 0}
              onChange={onChange}
              valid={trip?.attendants > 0 || trip?.pilots > 0}
              min={0}
              max={255}
            />
          </div>
          <FormField
            type="number"
            label="Crew ID:"
            name="trip.crewId"
            value={trip?.crewId || ''}
            onChange={onChange.int}
            valid={validity?.crewId?.valid}
            options={{
              input: {
                className: 'CrewId',
              },
            }}
          />
          <FormField
            label="First Name:"
            name="trip.firstName"
            value={trip?.firstName || ''}
            onChange={onChange}
            valid={validity?.firstName?.valid}
          />
          <FormField
            label="Last Name:"
            name="trip.lastName"
            value={trip?.lastName || ''}
            onChange={onChange}
            valid={validity?.lastName?.valid}
          />
          <div className="d-flex gap-3 {margin:-1.2rem|0;}">
            <div className="FormField">
              <SwitchInput
                className="align-items-end pb-1"
                label={
                  <>
                    Include in
                    <br />
                    Future Manifests?
                  </>
                }
                checked={trip?.fromManifest !== undefined ? !!trip?.fromManifest : true}
                onChange={(value) => onChange({ target: { name: 'trip.fromManifest', value: value ? 1 : 0 } })}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </div>
            <FormField label="Split:" type="number" name="trip.split" value={trip?.split || ''} onChange={onChange} />
          </div>
        </Col>
        <Col xs={12} md={5}>
          <div className="d-flex gap-3 {margin:0|0|-1.2rem|0;}">
            <FormField
              label={'Flags:'}
              placeholder="No Flags"
              name="trip.flags"
              value={(trip?.flags || ([] as string[])).map((node) => node.id || node)}
              onChange={onChange}
              disabled={selectedCount > 1}
              searchable
              options={{
                input: { as: SelectFlag, className: 'Flags' },
              }}
            />
            <FormField
              label={'Status:'}
              placeholder="Select Status"
              name="trip.status"
              value={trip?.status || ''}
              onChange={(event: any): void => (event?.target?.value ? onChange(event) : undefined)}
              valid={validity?.status?.valid}
              searchable
              options={{
                input: { as: SelectStatus, className: 'Status' },
              }}
            />
          </div>
          <FormField
            type="textarea"
            label="Comments:"
            name="trip.comments"
            value={trip?.comments || ''}
            onChange={onChange}
            options={{ input: { className: 'Comments {h:12.3rem;resize:none;}' } }}
          />
          {mode === 'editing' && (
            <div className="d-flex gap-3 {margin:-1.2rem|0;}">
              <div className="FormField">
                <SwitchInput
                  className="align-items-end pb-1"
                  label="Increment?"
                  checked={!!trip?.['increment']}
                  onChange={(value) => onChange({ target: { name: 'trip.increment', value } })}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </div>
              <FormField
                label="Days:"
                type="number"
                name="trip.days"
                value={trip?.['days'] || 0}
                disabled={!trip?.['increment']}
                onChange={onChange.int}
              />
            </div>
          )}
          {mode === 'editing' && (
            <FormField
              name="trip.scheduled"
              label="P/U Date:"
              type="date"
              value={trip?.scheduled || ''}
              onChange={(event: any): void => {
                const { value } = event?.target || {};
                setState((current: any): any => {
                  const currentScheduledTime = current?.trip?.scheduled?.split('T')?.[1];
                  const newScheduledTime = currentScheduledTime ? value.concat(' ', currentScheduledTime) : value;
                  const result = {
                    ...current,
                    trip: { ...(current?.trip || {}), scheduled: new Datetime(newScheduledTime).toString() },
                  };
                  if (current?.trip?.stops?.length) {
                    const originalStops = [...(current?.trip?.stops || [])];
                    const stops = originalStops.map(
                      (stop: Stop): Stop => ({
                        ...stop,
                        scheduled: value ? new Datetime(stop?.scheduled || undefined).setDate(value).toString() : null,
                      })
                    );
                    result.trip.stops = stops;
                  }
                  return result;
                });
              }}
              valid={validity?.scheduled?.valid}
              disabled={!!trip?.['increment']}
              feedback={trip?.['increment'] ? 'Disable increment to edit scheduled date.' : ''}
            />
          )}
          {mode === 'creating' && (
            <DateSelector
              name="trip.scheduledDays"
              label="P/U Date:"
              value={trip?.['scheduledDays'] || []}
              onChange={onChange}
              valid={!!trip?.scheduledDays?.length}
              selector="day"
              range={false}
              multiple
            />
          )}
          {mode === 'editing' && (
            <Row>
              <Col xs={7}>
                <DatetimeInputField
                  label="Arrival Date / Time:"
                  value={trip?.trackFlight?.actual}
                  onChange={(value) => onChange({ target: { name: 'trip.trackFlight.actual', value } })}
                  disabled={!trip?.trackFlightId}
                />
              </Col>
              <Col>
                <SwitchInput
                  label="Flight Gate Arrived?"
                  checked={trip?.trackFlight?.label === TrackFlightLabelEnum.Gated}
                  onChange={(checked) =>
                    onChange({
                      target: {
                        name: 'trip.trackFlight.label',
                        value: checked ? TrackFlightLabelEnum.Gated : TrackFlightLabelEnum.Unknown,
                      },
                    })
                  }
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={!trip?.trackFlightId || data?.trackFlight?.label === TrackFlightLabelEnum.Gated}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Modal.Title className="border-0 d-flex mt-4">
            <Badge className="rounded bg-black bg-opacity-75">
              <i className={`fa fa-2x fa-location-dot {scale(0.75)}`} />
            </Badge>
            <span className="flex-grow-1 h-100 ms-3 mt-2 border-bottom border-gray">Trip Stops</span>
          </Modal.Title>
          <Row className="mt-5">
            <Col xs={12} sm={8}>
              <TripStop
                data={{
                  ...trip,
                  type: 'PU',
                  airportFilter: trip?.airportCode || '',
                  locationId: trip?.puLocationId,
                  scheduled: trip?.scheduled,
                  actual: trip?.puActualDatetime,
                  completed: trip?.puCompletedDatetime,
                  kind: trip?.kind,
                  doLocationId: trip?.doLocationId || '',
                  stops: trip?.stops || [],
                }}
                valid={{ locationId: validity?.puLocationId?.valid, scheduled: validity?.scheduled?.valid }}
                disabled
                index={-1}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'locationId':
                      return value !== '' ? onChange({ target: { name: 'trip.puLocationId', value } }) : undefined;
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                options={{
                  autoSelectLocation: trip?.kind === TripKindEnum.Arr,
                  location: {
                    props: {
                      query: {
                        airportCode: trip?.airportCode || null,
                      },
                    },
                  },
                }}
              />
              <DragAndDrop
                list={trip?.stops || []}
                id="id"
                name="trip.stops"
                replaceProperties={['id', 'type', 'locationId', 'airportFilter']}
                onChange={onChange}
                as={MemoizedTripStop}
              />
              <TripStop
                data={{
                  ...trip,
                  type: 'DO',
                  locationId: trip?.doLocationId,
                  scheduled: trip?.['doScheduledDatetime'],
                  actual: trip?.doActualDatetime,
                  completed: trip?.completion?.datetime,
                  airportFilter: trip?.['doAirportFilter'] || trip?.doLocation?.airports?.[0]?.airportCode || '',
                  kind: trip?.kind,
                  puLocationId: trip?.puLocationId || '',
                  stops: trip?.stops || [],
                }}
                valid={{ locationId: validity?.doLocationId?.valid }}
                disabled
                index={trip?.stops?.length || 0}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'locationId':
                      return value !== '' ? onChange({ target: { name: 'trip.doLocationId', value } }) : undefined;
                    case 'airportFilter':
                      return setState((current: any): any => ({
                        ...current,
                        trip: {
                          ...current?.trip,
                          doLocationId: '',
                          doAirportFilter: value,
                        },
                      }));
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                options={{
                  autoSelectLocation: trip?.kind === TripKindEnum.Dep,
                  filterLocationByAirport: true,
                }}
              />
            </Col>
            <Col>
              <TripStopTimeSchedule
                stop={{
                  type: 'PU',
                  locationId: trip?.puLocationId,
                  scheduled: trip?.scheduled,
                  actual: trip?.puActualDatetime,
                  completed: trip?.puCompletedDatetime,
                }}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'scheduled':
                      return onChange.time({ target: { name: 'trip.scheduled', value } });
                    case 'actual':
                      return onChange.time({ target: { name: 'trip.puActualDatetime', value } });
                    case 'completed':
                      return onChange.time({ target: { name: 'trip.puCompletedDatetime', value } });
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                index={-1}
                valid={{ scheduled: validity?.scheduled?.valid }}
              />
              {trip?.stops?.map((stop: any, s: number): JSX.Element => {
                return (
                  <TripStopTimeSchedule
                    disabled={selectedCount > 1}
                    stop={stop}
                    onChange={onChange}
                    index={s}
                    key={`stop.${s}.time`}
                    valid={{ scheduled: stop?.scheduled ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID }}
                  />
                );
              })}
              <TripStopTimeSchedule
                stop={{
                  type: 'DO',
                  locationId: trip?.doLocationId,
                  scheduled: trip?.['doScheduledDatetime'],
                  actual: trip?.doActualDatetime,
                  completed: trip?.completion?.datetime,
                }}
                onChange={(event: any): any => {
                  const { name, value } = event?.target || {};
                  switch (name.split('.').pop()) {
                    case 'scheduled':
                      return onChange.time({ target: { name: 'trip.doScheduledDatetime', value } });
                    case 'actual':
                      return onChange.time({ target: { name: 'trip.doActualDatetime', value } });
                    case 'completed':
                      return onChange.time({ target: { name: 'trip.completion.datetime', value } });
                    default:
                      return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
                  }
                }}
                index={trip?.stops?.length || 0}
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal.Footer className="d-block {text-transform:uppercase!;font-size:0.8rem!;py:0!;text-align:right!;line-height:0.9rem;}_.input-group-text">
        <Row className="mt-4">
          <Col xs={12} sm={2}></Col>
          <Col xs={12} sm={5} className="text-center">
            <InputGroup>
              <InputGroup.Text className="invisible">{(data?.stops || []).length + 1}.</InputGroup.Text>
              <Button name="ADD_STOP" className="flex-grow-1" variant="outline-gray" onClick={onAddStop}>
                <i className="fa fa-plus-circle" /> Add Stop
              </Button>
            </InputGroup>
          </Col>
          <Col xs={12} sm={5} className="text-end">
            {mode === 'creating' && (
              <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
                <span>
                  <Button className="ms-2" variant="gray" onClick={onSaveAndCopy} disabled={!valid}>
                    Save Copy
                  </Button>
                </span>
              </TippyWhen>
            )}
            <Button name="CANCEL" className="ms-2" variant="secondary" onClick={handleHide}>
              Cancel
            </Button>
            <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
              <span>
                <Button name="SUBMIT" className="ms-2" variant="primary" onClick={handleSubmit} disabled={!valid}>
                  Save
                </Button>
              </span>
            </TippyWhen>
          </Col>
        </Row>
        {mode === 'editing' && (
          <Row className="px-3">
            <small className="mt-4">ACTIVITY:</small>
            <div className="bg-white rounded border height:200px">
              <div className="bg-white overflow-scroll p-2 height:190px">
                <ActivityView data={data} />
              </div>
            </div>
          </Row>
        )}
      </Modal.Footer>
    </>
  );
};

export const TripViewHeader = ({
  manifestImportId,
  title,
}: {
  manifestImportId: string;
  title: TripModalViewProps['title'];
}): JSX.Element => {
  return (
    <DynamicContent.Title className="justify-content-between">
      {title}
      {manifestImportId && (
        <Link className="Link" to={`/manifests/${manifestImportId}`} target="_blank">
          Go to Manifest
        </Link>
      )}
    </DynamicContent.Title>
  );
};

export default TripModalView;
