import './styles.scss';

import React, { ReactNode, ReactPortal, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { getClasses } from '@/utils';

export type TooltipProps = {
  children: ReactNode;
  content?: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  interactive?: boolean;
  when?: boolean;
  onHide?: () => void;
};

// Memoize Tooltip component to prevent unnecessary re-renders
const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  className,
  placement = 'top',
  interactive = false,
  when = true,
  onHide,
}: TooltipProps): ReactNode => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(undefined);
  const [calculatedPlacement, setCalculatedPlacement] = useState(placement);
  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Show tooltip on hover or focus
  const handleShowTooltip = useCallback((event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    setVisible(true);
    setTargetRect(event.currentTarget.getBoundingClientRect());
  }, []);

  // Hide tooltip
  const handleHideTooltip = useCallback(() => {
    if (interactive) {
      // If interactive is true, delay hiding to allow hover over tooltip
      hideTimeoutRef.current = setTimeout(() => {
        setVisible(false);
        setCalculatedPlacement(placement);
        if (onHide) onHide();
      }, 100); // Delay to check if mouse entered tooltip
    } else {
      setVisible(false);
      setCalculatedPlacement(placement);
      if (onHide) onHide();
    }
  }, [placement, interactive, onHide]);

  // Handle mouse entering the tooltip
  const handleTooltipMouseEnter = useCallback(() => {
    if (!hideTimeoutRef.current) return;
    clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = null;
  }, []);

  // Handle mouse leaving the tooltip
  const handleTooltipMouseLeave = useCallback(() => {
    setVisible(false);
    setCalculatedPlacement(placement);
  }, [placement]);

  useEffect(() => {
    if (!visible || !tooltipRef.current || !targetRect) return;
    const tooltipRect = tooltipRef.current.getBoundingClientRect();

    // Calculate available space
    const spaceAbove = targetRect.top;
    const spaceBelow = window.innerHeight - targetRect.bottom;
    const spaceLeft = targetRect.left;
    const spaceRight = window.innerWidth - targetRect.right;

    // Decide whether to flip the placement
    const newPlacement = (() => {
      switch (placement) {
        case 'top':
          return spaceAbove < tooltipRect.height + 10 ? 'bottom' : 'top';
        case 'bottom':
          return spaceBelow < tooltipRect.height + 10 ? 'top' : 'bottom';
        case 'left':
          return spaceLeft < tooltipRect.width + 10 ? 'right' : 'left';
        case 'right':
          return spaceRight < tooltipRect.width + 10 ? 'left' : 'right';
        default:
          return placement;
      }
    })();

    if (newPlacement !== calculatedPlacement) setCalculatedPlacement(newPlacement);

    // Calculate tooltipPosition based on 'newPlacement'
    const { top, left } = (() => {
      switch (newPlacement) {
        case 'top':
          return {
            top: targetRect.top + window.scrollY - 10,
            left: targetRect.left + window.scrollX + targetRect.width / 2,
          };
        case 'bottom':
          return {
            top: targetRect.bottom + window.scrollY + 10,
            left: targetRect.left + window.scrollX + targetRect.width / 2,
          };
        case 'left':
          return {
            top: targetRect.top + window.scrollY + targetRect.height / 2,
            left: targetRect.left + window.scrollX - 10,
          };
        case 'right':
          return {
            top: targetRect.top + window.scrollY + targetRect.height / 2,
            left: targetRect.right + window.scrollX + 10,
          };
        default:
          return { top: 0, left: 0 };
      }
    })();

    setTooltipPosition({ top, left });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, tooltipRef, targetRect, interactive]);

  // Decide whether to attach mouse events to the tooltip
  const tooltipMouseEvents = useMemo((): {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  } => {
    return interactive
      ? {
          onMouseEnter: handleTooltipMouseEnter,
          onMouseLeave: handleTooltipMouseLeave,
        }
      : {};
  }, [interactive, handleTooltipMouseEnter, handleTooltipMouseLeave]);

  const tooltipContent = useMemo(
    (): ReactPortal =>
      ReactDOM.createPortal(
        <div
          className={getClasses('Tooltip', calculatedPlacement)}
          ref={tooltipRef}
          style={{
            display: tooltipPosition ? 'inline-block' : 'none',
            top: `${tooltipPosition?.top || 0}px`,
            left: `${tooltipPosition?.left || 0}px`,
          }}
          {...tooltipMouseEvents}
        >
          <div className="Tooltip-Content">{content}</div>
          <div className="Tooltip-Arrow" />
        </div>,
        document.body
      ),
    [tooltipPosition, content, calculatedPlacement, tooltipMouseEvents]
  );

  // Decide whether to attach mouse events to the trigger element
  const triggerMouseEvents = useMemo(
    (): {
      onMouseEnter?: (event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => void;
      onMouseLeave?: (event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => void;
      onMouseOver?: (event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => void;
      onMouseOut?: (event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => void;
    } => ({
      [interactive ? 'onMouseEnter' : 'onMouseOver']: handleShowTooltip,
      [interactive ? 'onMouseLeave' : 'onMouseOut']: handleHideTooltip,
    }),
    [handleHideTooltip, handleShowTooltip, interactive]
  );

  if (!content || !when) return children;
  return (
    <div
      className={getClasses('Tooltip-Trigger', className)}
      {...triggerMouseEvents}
      onFocus={handleShowTooltip}
      onBlur={handleHideTooltip}
    >
      {children}
      {visible && tooltipContent}
    </div>
  );
};

export default React.memo(Tooltip);
