import { Button, Col, ColProps } from 'react-bootstrap';
import React, { MouseEventHandler, useCallback, useMemo } from 'react';

import { Dayjs } from 'dayjs';
import { TODAY } from '@/constants';
import { getClasses } from '@/utils';
import { useCalendarContext } from '@/components/Calendar/new';

export type CalendarDateProps = { value: Dayjs } & ColProps;

const CalendarDate = ({ value, ...colProps }: CalendarDateProps): JSX.Element => {
  // Get the start and end dates, the current month, and the onChange function from the context.
  const { startDate, endDate, month, onChange, selected, range } = useCalendarContext();
  // Get the display value for the date.
  const display = useMemo<string>((): string => value.format('D').toString(), [value]);

  // Derive the classes for the date and other checks.
  const isSet = startDate?.date && endDate?.date;
  const isStart = startDate?.date && value.isSame(startDate?.date, 'day');
  const isEnd = endDate?.date && value.isSame(endDate?.date, 'day');
  const isRange = range !== undefined && range !== false;
  const isToday = value.isSame(TODAY, 'day') ? 'Calendar-Today' : undefined;
  const isSelected = (!isRange && selected.includes(value.format('YYYY-MM-DD'))) || isStart || isEnd ? 'Calendar-Selected' : undefined;
  const isOutOfRange = !value.isBetween(month.startOf('month').subtract(1, 'day'), month.endOf('month')) ? 'Calendar-Outset' : undefined;
  const isInRange = isRange && isSet && (isSelected || value.isBetween(startDate?.date, endDate?.date)) ? 'Calendar-Range' : undefined;

  // Get the classes for the date.
  const classes = getClasses('Calendar-Day', isToday, isOutOfRange, isInRange, isSelected, colProps.className);

  // Handle the change event for the date.
  const handleChange = useCallback<MouseEventHandler<HTMLButtonElement>>((): void => onChange(value), [value, onChange]);

  return (
    <Col {...colProps} className={classes}>
      <Button className="Calendar-Date" variant="icon" onClick={handleChange} tabIndex={-1}>
        {display}
      </Button>
    </Col>
  );
};

export default CalendarDate;
