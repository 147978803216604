import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import React, { useMemo } from 'react';

import { Middle } from '../Align';
import { generateQueryString } from '@/utils';
import useSession from '@/state/session';
import useSessionStorage from '@/hooks/useSessionStorage';

const SUPPORT_EMAIL = 'support@skyhoptechnologies.com';

const DefaultError = ({ error }: { error: Error }): JSX.Element => {
  const [userId = 'UNKNOWN', userName = 'UNKNOWN'] = useSession(({ state }) => [
    state?.user?.id,
    [state?.user?.firstName, state?.user?.lastName].join(' '),
  ]);
  const [{ release = 'local', version = 'local' } = {}] = useSessionStorage('version');
  const [details, queryString] = useMemo(() => {
    const details = {
      ERROR: error?.message,
      'USER ID': userId,
      NAME: userName,
      LOCATION: window.location.origin + window.location.pathname,
      QUERY: window.location.search,
      TIME: new Date().toLocaleString(),
      RELEASE: release,
      CURRENT: version,
    };
    const queryString = generateQueryString({
      subject: `SkyPlan Unexpected Error`,
      body: ['\n', ...Object.entries(details).map(([key, value]) => `${key}: ${value}`)].join('\n'),
    });
    return [details, queryString];
  }, [error?.message, release, userId, userName, version]);

  return (
    <div className="h-100 w-100" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <Middle.Center>
        <Card className="shadow" style={{ maxWidth: '33vw', borderRadius: '0.75rem' }}>
          <Card.Header>
            <Card.Title>Something Went Wrong</Card.Title>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {Object.entries(details).map(([key, value]) => (
                <ListGroupItem key={key}>
                  <strong>{key}</strong>: {value}
                </ListGroupItem>
              ))}
            </ListGroup>
            <Button
              variant="primary"
              size="lg"
              className="w-100 d-flex gap-2 justify-content-center mt-2 rounded"
              onClick={() => {
                window.open(`mailto:${SUPPORT_EMAIL}?${queryString}`, '_blank');
                window.location.reload();
              }}
            >
              <span>
                <i className="fa fa-envelope-open-text" />
              </span>
              <span>Click here to send an email to Support</span>
            </Button>
          </Card.Body>
          <Card.Footer>
            <em>
              Click <a href={window.location.href}>here</a> to refresh and try again.
            </em>
          </Card.Footer>
        </Card>
      </Middle.Center>
    </div>
  );
};

export default DefaultError;
