import Field, { FieldProps } from '@/components/Field';
import QueryDropdown, { QueryDropdownMultiProps, QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';
import { getAirportGroupPermissions, getAirportGroups } from '@/api/services/filters/airportGroups';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getAirports } from '@/api/services/filters/airports';

export type AirportDropdownProps = Omit<QueryDropdownProps, 'query'>;
export type AirportGroupDropdownProps = Omit<QueryDropdownMultiProps, 'query'>;

const AirportDropdown = ({ name = 'AirportDropdown', ...props }: AirportDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirports();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return (
    <QueryDropdown
      {...props}
      name={name}
      query={getItems}
      options={{ ...props?.options, locale: props?.options?.locale || { 'Select...': 'City' } }}
    />
  );
};

export const AirportDropdownMulti = ({ name = 'AirportDropdown', ...props }: AirportGroupDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirports();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return (
    <QueryDropdown.Multi
      {...props}
      name={name}
      query={getItems}
      options={{ ...props?.options, locale: props?.options?.locale || { 'Select...': 'City' } }}
    />
  );
};
AirportDropdown.Multi = AirportDropdownMulti;

export const AirportGroupDropdown = ({ name = 'AirportGroupDropdown', ...props }: AirportGroupDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirportGroups();
    return (
      res?.map?.(
        (option: FilterOption): DropdownItem => ({
          label: option?.displayName,
          value: option?.values?.length === 1 && option?.values?.[0] === option?.id ? option?.id : undefined,
          group: option?.values?.length > 1 ? option?.values : undefined,
          keywords: option?.displayName?.split?.(' ') || [],
        })
      ) || []
    );
  }, []);

  return (
    <QueryDropdown.Sticky
      {...props}
      name={name}
      query={getItems}
      onChange={(value, item) => {
        props?.onChange?.(value, item);
        return false;
      }}
      options={{ ...props?.options, locale: props?.options?.locale || { 'Select...': 'City' }, showSelectAll: false }}
    />
  );
};

export const AirportGroupPermissionsDropdown = ({
  name = 'AirportGroupPermissionsDropdown',
  ...props
}: AirportGroupDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAirportGroupPermissions();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({
        label: option?.displayName,
        value: option?.values?.length === 1 && option?.values?.[0] === option?.id ? option?.id : undefined,
        group: option?.values?.length > 1 ? option?.values : undefined,
        keywords: option?.displayName?.split?.(' ') || [],
      })
    );
  }, []);

  return (
    <QueryDropdown.Multi
      {...props}
      name={name}
      query={getItems}
      options={{ ...props?.options, locale: props?.options?.locale || { 'Select...': 'City' } }}
    />
  );
};

export const AirportDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & AirportDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <AirportDropdown {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default AirportDropdown;
