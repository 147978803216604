import { CompletionCurrencyEnum, Trip, TripTableFormatEnum } from '@/models/gen/graphql';
import { Datetime, queryInput } from '@/utils';

import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import { refetchSearchTripsTable } from '@/api/services/trips/searchTrips';
import { useCallback } from 'react';
import { useEditCompletionModal } from '@/pages/Trips/EditCompletion';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

const CompletionCell = (): React.JSX.Element => {
  const { onDeleteTrips } = useTripsTableContext();
  const onSetRow = useTripTableState(({ state }) => state.onSetRow);
  const showEditCompletionModal = useEditCompletionModal(({ setState }) => setState);
  const { data: { id, state, scheduled, servicerIataAirlineCode, flightNumber } = {} } = useSimpleTableRowContext<Trip>();
  const { completion, datetimeOfChange, displayName, displayNameShort } = state || {};
  const datetimeOfChangeDatetime = datetimeOfChange ? new Datetime(datetimeOfChange) : null;

  const onSubmit = useCallback(
    async (_tripIds, state) => {
      if (state === undefined) return;
      const res = await refetchSearchTripsTable({ format: TripTableFormatEnum.All, query: [{ id: queryInput(id) }] }, { pageSize: 1 });
      const row = res?.rows?.[0];
      // remove row from state
      if (!row) return onDeleteTrips([id]);
      // update row in state
      onSetRow({ id, ...row }, [id]);
    },
    [id, onDeleteTrips, onSetRow]
  );
  const onClick = useCallback(
    () =>
      showEditCompletionModal({
        show: true,
        tripId: id,
        completion: {
          ...completion,
          currency: completion?.currency || CompletionCurrencyEnum.Usd,
        },
        scheduled,
        servicerIataAirlineCode,
        flightNumber,
        onSubmit,
      }),
    [completion, flightNumber, id, onSubmit, scheduled, servicerIataAirlineCode, showEditCompletionModal]
  );

  return (
    <SimpleTableCell name="COMPLETION" className="w-sm-fixed d-print-none">
      <Tooltip content={displayName || 'No Completion'}>
        <Button variant="icon" onClick={onClick}>
          {displayNameShort && <small className="completion-reason">{displayNameShort}</small>}
          {datetimeOfChangeDatetime && <small className="completion-time text-success d-block">{datetimeOfChangeDatetime.time}</small>}
          {!datetimeOfChangeDatetime && !displayNameShort && <i className="sv sv-time fs-5" />}
        </Button>
      </Tooltip>
    </SimpleTableCell>
  );
};

export default CompletionCell;
