import './styles.scss';

import React, {
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
  RefObject,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SimpleTableContainer from '@/components/SimpleTable/SimpleTableContainer';
import SimpleTableEmptyRow from '@/components/SimpleTable/SimpleTableEmptyRow';
import SimpleTableHeader from '@/components/SimpleTable/SimpleTableHeader';
import { SimpleTableRowProps } from '@/components/SimpleTable/SimpleTableRow';
import { getClasses } from '@/utils';
import { useDebouncedCallback } from '@/hooks/useDebounceCallback';
import useEventListener from '@/hooks/useEventListener';
import useIsVisible from '@/hooks/useIsVisible';

export type SimpleTableContextValue = {
  selected: string[];
  onSelect: (id: string) => void;
  loading: boolean;
  onScrollToBottom: () => void;
  onScrollToTop: () => void;
  tableHeight: number;
  printing: boolean;
};
export const SimpleTableContext = createContext<SimpleTableContextValue | undefined>(undefined);
export const useSimpleTableContext = (): SimpleTableContextValue => useContext(SimpleTableContext);

export type SimpleTableColumnsProps = { rows: string[] };
export type SimpleTableProps = {
  rows: string[];
  loading?: boolean;
  title: string;
  shortcuts?: JSX.Element;
  fetchMore?: () => void;
  disableScroll?: boolean;
  columns: JSX.Element;
  row:
    | ((props: SimpleTableRowProps) => JSX.Element)
    | ForwardRefExoticComponent<Omit<SimpleTableRowProps, 'ref'> & RefAttributes<HTMLDivElement>>;
} & HTMLAttributes<HTMLDivElement>;

const SimpleTable = (
  { title, loading, rows, fetchMore, shortcuts, columns, row: TableRow, ...divProps }: SimpleTableProps,
  outerRef: RefObject<HTMLDivElement>
): JSX.Element => {
  const innerRef = useRef<HTMLDivElement>(null);
  const currentRef = outerRef || innerRef;
  const contentRef = useRef<HTMLDivElement>(null);
  const [firstRowRef, _isAtTop] = useIsVisible([rows]);
  const [lastRowRef, isAtBottom] = useIsVisible([rows]);
  const [selected, setSelected] = useState<string[]>([]);
  const [printing, setPrinting] = useState<boolean>(false);

  const tableHeight = contentRef.current?.clientHeight || 0;

  const onSelect = useCallback(
    (id: string): void =>
      setSelected((current: string[]): string[] =>
        current.includes(id) ? current.filter((existing: string): boolean => existing !== id) : [...current, id]
      ),
    []
  );

  const onScrollToBottom = useCallback((): void => {
    lastRowRef.current?.scrollIntoView?.(false);
  }, [lastRowRef]);

  const onScrollToTop = useCallback((): void => {
    currentRef.current?.scrollIntoView?.();
  }, [currentRef]);

  const simpleTableContextValue = useMemo(
    (): SimpleTableContextValue => ({ selected, onSelect, loading, onScrollToBottom, onScrollToTop, tableHeight, printing }),
    [loading, onScrollToBottom, onScrollToTop, onSelect, selected, tableHeight, printing]
  );
  const debouncedFetchMore = useDebouncedCallback(fetchMore, 1000);

  useLayoutEffect((): void => {
    if (loading || !fetchMore || !isAtBottom) return;
    debouncedFetchMore();
  }, [debouncedFetchMore, fetchMore, isAtBottom, loading]);

  const content = useMemo((): JSX.Element | JSX.Element[] => {
    if (!rows.length && !loading) return <SimpleTableEmptyRow />;
    return rows.map((rowId: string, index: number): JSX.Element => {
      const isFirstRow = index === 0;
      const isLastRow = index === rows.length - 1;
      const rowRef = isFirstRow ? firstRowRef : isLastRow ? lastRowRef : undefined;
      return <TableRow id={rowId} key={rowId} ref={rowRef} />;
    });
  }, [TableRow, firstRowRef, lastRowRef, loading, rows]);

  const onStartPrint = useCallback((): void => setPrinting(true), []);
  const onEndPrint = useCallback((): void => setPrinting(false), []);

  useEventListener('beforeprint', onStartPrint);
  useEventListener('afterprint', onEndPrint);

  return (
    <SimpleTableContext.Provider value={simpleTableContextValue}>
      <div {...divProps} className={getClasses('SimpleTable', divProps.className)} ref={currentRef}>
        <SimpleTableHeader title={title} shortcuts={shortcuts}>
          {columns}
        </SimpleTableHeader>
        <SimpleTableContainer ref={contentRef}>{content}</SimpleTableContainer>
      </div>
    </SimpleTableContext.Provider>
  );
};

export default forwardRef(SimpleTable);
