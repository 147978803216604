import './styles.scss';

import React, { createContext, useContext, useMemo } from 'react';
import SimpleTable, { SimpleTableProps } from '@/components/SimpleTable';
import TripsTableRow, { TripsTableRowEventHandlers } from '@/features/Trips/components/TripsTable/TripsTableRow';

import TripsTableHeader from '@/features/Trips/components/TripsTable/TripsTableHeader';

export type TripsTableContextValue = {} & TripsTableRowEventHandlers;
export const TripsTableContext = createContext<TripsTableContextValue | undefined>(undefined);
export const useTripsTableContext = (): TripsTableContextValue => useContext(TripsTableContext);

type TripsTableProps = TripsTableRowEventHandlers & Omit<SimpleTableProps, 'row' | 'columns' | 'rowHeight'>;
const TripsTable = ({
  onEditTrip,
  onEditRateReport,
  onEditRate,
  onDeleteTrips,
  onRefetch,
  rows,
  ...props
}: TripsTableProps): JSX.Element => {
  const Header = useMemo((): JSX.Element => <TripsTableHeader rows={rows} />, [rows]);
  const tripsTableContextValue = useMemo(
    (): TripsTableContextValue => ({
      onEditTrip,
      onEditRateReport,
      onEditRate,
      onDeleteTrips,
      onRefetch,
    }),
    [onEditTrip, onEditRateReport, onEditRate, onDeleteTrips, onRefetch]
  );
  return (
    <TripsTableContext.Provider value={tripsTableContextValue}>
      <SimpleTable rows={rows} columns={Header} row={TripsTableRow} {...props} />
    </TripsTableContext.Provider>
  );
};

export default React.memo(TripsTable);
