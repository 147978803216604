import { useCallback, useEffect, useMemo, useRef } from 'react';

/**
 * useDebouncedCallback hook creates a debounced version of a callback function, delaying its execution until a specified time has elapsed since the last invocation.
 * https://mantine.dev/hooks/use-debounced-callback/
 * @param callback The function being debounced
 * @param delay The delay in milliseconds
 * @returns The debounced function call
 */
export function useDebouncedCallback<T extends (...args: any[]) => any>(callback: T, delay: number) {
  const handleCallback = useCallbackRef(callback);
  const debounceTimerRef = useRef(0);
  useEffect(() => () => window.clearTimeout(debounceTimerRef.current), []);

  return useCallback(
    (...args: Parameters<T>) => {
      window.clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = window.setTimeout(() => handleCallback(...args), delay);
    },
    [handleCallback, delay]
  );
}

/**
 * Helper hook, makes use of a useRef to store a memoized callback
 * https://mantine.dev/hooks/
 * @param callback The function being memoized
 * @returns The memoized function call
 */
export function useCallbackRef<T extends (...args: any[]) => any>(callback: T | undefined): T {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
}
