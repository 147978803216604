import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

import { getClasses } from '@/utils';

export type SimpleTableEmptyRowProps = HTMLAttributes<HTMLDivElement>;

const SimpleTableEmptyRow = ({ ...divProps }: SimpleTableEmptyRowProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => (
  <div {...divProps} className={getClasses('SimpleTable-Row', 'SimpleTable-Empty', divProps.className)} ref={ref}>
    <div className="SimpleTable-Cell text-center">
      <i className="fa fa-inbox" />
      <span>No Records</span>
    </div>
  </div>
);

export default forwardRef(SimpleTableEmptyRow);
