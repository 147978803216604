import { Datetime, Validation, zeroPadFlightNumber } from '@/utils';
import { Stop, StopTypeEnum, Trip } from '@/models/gen/graphql';
import { useCallback, useEffect, useMemo } from 'react';
import useOnChange, { OnChange } from '@/hooks/useOnChange';

import { EditTripsModalValidator } from '@/components/EditTripsModal/utils';
import { TripViewProps } from '@/features/Trips/components/TripModal/views/trip';
import { createComponentState } from '@/state';
import { getFlagByTripId } from '@/api/services/flag/SearchFlag';
import { getStopByTripId } from '@/api/services/stop/SearchStop';
import { getTripForModal } from '@/api/services/trips/searchTrips';
import useTrips from '@/hooks/useTrips';
import useUuid from '@/hooks/useUuid';
import useSession from '@/state/session';

export type TripStop = Partial<Stop> & {
  airportFilter?: string;
};
type TripForm = Omit<Partial<Trip>, 'stops' | 'flightNumber'> & {
  scheduledDays?: string[];
  comments?: string;
  days?: number;
  increment?: boolean;
  doScheduledDatetime?: string;
  doAirportFilter?: string;
  flightNumber?: number | string;
  stops?: TripStop[];
};
export type UseTripViewState = {
  trip?: TripForm;
  original?: Partial<Trip>;
  loading?: boolean;
  loopOrFlightNumber?: string;
};
const initUseTripsModalState: UseTripViewState = {
  trip: {},
  original: {},
  loading: false,
  loopOrFlightNumber: undefined,
};
export const useTripViewState = createComponentState(initUseTripsModalState);
const useTripView = ({ data, title, mode, selected, onHide, onSubmit }: TripViewProps): UseTripViewValues => {
  const [, { handleCreateTripBulk, handleUpdateTripBulk }] = useTrips();
  const [state, setState] = useTripViewState(({ state, setState }) => [state, setState]);
  const { trip = {}, original = {}, loopOrFlightNumber } = state;
  const onChange = useOnChange(setState);
  const stopsDisabled = mode === 'editing' && selected?.length > 1;
  const upsProviderId = useSession(({ state }) => state.config?.upsProviderId || '');

  const handleHide = async (): Promise<void> => {
    const isConfirmCancel: boolean = await onHide?.();
    if (isConfirmCancel) setState(initUseTripsModalState);
  };

  const handleSubmit = async (): Promise<void> => {
    trip.pilots = trip?.pilots || 0;
    trip.attendants = trip?.attendants || 0;
    trip.flightNumber = parseInt(`${trip?.flightNumber || 0}`);
    trip.loopName = trip?.loopName || '';
    if (trip?.id) {
      await handleUpdateTripBulk(trip, selected?.length > 1 ? selected || [] : [original]);
    } else {
      await handleCreateTripBulk(trip);
    }
    onSubmit?.(state);
  };
  const onSaveAndCopy = async (): Promise<void> => {
    trip.pilots = trip?.pilots || 0;
    trip.attendants = trip?.attendants || 0;
    trip.flightNumber = parseInt(`${trip?.flightNumber || 0}`);
    trip.loopName = trip?.loopName || '';
    await handleCreateTripBulk(trip, selected?.length > 1 ? selected || [] : [original]);
    onSubmit?.(state, true);
  };

  const selectedCount = selected ? Object.values(selected).length : 0;
  const uuid = useUuid();

  const onAddStop = (): void => {
    setState((current: UseTripViewState): UseTripViewState => {
      const lastScheduled = (current?.trip?.stops || []).at(-1)?.scheduled || current?.trip?.scheduled;
      const scheduled = lastScheduled ? new Datetime(lastScheduled).add(5, 'minutes').toString() : null;
      return {
        ...current,
        trip: {
          ...(current?.trip || {}),
          stops: [
            ...(current?.trip?.stops || []),
            {
              id: new Date().getTime().toString(),
              type: StopTypeEnum.Pu,
              airportFilter: current?.trip?.airportCode,
              scheduled,
            },
          ],
        },
      };
    });
  };
  const onBlurFlightNumber = (): void => {
    setState((current: UseTripViewState): UseTripViewState => {
      const value = current?.loopOrFlightNumber || '';
      if (!value)
        return { ...current, loopOrFlightNumber: '', trip: { ...(current?.trip || {}), flightNumber: undefined, loopName: undefined } };
      if (value.length > 4 || value.match(/\D/g))
        return { ...current, trip: { ...(current?.trip || {}), flightNumber: undefined, loopName: value } };
      return {
        ...current,
        loopOrFlightNumber: value.replace(/^[0]+/, '').padStart(4, '0'),
        trip: { ...(current?.trip || {}), flightNumber: value, loopName: undefined },
      };
    });
  };

  const validity = useMemo(() => {
    if (mode === 'creating' && !EditTripsModalValidator.keys.includes('scheduledDays')) EditTripsModalValidator.keys.push('scheduledDays');
    if (mode === 'editing' && EditTripsModalValidator.keys.includes('scheduledDays'))
      EditTripsModalValidator.keys.splice(EditTripsModalValidator.keys.indexOf('scheduledDays'), 1);
    const result = (EditTripsModalValidator || (() => ({})))(trip, selected, upsProviderId);
    return result;
  }, [mode, selected, trip]);
  const isValid = Validation.isValid(validity);

  const getTripData = useCallback(
    async (id: string): Promise<void> => {
      setState((current: UseTripViewState): UseTripViewState => ({ ...current, loading: true }));
      try {
        const trip = await getTripForModal(id);
        setState((current: UseTripViewState): UseTripViewState => ({ ...current, trip, original: trip }));
      } catch (error) {
        console.error(error);
      } finally {
        setState((current: UseTripViewState): UseTripViewState => ({ ...current, loading: false }));
      }
    },
    [setState]
  );

  const getAdditionalTripData = useCallback(
    async (id: string): Promise<void> => {
      setState((current: any): any => ({ ...current, loading: true }));
      try {
        // Run all the async functions concurrently
        const [flags, stops] = await Promise.all([getFlagByTripId(id), getStopByTripId(id)]);

        // Update the state once all the data is loaded
        setState((current: any): any => {
          const updatedState = {
            ...current,
            trip: { ...(current.trip || {}), flags, stops },
            original: { ...current.original, flags, stops },
          };
          return updatedState;
        });
      } catch (error) {
        console.error(error);
      } finally {
        setState((current: any): any => ({ ...current, loading: false }));
      }
    },
    [setState]
  );

  useEffect((): void => {
    if (typeof data === 'string') {
      getTripData(data);
      return;
    }
    setState((current) => ({
      ...current,
      trip: data || {},
      original: data || {},
      loopOrFlightNumber:
        mode === 'creating'
          ? data?.loopName || (data?.flightNumber ? zeroPadFlightNumber(data?.flightNumber) : '')
          : data?.loopName || zeroPadFlightNumber(data?.flightNumber),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTripData, data]);

  useEffect((): void => {
    if (!data?.id) return;
    getAdditionalTripData(data?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdditionalTripData, data?.id]);

  const returnState = { data, title, mode, trip, stopsDisabled, validity, loopOrFlightNumber, selectedCount, uuid, isValid };
  const returnMethods = { onChange, setState, onSaveAndCopy, handleSubmit, handleHide, onBlurFlightNumber, onAddStop };
  return [returnState, returnMethods];
};

export type UseTripViewValues = [
  {
    data: Partial<Trip>;
    title: string;
    mode: 'creating' | 'editing';
    trip: TripForm;
    stopsDisabled: boolean;
    validity: Record<string, Validation.Validity>;
    loopOrFlightNumber: string;
    selectedCount: number;
    uuid: string;
    isValid: boolean;
  },
  {
    onChange: OnChange;
    setState: (value: UseTripViewState | ((current: UseTripViewState) => UseTripViewState)) => void;
    onSaveAndCopy: () => void;
    handleSubmit: () => void;
    handleHide: () => void;
    onBlurFlightNumber: () => void;
    onAddStop: () => void;
  },
];

export default useTripView;
