import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import { Trip } from '@/models/gen/graphql';
import { useCallback } from 'react';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import { useTripModalState } from '@/features/Trips/components/TripModal';

const ActivityCell = (): JSX.Element => {
  const showTripModal = useTripModalState(({ setState }) => setState);
  const { data } = useSimpleTableRowContext<Trip>();

  const onClick = useCallback(
    () =>
      showTripModal({
        show: true,
        tab: 'activity',
        data,
        selected: [data],
      }),
    [data, showTripModal]
  );
  return (
    <SimpleTableCell name="ACTIVITY" className="w-sm-fixed d-print-none">
      <Tooltip content="Activity">
        <Button variant="icon" onClick={onClick}>
          <i className={`sv sv-bubble-dots fs-5`} />
        </Button>
      </Tooltip>
    </SimpleTableCell>
  );
};

export default ActivityCell;
