import { QueryInputType, queryInput } from '@/utils/custom';
import { SEARCH_TRIPS_TABLE_PAGE_SIZE, useSearchTripsTable } from '@/api/services/trips/searchTrips';
import { SortDirectionEnum, Trip, TripTableFormatEnum, TripTableSearch } from '@/models/gen/graphql';
import TripFilters, {
  AirlineTripsFiltersState,
  TripFiltersRefMethods,
  initAirlineTripsFiltersState,
} from '@/features/AirlineTrips/components/TripFilters';
import useAirlineTripTableState, {
  AirlineTripSortColumnEnum,
  DEFAULT_AIRLINE_TRIP_SORTING,
} from '@/features/AirlineTrips/components/TripsTable/hook';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import FormButton from '@/components/FormButton';
import HasPermission from '@/components/HasPermission';
import HtmlTripsTable from '@/features/AirlineTrips/components/TripsTable';
import PageInfo from '@/components/PageInfo';
import TripSettingsModal from '@/features/Trips/components/TripSettingsModal';
import { Validation } from '@/utils/validations';
import equal from 'fast-deep-equal/es6/react';
import useTripSettings from '@/features/AirlineTrips/components/TripSettingsModal/hook';
import { zeroPadFlightNumber } from '@/utils/numbers';

const TRIPS_PAGE_POLL_INTERVAL = 1000 * 60 * 2; // 2 minutes

const AirlineTrips = (): React.JSX.Element => {
  // queries
  const [
    { data: { rows = [], totalCount = 0, hasNextPage = false, endCursor = '0' } = {}, loading },
    { fetch, refetch, fetchMore, setData },
  ] = useSearchTripsTable();
  const [
    { data: { rows: priorityRows = [] } = {}, loading: loadingPriority },
    { fetch: fetchPriority, refetch: refetchPriority, setData: setPriorityData },
  ] = useSearchTripsTable();
  // state
  const setState = useAirlineTripTableState(({ setState }) => setState);
  const onSetTrips = useAirlineTripTableState(({ state }) => state.onSetTrips);
  const tripState = useAirlineTripTableState(({ state }) => state.trips);
  const sorting = useAirlineTripTableState(({ state }) => state.sorting);

  const setColumns = useTripSettings(({ state }) => state.setColumns);

  const [search, setSearch] = useState<string>(initAirlineTripsFiltersState.search);
  const [lastFormat, setLastFormat] = useState<TripTableFormatEnum>(initAirlineTripsFiltersState.format);
  //ref
  const lastFilters = useRef<AirlineTripsFiltersState>(null);
  const lastSorting = useRef<Array<{ column: AirlineTripSortColumnEnum; direction: SortDirectionEnum }>>(null);
  const tripFiltersRef = useRef<TripFiltersRefMethods>(null);

  const handleRefetch = useCallback(async (): Promise<void> => {
    lastTrips.current = [];
    if (lastFormat === TripTableFormatEnum.Current) refetchPriority();
    refetch();
    // clear selected state on search
    setState((current) => ({
      ...current,
      selected: new Map(),
    }));
  }, [lastFormat, refetchPriority, refetch, setState]);
  const onFilterSubmit = useCallback(
    async (filters: AirlineTripsFiltersState): Promise<void> => {
      lastTrips.current = [];
      // refetch if filters didn't changed
      if (equal(filters, lastFilters.current) && equal(sorting, lastSorting.current)) return handleRefetch();
      const tripSearch = convertTripsFiltersStateToQuery(filters, sorting);
      // fetch priority trips if format is current
      // get all priority trips
      if (filters.format === TripTableFormatEnum.Current) fetchPriority({ query: tripSearch, format: TripTableFormatEnum.Priority });
      // fetch trips table
      fetch({ query: tripSearch, format: filters.format }, { pageSize: SEARCH_TRIPS_TABLE_PAGE_SIZE });
      lastFilters.current = filters;
      lastSorting.current = sorting;
      setLastFormat(filters.format);
      // clear selected state on search
      setState((current) => ({
        ...current,
        selected: new Map(),
      }));
    },
    [fetch, fetchPriority, handleRefetch, setState, sorting]
  );
  const onFetchMore = useCallback(async (): Promise<void> => {
    if (loading) return;
    const tripSearch = convertTripsFiltersStateToQuery(lastFilters.current, sorting);
    fetchMore(
      { query: tripSearch, format: lastFilters.current.format },
      {
        page: Math.round((parseInt(endCursor) || 0) / SEARCH_TRIPS_TABLE_PAGE_SIZE),
        merge: true,
      }
    );
  }, [fetchMore, endCursor, lastFilters.current, sorting, loading]);
  const onReset = async (input: AirlineTripsFiltersState): Promise<void> => {
    setState((current) => ({
      ...current,
      sorting: DEFAULT_AIRLINE_TRIP_SORTING,
    }));
    setSearch(input.search);
    onFilterSubmit(input);
  };

  // FE search
  const onSearch = (val: string): void => setSearch(val);

  const lastTrips = useRef<Trip[]>([]);
  // we want to update the zustand state as soon as we have new data
  // so we use useMemo instead of useEffect so it updates immediately
  // instead of waiting for the component to render
  useMemo(() => {
    const result = lastFormat === TripTableFormatEnum.Current ? [...(rows || []), ...(priorityRows || [])] : rows || [];
    if (equal(lastTrips.current, result)) return;
    // update zustand trips state
    lastTrips.current = result;
    onSetTrips(result);
  }, [rows, priorityRows, lastFormat]);

  // convert rows to table
  const priorityTripIds = useMemo((): string[] => {
    if (lastFormat !== TripTableFormatEnum.Current) return [];
    const [_priorityTrips, priorityTripIds] = getTripsAndIds(priorityRows, tripState, search);
    return priorityTripIds;
  }, [priorityRows, tripState, search, lastFormat]);

  const [trips, tripIds] = useMemo((): [Map<string, Trip>, string[]] => getTripsAndIds(rows, tripState, search), [rows, tripState, search]);

  const autoRefresh = useCallback(() => {
    handleRefetch();
  }, [handleRefetch]);
  // refetch at interval
  // TODO: useInterval(autoRefresh, TRIPS_PAGE_POLL_INTERVAL);

  // set to state refetch on mount
  useEffect((): void => {
    setState((current) => ({ ...current, refetch: handleRefetch }));
  }, [handleRefetch, setState]);

  useLayoutEffect((): void => {
    setColumns();
  }, [setColumns]);

  const showPriorityTable = lastFormat === TripTableFormatEnum.Current && (!!priorityTripIds.length || loadingPriority);

  return (
    <>
      <TripFilters
        onSubmit={onFilterSubmit}
        onReset={onReset}
        onSearch={onSearch}
        sorting={sorting}
        ref={tripFiltersRef}
        loading={loading || loadingPriority}
      />
      <PageInfo>
        Total Trips: {tripIds.length} / {totalCount}
      </PageInfo>
      {(loading || loadingPriority) && (
        <PageInfo>
          <i className="fa fa-spinner fa-pulse" />
        </PageInfo>
      )}
      <div className="d-flex flex-column align-items-center {gap:2.8rem;padding-top:2rem;padding-bottom:2.8rem} {width:100%}>div">
        {showPriorityTable && (
          <div className="PriorityTrips">
            <HtmlTripsTable title="Late Outbound / Flagged Trips" rows={priorityTripIds} />
          </div>
        )}
        <div className="Trips">
          <HtmlTripsTable title="All Trips" rows={tripIds} fetchMore={hasNextPage ? onFetchMore : undefined} />
        </div>
      </div>
      <TripSettingsModal />
    </>
  );
};

type TripsTableShortcutsProps = {
  deleteAll: () => void;
  uaCancels: () => void;
  zeroRates: () => void;
};
const TripsTableShortcuts = ({ deleteAll, uaCancels, zeroRates }: TripsTableShortcutsProps): React.JSX.Element => {
  return (
    <>
      <FormButton variant="icon" onClick={deleteAll} icon={<i className="sv sv-trash2" />}>
        Delete All
      </FormButton>
      <FormButton variant="icon" onClick={uaCancels}>
        UA Cancels
      </FormButton>
      <FormButton variant="icon" onClick={zeroRates}>
        Zero Rates
      </FormButton>
    </>
  );
};

const getTripsAndIds = (tripRows: Trip[], tripState: Map<string, Trip>, search: string): [map: Map<string, Trip>, keys: string[]] => {
  if (!tripRows?.length) return [new Map(), []];
  const updatedRows = tripRows.map((row) => tripState.get(row.id) || row);
  // filter rows based on FE search
  const trips: Map<string, Trip> = searchTripsTableColumns(updatedRows, search);
  // format row ids for the table
  return [trips, Array.from(trips.keys())];
};

// takes the rows and search and returns the trips ids that match
const searchTripsTableColumns = (rows: Trip[], search: string): Map<string, Trip> => {
  const output: Map<string, Trip> = new Map();
  const lowerSearchTerm = search.toLowerCase();
  for (let i = 0; i < rows.length; i++) {
    const node = rows[i];
    // if nothing to search then add all
    if (!search) {
      output.set(node.id, node);
      continue;
    }
    // if something to search then add only if search matches
    // limit search to searchable columns
    const searchableColumns = [
      node.type,
      node.scheduled,
      node.trackFlight ?? '',
      node.kind,
      node.airportCode,
      node.servicerIataAirlineCode ?? '',
      zeroPadFlightNumber(node.flightNumber),
      node.loopName ?? '',
      node.pilots ?? 0,
      node.attendants ?? 0,
      node.driver?.employeeId ?? '',
      node.driver?.fullName ?? '',
      node.puLocation?.name ?? '',
      node.doLocation?.name ?? '',
      node.rate?.rate ?? '',
      node.vehicle?.trackingId ?? '',
      node.payerProvider?.displayName ?? '',
    ]
      .join(' ')
      .toLowerCase();

    const match = searchableColumns.includes(lowerSearchTerm);
    if (!match) continue;
    output.set(node.id, node);
  }
  return output;
};

const convertTripsFiltersStateToQuery = (
  filters: AirlineTripsFiltersState,
  sorting: Array<{ column: AirlineTripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch[] => {
  // default query
  const query: TripTableSearch = {
    latestScheduled: queryInput.date([filters.from, filters.to]),
  };

  // destructure whatever keys don't map to TripTableSearch
  const { search: _search, format: _format, from: _from, to: _to, ...remainingFilters } = filters || {};
  // loop through rest of the filters
  for (const filterKey in remainingFilters) {
    if (!Validation.isTruthy(remainingFilters[filterKey])) continue;
    const filterValue = remainingFilters[filterKey];

    let key = filterKey as keyof TripTableSearch;
    if (filterKey === 'flightNumber') key = isLoopName(filterValue) ? 'loopName' : 'flightNumber';
    if (filterKey === 'rateAmount' && !HasPermission.check('allowSearchTripsByRate')) continue;

    // if there is a value, add it to the query
    const { value, type } = convertValueToQueryInput(filterKey, filterValue);

    query[key] = queryInput(value, type);
  }

  const result = applySortingToTripQuery(query, sorting);

  return [result];
};

const convertValueToQueryInput = (key: string, value: any): { type: QueryInputType; value: any } => {
  const type = QueryInputType.OR;
  if (key === 'rateAmount' && (parseFloat(`${value}`) === 0 || value === 'NO_RATE')) return { type: QueryInputType.INORNULL, value: [0.0] };
  if (value === null) return { type: QueryInputType.ISNULL, value: [] };
  return { type, value };
};

const applySortingToTripQuery = (
  query: TripTableSearch,
  sorting: Array<{ column: AirlineTripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch => {
  if (!sorting?.length) return query;
  const output = { ...query };

  for (let i = 0; i < sorting.length; i++) {
    const { column, direction } = sorting[i];
    if (!direction) continue;
    const { values = [], type = QueryInputType.DEFAULT } = output[column] || ({} as any); // TODO: fix this any
    output[column] = queryInput(values, type, direction, i);
  }

  return output;
};

const isLoopName = (value: string): boolean => /\D/g.test(value) || value.length > 4;

export default AirlineTrips;
