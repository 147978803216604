import './styles.scss';

import { DATE_FE_FORMAT, TODAY } from '@/constants';
import { Datetime, getClasses } from '@/utils';
import Input, { InputProps } from '@/components/Input';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { MutableRefObject, forwardRef, useMemo, useRef } from 'react';

import Calendar from '@/components/Calendar/new';
import { DATE_INPUT_FORMAT } from '@/constants';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import useClassNames from '@/hooks/useClassNames';
import useUuid from '@/hooks/useUuid';

export type DateInputProps = {
  className?: string;
  name: string;
  value: string;
  onChange: any;
  onBlur?: any;
  isValid?: boolean;
  isInvalid?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  picker?: 'week' | 'month';
  [x: string]: any;
};

const DateInput = ({
  className,
  name,
  value,
  onChange,
  isValid,
  isInvalid,
  readOnly,
  disabled,
  picker,
  ...props
}: DateInputProps): JSX.Element => {
  const ref: MutableRefObject<any> = useRef();
  const classes = useClassNames(
    className,
    'flex-grow-1 border',
    isValid !== undefined && !!isValid ? 'isValid border-success' : undefined,
    isInvalid !== undefined && !!isInvalid ? 'isInvalid border-danger' : undefined
  );
  const handleChange = (value: any): void => {
    if (value && typeof value !== 'string') {
      value = value.format(DATE_INPUT_FORMAT);
    }
    onChange({ target: { name, value } });
  };

  const onKeyDown = (event: any): void => {
    if (!ref?.current) return;
    const key = event.key;
    switch (key) {
      case 'Tab':
      case 'Enter': {
        event.preventDefault();
        event.stopPropagation();
        handleChange(event?.target?.value);
        if (key === 'Tab') ref.current.blur();
      }
      default: {
        break;
      }
    }
  };

  return (
    <DatePicker
      className={classes}
      name={name}
      value={dayjs(value || TODAY, DATE_INPUT_FORMAT)}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      size="small"
      suffixIcon={<i className="fa fa-regular fa-calendar" />}
      allowClear={false}
      inputReadOnly={!!readOnly}
      disabled={disabled}
      format={DATE_FE_FORMAT}
      picker={picker}
      {...props}
      placeholder={picker === 'week' ? '--/--/-- - --/--/--' : '--/--/--'}
      ref={ref}
    />
  );
};

// TODO: Expand on the logic here to allow for ranges and multiple dates.
// TODO: Once all functionality is in place, replace the old DateInput component.
export type DateInputNewProps = {
  value: string[];
  onChange: (selected: string, value: string[]) => void;
  range?: boolean;
} & Omit<InputProps, 'onChange' | 'value'>;

DateInput.New = (() => {
  const DateInputNew = ({ onChange, range, ...inputProps }: DateInputNewProps, ref) => {
    const uuid = useUuid();
    const value = useMemo(() => {
      if (range !== undefined && range !== false) {
        const from = inputProps?.value?.at(0);
        const to = inputProps?.value?.at(-1);
        if (!from || !to) return '';
        return [from, to].map((date: string): string => new Datetime(date).format(DATE_INPUT_FORMAT)).join(' - ');
      }
      const date = inputProps.value?.at?.(0);
      if (!date) return '';
      return new Datetime(date).format(DATE_INPUT_FORMAT);
    }, [inputProps.value, range]);

    return (
      <OverlayTrigger
        container={document.body}
        trigger="click"
        placement="auto"
        rootClose
        overlay={
          <Popover id={uuid}>
            <Popover.Body>
              <Calendar value={inputProps?.value} onChange={onChange} />
            </Popover.Body>
          </Popover>
        }
      >
        <Input
          {...inputProps}
          value={value}
          className={getClasses('DateInput', inputProps?.className)}
          ref={ref}
          readOnly
          data-props={JSON.stringify(inputProps)}
        />
      </OverlayTrigger>
    );
  };
  return forwardRef(DateInputNew);
})();

export default DateInput;
