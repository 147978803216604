import Field, { FieldProps } from '@/components/Field';
import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getServicerIataAirlineCodes } from '@/api/services/filters/airlines';

export type AirlineIataDropdownProps = Omit<QueryDropdownProps, 'query'>;

const AirlineIataDropdown = ({ name = 'airline', ...props }: AirlineIataDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getServicerIataAirlineCodes();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({
        label: option?.displayName,
        value: option?.values?.[0],
        keywords: option?.displayName.split(' '),
      })
    );
  }, []);

  return (
    <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Airline' } }} />
  );
};

export const AirlineIataDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & AirlineIataDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <AirlineIataDropdown {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default AirlineIataDropdown;
