import React, { useCallback, useMemo } from 'react';
import { SortDirectionEnum, Trip } from '@/models/gen/graphql';
import useTripSettings, { ColumnKeyEnum } from '@/features/Trips/components/TripSettingsModal/hook';
import useTripTableState, { TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import { SimpleTableColumnsProps } from '@/components/SimpleTable';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import SimpleTableSortCell from '@/components/SimpleTable/SimpleTableSortCell';

const TripTableSelectAll = ({ rows }: SimpleTableColumnsProps): React.JSX.Element => {
  const trips = useTripTableState(({ state }) => state.trips);
  const selected = useTripTableState(({ state }) => state.selected);
  const setState = useTripTableState(({ setState }) => setState);

  const filteredTrips = useMemo(() => {
    const output: Map<string, Trip> = new Map();
    rows.forEach((rowId) => output.set(rowId, trips.get(rowId)));
    return output;
  }, [rows, trips]);

  const isSelectAll = useMemo(() => {
    if (selected.size !== filteredTrips.size) return false;
    for (const tripId of selected.keys()) {
      if (!filteredTrips.has(tripId)) return false;
    }
    return true;
  }, [filteredTrips, selected]);

  const handleChange = useCallback(
    () => setState((current) => ({ ...current, selected: isSelectAll ? new Map() : filteredTrips })),
    [filteredTrips, isSelectAll, setState]
  );

  return <input type="checkbox" checked={isSelectAll} onChange={handleChange} />;
};

const TripTableHeader = ({ rows }: SimpleTableColumnsProps): React.JSX.Element => {
  const columns = useTripSettings(({ state }) => new Map(state.columns));
  const sorting = useTripTableState(({ state }) => state.sorting);
  const onSort = useTripTableState(({ state }) => state.onSort);
  const onSortOnly = useTripTableState(({ state }) => state.onSortOnly);

  const columnSortMap = useMemo(
    () =>
      sorting.reduce((acc, curr) => {
        acc[curr.column] = curr.direction;
        return acc;
      }, {}) as Record<TripSortColumnEnum, SortDirectionEnum>,
    [sorting]
  );

  const handleSort = useCallback(
    (...columns: TripSortColumnEnum[]): React.MouseEventHandler<HTMLButtonElement> =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        // is user holding shift key
        if (event.shiftKey) return onSort(columns);
        return onSortOnly(columns);
      },
    [onSort, onSortOnly]
  );
  return (
    <SimpleTableRow header>
      <SimpleTableCell name="SELECT" className="w-xs-fixed d-print-none">
        <TripTableSelectAll rows={rows} />
      </SimpleTableCell>
      {columns.get(ColumnKeyEnum.FLAGS) && (
        <SimpleTableCell name="FLAGS" className="w-sm-fixed d-print-none">
          <div className="w-100 d-flex justify-content-center gap-2">
            <i className="sv sv-flag-empty fs-5 {padding-top:0.05rem;}" />
            <i className="sv sv-message2 fs-5" />
          </div>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.TYPE) && (
        <SimpleTableCell name="TYPE" className="w-md-fixed">
          <SimpleTableSortCell onSort={handleSort(TripSortColumnEnum.type)} direction={columnSortMap[TripSortColumnEnum.type]}>
            TYPE
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_DATE) && (
        <SimpleTableCell name="SCHEDULED_DATE" className="w-sm-grow">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.latestScheduled)}
            direction={columnSortMap[TripSortColumnEnum.latestScheduled]}
          >
            DATE
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_TIME) && (
        <SimpleTableCell name="SCHEDULED_TIME" className="w-sm-grow">
          <SimpleTableSortCell onSort={handleSort(TripSortColumnEnum.latestScheduledUtc)} direction={columnSortMap.latestScheduledUtc}>
            SCH
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.ACTUAL) && (
        <SimpleTableCell name="ACTUAL" className="w-sm-grow">
          <SimpleTableSortCell onSort={handleSort(TripSortColumnEnum.actual)} direction={columnSortMap[TripSortColumnEnum.actual]}>
            ACT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.AIRPORT_CODE) && (
        <SimpleTableCell name="AIRPORT_CODE" className="w-md-fixed">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.airportCode)}
            direction={columnSortMap[TripSortColumnEnum.airportCode]}
          >
            CITY
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.IATA_CODE) && (
        <SimpleTableCell name="IATA_CODE" className=" w-sm-fixed">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.servicerIataAirlineCode)}
            direction={columnSortMap.servicerIataAirlineCode}
          >
            AL
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.FLIGHT_NUMBER) && (
        <SimpleTableCell name="FLIGHT_NUMBER">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.flightNumber, TripSortColumnEnum.loopName)}
            direction={columnSortMap[TripSortColumnEnum.flightNumber]}
          >
            FLT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.PILOT) && (
        <SimpleTableCell name="PILOT" className="w-sm-fixed">
          <SimpleTableSortCell onSort={handleSort(TripSortColumnEnum.pilots)} direction={columnSortMap[TripSortColumnEnum.pilots]}>
            PLT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.ATTENDANT) && (
        <SimpleTableCell name="ATTENDANT" className="w-sm-fixed">
          <SimpleTableSortCell onSort={handleSort(TripSortColumnEnum.attendants)} direction={columnSortMap[TripSortColumnEnum.attendants]}>
            FA
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.DRIVER) && (
        <SimpleTableCell name="DRIVER" className="text-left w-md-grow">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.driverFirstName, TripSortColumnEnum.driverLastName)}
            direction={columnSortMap[TripSortColumnEnum.driverFirstName]}
          >
            DRV
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.VEHICLE) && (
        <SimpleTableCell name="VEHICLE">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.vehicleTrackingId)}
            direction={columnSortMap[TripSortColumnEnum.vehicleTrackingId]}
          >
            VAN
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.PU_LOCATION) && (
        <SimpleTableCell name="PU_LOCATION" className="w-lg-grow">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.puLocationName)}
            direction={columnSortMap[TripSortColumnEnum.puLocationName]}
          >
            P/U
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.DO_LOCATION) && (
        <SimpleTableCell name="DO_LOCATION" className="w-lg-grow">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.doLocationName)}
            direction={columnSortMap[TripSortColumnEnum.doLocationName]}
          >
            D/O
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.CLIENT) && (
        <SimpleTableCell name="CLIENT">
          <SimpleTableSortCell
            onSort={handleSort(TripSortColumnEnum.payerProviderDisplayName)}
            direction={columnSortMap[TripSortColumnEnum.payerProviderDisplayName]}
          >
            CLT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.COMPLETION) && (
        <SimpleTableCell name="COMPLETION" className="w-sm-fixed d-print-none">
          <i className="sv sv-completion fs-5" />
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.ACTIVITY) && (
        <SimpleTableCell name="ACTIVITY" className="w-sm-fixed d-print-none">
          <i className="sv sv-bubble-dots fs-5" />
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.FCR) && (
        <SimpleTableCell name="FCR" className="w-sm-fixed d-print-none">
          <i className="sv sv-fcr fs-5" />
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.COMBINE) && (
        <SimpleTableCell name="COMBINE" className="w-sm-fixed d-print-none">
          <i className="sv sv-combine-icon fs-5 text-secondary" />
        </SimpleTableCell>
      )}
      {columns.get(ColumnKeyEnum.RATE) && (
        <SimpleTableCell name="RATE" className="w-sm-grow">
          <SimpleTableSortCell onSort={handleSort(TripSortColumnEnum.rateAmount)} direction={columnSortMap[TripSortColumnEnum.rateAmount]}>
            RATE
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
    </SimpleTableRow>
  );
};
export default React.memo(TripTableHeader);
