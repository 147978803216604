import { Alias, Location, Trip } from '@/models/gen/graphql';
import { MouseEvent, useEffect, useState } from 'react';
import { generateDirectionsLink, parsePhoneNumber } from '@/utils';

import { Button } from 'react-bootstrap';
import LoadingSpinner from '@/components/LoadingSpinner';
import React from 'react';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import useDebouncedClick from '@/hooks/useDebouncedClick';
import useDelayCallback from '@/hooks/useDelayCallback';
import { useGetAliasForLocation } from '@/api/services/locations/searchLocations';
import { useGlobalEditLocationModalState } from '@/components/GlobalEditLocationModal';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';

type LocationCellProps = {
  type: 'pickup' | 'dropoff';
};

const LocationCell = ({ type }: LocationCellProps): JSX.Element => {
  const cellName = type === 'pickup' ? 'PU_LOCATION' : 'DO_LOCATION';
  const locationKey = type === 'pickup' ? 'puLocation' : 'doLocation';
  const locationIdKey = type === 'pickup' ? 'puLocationId' : 'doLocationId';

  const { data: { [locationIdKey]: locationId, [locationKey]: location, puLocation, doLocation } = {} } = useSimpleTableRowContext<Trip>();
  const { name, address, stateCode, zipCode, cityName, phoneNumber } = location || {};
  const [copied, setCopied] = useState(false);
  const showEditLocationModal = useGlobalEditLocationModalState(({ setState }) => setState);
  const onClick = useDebouncedClick((event: MouseEvent<HTMLButtonElement>, clickCount: number, resetCount: () => void) => {
    event.stopPropagation();
    if (clickCount === 1)
      showEditLocationModal({
        show: true,
        data: location,
        onSubmit: undefined,
        onAfterSubmit: undefined,
        onHide: undefined,
      });
    resetCount();
  });
  const onDoubleClick = (event) => {
    event.stopPropagation();
    const formatLocation = (location: Location | undefined): string =>
      `${location?.name || ''}, ${location?.address || ''}, ${location?.cityName || ''}, ${location?.stateCode || ''} ${location?.zipCode || ''}`;
    const puStreet = formatLocation(puLocation);
    const doStreet = formatLocation(doLocation);
    const url = generateDirectionsLink(puStreet, doStreet);
    window.open(url, '_blank');
  };
  const street = `${address}, ${cityName}, ${stateCode} ${zipCode}`;
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`${name}\n${street}`);
      setCopied(true);
    } catch (err) {
      console.error('LocationCell: Failed to copy - ', err);
    }
  };

  return (
    <SimpleTableCell name={cellName} className="w-lg-grow" onDoubleClick={onDoubleClick}>
      <Tooltip
        interactive
        onHide={() => setCopied(false)}
        content={
          <Tooltip content={<span className={copied ? 'text-success' : ''}>{copied ? 'Copied' : 'Click to copy address'}</span>}>
            <Button variant="icon" className="Link link-normal text-center pointer" onClick={handleCopyToClipboard}>
              {name}
              <br />
              {street}
              <br />
              {parsePhoneNumber(phoneNumber, ' ')}
            </Button>
          </Tooltip>
        }
        placement="left"
      >
        <Tooltip content={<small>Click to edit location</small>}>
          <Button variant="icon" onClick={onClick}>
            {name || '--'}
          </Button>
        </Tooltip>
      </Tooltip>
      <LocationAliasTooltip locationId={locationId} />
    </SimpleTableCell>
  );
};

const LocationAliasTooltip = ({ locationId }: { locationId: string }): JSX.Element => {
  const [{ data, loading }, { fetch: getAliasForLocation }] = useGetAliasForLocation();
  const { rows: aliases = [] } = data || {};
  const [called, setCalled] = useState(false);
  const [copied, setCopied] = useState(false);

  const [handleMouseEnter, handleMouseLeave] = useDelayCallback((): void => {
    setCalled(true);
    getAliasForLocation(locationId);
  }, 250);

  useEffect((): (() => void) => {
    return (): void => {
      setCalled(false);
      setCopied(false);
    };
  }, []);

  const handleClick = async () => {
    await navigator.clipboard.writeText(aliases?.map?.(({ name }: Alias): string => name)?.join('\n') || '');
    setCopied(true);
  };

  return (
    <Tooltip
      content={
        <>
          {/* display loading spinner if the request is not called */}
          {(!called || loading) && <LoadingSpinner size="sm" />}
          {called && !loading && !aliases?.length && <strong>No Aliases</strong>}
          {called && !loading && !!aliases?.length && (
            <>
              <div>
                <strong>Aliases:</strong>
                <strong> ({aliases?.length})</strong>
                <span> - </span>
                {!copied && <small>Click to Copy</small>}
                {copied && <small className="text-green">Copied</small>}
              </div>
              <ul className="m-0">{aliases?.map?.(({ name }: Alias, a: number): JSX.Element => <li key={a}>{name}</li>)}</ul>
            </>
          )}
        </>
      }
      placement="right"
    >
      <Button variant="icon" onClick={aliases?.length ? handleClick : undefined}>
        <i
          className="fa fa-circle-info pointer d-print-none ms-2 {opacity:0.4;}"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </Button>
    </Tooltip>
  );
};

export default React.memo(LocationCell);
