import { Datetime, titleCase } from '@/utils';
import { TrackFlightLabelEnum, Trip, TripKindEnum } from '@/models/gen/graphql';

import { Badge } from 'react-bootstrap';
import { ReactNode } from 'react';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';

const LATE_THRESHOLD_MINUTES = 1;
const VERY_LATE_THRESHOLD_HOURS = 5;

const getActualTimeVariant = (scheduled: string, actual: Datetime): string => {
  if (!actual || !scheduled) return;
  const actualDatetime = actual.asDayjs();
  if (actualDatetime.isBefore(scheduled)) return 'success';
  const isLate = actualDatetime.diff(scheduled, 'minutes') >= LATE_THRESHOLD_MINUTES;
  const isVeryLate = actualDatetime.diff(scheduled, 'hours') >= VERY_LATE_THRESHOLD_HOURS;
  if (isLate && !isVeryLate) return 'warning-dark';
  if (isVeryLate) return 'danger';
};

const ActualCell = (): ReactNode => {
  const { data: { trackFlight, kind, scheduled } = {} } = useSimpleTableRowContext<Trip>();
  const { actual, arrivalGate, arrivalTerminal, label } = trackFlight || {};

  const actualDatetime = new Datetime(actual);
  const variant = getActualTimeVariant(scheduled, actualDatetime);

  return (
    <SimpleTableCell name="ACTUAL" className="flex-column gap-1 w-sm-grow">
      {!actual && '--'}
      {actual && label && (
        <Tooltip
          content={
            <>
              <div>{`Status: ${titleCase(label?.replace('_', ' ') || '')}`}</div>
              {label === TrackFlightLabelEnum.EnRoute && <div>{`ETA: ${actualDatetime.time || 'Unknown'}`}</div>}
              {kind === TripKindEnum.Arr && (
                <>
                  {arrivalGate && <div>Gate: {arrivalGate}</div>}
                  {arrivalTerminal && <div>Terminal: {arrivalTerminal}</div>}
                </>
              )}
            </>
          }
        >
          <Badge className="d-flex align-items-center justify-content-center fs-6" bg={variant}>
            {label[0]}
          </Badge>
        </Tooltip>
      )}
      {actual && <span className={`text-${variant}`}>{actualDatetime.time}</span>}
    </SimpleTableCell>
  );
};

export default ActualCell;
