import { FilterOption } from '@/models/gen/graphql';
import { GetFlagsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetFlagsGraphApiResponse = GraphApiResponse<typeof GetFlagsDocument>;

const getFlagsResponseSelector = (res: GetFlagsGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.flag as Array<FilterOption>;

const [runGetFlags] = graphApi(GetFlagsDocument, {
  onError: (res: GetFlagsGraphApiResponse): void => handleError(res, { notification: { title: 'Get Flags' } }),
});

export const getFlags = async (): Promise<Array<FilterOption>> => {
  const res = await runGetFlags();
  return getFlagsResponseSelector(res);
};

export const useGetFlags = createGraphApiHook(getFlags);
