import './styles.scss';

import React, { useEffect, useLayoutEffect } from 'react';
import { Validation, generateQueryString, getClasses, getCookies, logout, parseQueryString } from '@/utils';

import DebugTools from '../DebugTools';
import Header from '@/components/Header';
import { LoadingBlur } from '@/components/LoadingSpinner';
import ModalContainer from '@/components/ModalContainer';
import NotificationWrapper from '@/components/Notification/NotificationWrapper';
import { clearNotifications } from '@/utils/custom';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import { useSearchParams } from 'react-router-dom';
import useSession from '@/state/session';
import useSocket from '@/hooks/useSocket';
import useVersionCheck from '@/hooks/useVersionCheck';
import NotAllowed from '@/components/ProtectedRoute/NotAllowed';

const ProtectedRoute = ({ title, children, className }: { title: string; children: any; className?: string }): JSX.Element => {
  const env = useSession(({ state }) => state.env);
  const user = useSession(({ state }) => state.user);
  const allowedURLS = useSession(({ state }) => state.allowedUrls);
  const setSession = useSession(({ setState }) => setState);
  const [query] = useSearchParams();
  const isLocal = env === 'local';
  const isDebug = query.has('debug');
  const socket = useSocket();

  useDocumentTitle(title || '');
  useEffect((): void => {
    const { token } = getCookies('token');
    const deprecatedPersistedAppState = JSON.parse(localStorage.getItem('persist:app'));
    if (Validation.isNil(user) && deprecatedPersistedAppState?.session?.user) {
      setSession((current) => ({
        ...current,
        user: deprecatedPersistedAppState?.session?.user,
        config: deprecatedPersistedAppState?.config,
      }));
      return;
    }
    if (!token || Validation.isNil(user)) logout();
  }, [user]);
  useLayoutEffect((): void => {
    clearNotifications();
    const { token } = getCookies('token');
    if (socket?.connected || !token) return;
    const queryObject = typeof socket?.io?.opts?.query === 'string' ? parseQueryString(socket?.io?.opts?.query) : socket?.io?.opts?.query;
    const updatedQuery = generateQueryString({
      ...(queryObject || {}),
      Authorization: `Bearer ${token}`,
    });
    if (updatedQuery !== socket.io.opts.query) {
      socket.io.opts.query = updatedQuery;
    }
    socket?.connect();
  }, [socket]);
  useVersionCheck();
  if (!user) return <LoadingBlur loading={true} />;
  if (allowedURLS && !allowedURLS.includes(window.location.pathname)) {
    return <NotAllowed />;
  }
  return (
    <>
      <Header />
      <NotificationWrapper />
      <div className={getClasses('RouteContent', className)}>
        {children}
        <div id="Sidebar-Left" className="d-flex flex-column py-2 ps-2 {font-size:1.5rem;}_.btn">
          <div className="Sidebar-Top d-flex flex-column justify-content-start flex-grow-1 gap-2" />
          <div className="Sidebar-Bottom d-flex flex-column justify-content-end flex-grow-1 gap-2" />
        </div>
        <div id="Sidebar-Right" className="d-flex flex-column py-2 ps-2 {font-size:1.5rem;}_.btn">
          <div className="Sidebar-Top d-flex flex-column justify-content-start flex-grow-1 gap-2" />
          <div className="Sidebar-Bottom d-flex flex-column justify-content-end flex-grow-1 gap-2" />
        </div>
      </div>
      <div id="RouteContent-Footer"></div>
      <ModalContainer />
      {isLocal && isDebug && <DebugTools />}
    </>
  );
};

export default ProtectedRoute;
