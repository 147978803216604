import { Rate, Trip, UpdateRateOnTripResponse } from '@/models/gen/graphql';
import { Validation, handleError } from '@/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTripTableState, { TripTableState } from '@/features/Trips/components/TripsTable/hook';

import { Button } from 'react-bootstrap';
import CurrencyInput from '@/components/CurrencyInput';
import LoadingSpinner from '@/components/LoadingSpinner';
import React from 'react';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import updateRateOnTrips from '@/api/services/rates/updateRateOnTrips';
import useDelayCallback from '@/hooks/useDelayCallback';
import { useGetCorrectRatesForTrips } from '@/api/services/rates/getCorrectRatesForTrips';
import { useRatesReportModal } from '@/components/RateReportModal';
import { useRunRateAmountOnTrips } from '@/api/services/trips/runRateAmountOnTrips';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

const RateCell = ({ onSetRow, onDoubleClick }: { onSetRow: TripTableState['onSetRow']; onDoubleClick: any }): React.JSX.Element => {
  const { data: { id, rate } = {} } = useSimpleTableRowContext<Trip>();
  const selected = useTripTableState(({ state }) => state.selected);
  const [editing, setEditing] = useState(false);

  const dedupeSelected = useMemo((): string[] => Array.from(new Set([...Array.from(selected.keys()), id])), [selected, id]);

  return (
    <SimpleTableCell
      name="RATE"
      className="w-sm-grow d-flex justify-between align-items-center ge-2 flex-nowrap"
      onDoubleClick={onDoubleClick}
    >
      {!editing && <SuggestRate rowId={id} selected={dedupeSelected} onSetRow={onSetRow} />}
      <InlineEditRate
        rate={rate?.rate || 0}
        rowId={id}
        selected={dedupeSelected}
        onSetRow={onSetRow}
        editing={editing}
        setEditing={setEditing}
      />
    </SimpleTableCell>
  );
};

const DisplayRate = ({ rate }: { rate: number }): React.JSX.Element => {
  const parsed = parseFloat(`${rate}`) || 0;
  const formattedWholeNumber = parseInt(`${rate}`.split('.')[0]).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <>
      {rate % 1 === 0 ? (
        parsed
      ) : (
        <>
          {formattedWholeNumber}
          <sup>{parsed.toFixed(2).split('.')[1]}</sup>
        </>
      )}
    </>
  );
};

type InlineEditRateProps = {
  rowId: string;
  rate: number;
  selected: string[];
  onSetRow: TripTableState['onSetRow'];
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};
const InlineEditRate = ({ rate, rowId, selected, onSetRow, editing, setEditing }: InlineEditRateProps): React.JSX.Element => {
  const [{ loading }, { fetch: runRateAmountOnTrips }] = useRunRateAmountOnTrips();
  const [value, setValue] = useState('');

  const onSubmitInlineRate = useCallback(
    async (value: string): Promise<void> => {
      try {
        const amount = parseFloat(`${value}`);
        if (!Validation.isNumber(amount)) throw new Error('invalid amount');
        setEditing(false);
        // format bulk rate
        const payload = selected.map((tripId: string): { tripId: string; amount: number } => ({
          tripId,
          amount,
        }));
        const res = await runRateAmountOnTrips(payload);
        if (!res.length) throw new Error('failed to update rate');
        const { rateId, rateGroupId } = res[0];
        const updates: Partial<Trip> = {
          id: rowId,
          rate: {
            id: rateId,
            rateGroupId: rateGroupId,
            rate: amount,
          } as Rate,
        };

        const tripIds = res.map((a): string => a.tripId);

        onSetRow(updates, tripIds);
        setEditing(false);
      } catch (err) {
        handleError(err, { notification: { title: 'Update Trip Rate' } });
      }
    },
    [selected, rowId, onSetRow, setEditing, runRateAmountOnTrips]
  );

  useEffect((): void => {
    setValue(`${rate ?? ''}`);
  }, [rate]);

  return (
    <>
      {editing && (
        <CurrencyInput
          name="rate"
          className="InputCell"
          value={value}
          onChange={setValue}
          onBlur={() => setEditing(false)}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                // Submit the rate, and fall through to close the edit.
                onSubmitInlineRate(event.target.value);
              case 'Escape':
                // Reset local value and finish editing.
                setValue(`${rate ?? ''}`);
                return setEditing(false);
              default:
                // Do nothing so the input can be edited.
                break;
            }
          }}
          autoFocus
        />
      )}
      {!loading && !editing && (
        <Button className="px-0" variant="icon" onClick={(): void => setEditing(true)}>
          {Validation.isNumber(rate) ? <DisplayRate rate={rate} /> : '--'}
        </Button>
      )}
      {loading && !editing && (
        <span>
          <LoadingSpinner size="sm" />
        </span>
      )}
    </>
  );
};

const SuggestRate = ({
  rowId,
  selected,
  onSetRow,
}: {
  rowId: string;
  selected: string[];
  onSetRow: TripTableState['onSetRow'];
}): React.JSX.Element => {
  const [{ data = [], loading }, { fetch: getCorrectRatesForTrips }] = useGetCorrectRatesForTrips();
  const [called, setCalled] = useState(false);
  const setState = useTripTableState(({ setState }) => setState);
  const showRateReportModal = useRatesReportModal(({ setState }) => setState);
  const { onRefetch } = useTripsTableContext();

  const onEditRateReport = useCallback(
    () =>
      showRateReportModal({
        show: true,
        tripIds: Object.keys(selected),
        onSubmit: async () => {
          // clear selected state on trip modal submit
          setState((current) => ({ ...current, selected: new Map() }));
          onRefetch();
        },
      }),
    [showRateReportModal, selected, setState, onRefetch]
  );

  const suggestedRate = data[0]?.newRate;
  const displayRateReport = selected?.length > 1 && selected?.includes(rowId);

  const applyRateSuggestion = useCallback(async (): Promise<UpdateRateOnTripResponse[]> => {
    try {
      const res = await updateRateOnTrips([{ rateHistoryId: suggestedRate?.id, tripId: rowId }]);
      if (!res.length) throw new Error('failed to update rate');
      const updates: Partial<Trip> = {
        id: rowId,
        rate: {
          id: suggestedRate?.id,
          rateGroupId: suggestedRate?.rateGroupId,
          rate: suggestedRate?.rate,
        } as Rate,
      };

      const tripIds = res.map((a): string => a.tripId);

      onSetRow(updates, tripIds);
      return res;
    } catch (err) {
      handleError(err, { notification: { title: 'Update Trip Rate' } });
    }
  }, [suggestedRate?.id, suggestedRate?.rateGroupId, suggestedRate?.rate, rowId, onSetRow]);

  const [handleMouseEnter, handleMouseLeave] = useDelayCallback((): void => {
    if (displayRateReport) return;
    setCalled(true);
    getCorrectRatesForTrips([rowId]);
  }, 250);

  useEffect((): (() => void) => {
    return (): void => setCalled(false);
  }, []);

  return (
    <Tooltip
      placement="left"
      interactive={!!(displayRateReport || suggestedRate)}
      content={
        <div className="d-flex gap-2">
          {displayRateReport && (
            <Button size="sm" onClick={onEditRateReport} className="{white-space:nowrap}">
              Rate Report
            </Button>
          )}
          {/* display loading spinner if the request is not called */}
          {(!called || loading) && !displayRateReport && <LoadingSpinner size="sm" />}
          {called && !loading && !displayRateReport && !suggestedRate && (
            <span className="{white-space:nowrap;line-height:2rem;}">No Suggested Rate</span>
          )}
          {called && !loading && !displayRateReport && suggestedRate && (
            <>
              <span className="{white-space:nowrap;line-height:2rem;}">{`Suggested ${suggestedRate.type} $${suggestedRate.rate.toFixed(2)}`}</span>
              <Button size="sm" onClick={applyRateSuggestion}>
                Apply
              </Button>
            </>
          )}
        </div>
      }
    >
      <i
        className="fa fa-circle-info pointer pe-2 d-print-none {opacity:0.4;}"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Tooltip>
  );
};

export default React.memo(RateCell);
