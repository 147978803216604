import { MouseEventHandler, ReactNode, useMemo } from 'react';

import { Button } from 'react-bootstrap';
import { SortDirectionEnum } from '@/models/gen/graphql';
import Tooltip from '@/components/Tooltip';
import { getClasses } from '@/utils';

export type SimpleTableSortCellProps = {
  direction: SortDirectionEnum;
  onSort: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
};

const SimpleTableSortCell = ({ children, onSort, direction }: SimpleTableSortCellProps): JSX.Element => {
  const [classes, tooltip] = useMemo(
    (): [string, string] => [
      getClasses('fa', direction ? `fa-sort-${direction.toLowerCase()}` : 'fa-sort opacity-25'),
      direction && (direction === SortDirectionEnum.Asc ? 'Ascending' : 'Descending'),
    ],
    [direction]
  );

  return (
    <Button className="SimpleTable-SortCell" variant="icon" onClick={onSort}>
      <span>{children}</span>
      <Tooltip content={tooltip}>
        <span className="ms-1 d-print-none">
          <i className={classes} />
        </span>
      </Tooltip>
    </Button>
  );
};

export default SimpleTableSortCell;
