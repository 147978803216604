import {
  AirportHasUser,
  CreateGroupHasUserInput,
  CreatePermissionHasUserInput,
  DeleteGroupHasUserInput,
  DeleteLicenseInput,
  DeletePermissionHasUserInput,
  Group,
  License,
  RunUpdateUserProfileInput,
  UpdateUserInput,
  User,
} from 'models/gen/graphql';
import { Validation, createNotification, generateUpdateBulkPayload, getDiff, runQueryKeys } from '../utils';
import { createContext, useContext } from 'react';

import Logger from '../utils/logs';
import { Toast } from '../models';
import createGroupHasUserBulk from '@/api/services/users/createGroupHasUserBulk';
import createLicenseBulk from '@/api/services/users/createLicenseBulk';
import createPermissionHasUserBulk from '@/api/services/users/createPermissionHasUserBulk';
import createUserBulk from '@/api/services/users/createUserBulk';
import deleteUserBulk from '@/api/services/users/deleteUserBulk';
import reportProblem from '@/api/services/users/reportProblem';
import updateUserProfileBulk from '@/api/services/users/updateUserProfileBulk';
import useActions from './useActions';
import useConfirmation from '../hooks/useConfirmation';
import { validateLicense } from '../queries/users/createLicenseBulk';
import { validateUser } from '../queries/users/createUserBulk';

interface UserUpdate extends User {
  removedLicenses: License[];
}
interface UserCreate extends User {
  confirmPassword: string;
}

const log = Logger.of('UseUsers');

const UserContext = createContext({});
const useUsersContext = (): {} => useContext(UserContext);
const initUseUsersState = {};
const useUsers = (): any[] => {
  const confirmDelete = useConfirmation();
  // Config useActions
  const [state, setState] = useActions(initUseUsersState, (): any => {
    // Crud Handlers
    const handleDeleteUserBulk = async (selected: string[]): Promise<any> => {
      try {
        await confirmDelete();
        return await deleteUserBulk(selected);
      } catch (err) {
        console.error(err);
      }
    };

    const handleCreateUserBulk = async (create: UserCreate): Promise<any> => {
      const { licenses = [], airportCodes: permissions = [], groups = [], confirmPassword = '' } = create;

      // Validate User Inputs
      const invalid = [];
      if ((create.username || '') === '') invalid.push('username');
      if (create.password !== confirmPassword || create.password === '') invalid.push('password');
      if ((create.firstName || '') === '') invalid.push('firstname');
      if ((create.lastName || '') === '') invalid.push('lastname');
      if (!Validation.isEmail(create.email)) invalid.push('email');
      if (!Validation.isValidUUID(create.roleId)) invalid.push('role');
      if (!Validation.isValidUUID(create.companyId)) invalid.push('company');
      if (isNaN(create.active)) invalid.push('status');
      if (invalid.length > 0) {
        createNotification(`Invalid fields: ${invalid.sort().join(', ')}`, Toast.Type.DANGER, 'Create User');
        return;
      }

      const res = await createUserBulk([create]);

      for (let r = 0; r < res.length; r++) {
        const { error, node: { id = '' } = {} } = res[r];

        if (!Validation.isNil(error)) throw new Error(error);

        // Create License
        if (licenses.length > 0) {
          const createLicensePayload = licenses.map((node: License): License => ({ ...node, userId: id }));
          await createLicenseBulk(createLicensePayload);
        }

        // Create Permission Has User
        if (permissions.length > 0) {
          const createPermissionHasUserPayload: CreatePermissionHasUserInput[] = permissions.map(
            (permission: any): CreatePermissionHasUserInput => ({
              permissionId: permission?.id || permission,
              userId: id,
            })
          );
          await createPermissionHasUserBulk(createPermissionHasUserPayload);
        }

        // Create Group Has User
        if (groups.length > 0) {
          const createGroupHasUserPayload: CreateGroupHasUserInput[] = groups.map(
            (group: any): CreateGroupHasUserInput => ({ groupId: group?.id || group, userId: id })
          );
          await createGroupHasUserBulk(createGroupHasUserPayload);
        }
      }
      return res;
    };

    const handleUpdateUserBulk = async (
      { groups = [], airportCodes: permissions = [], licenses = [], ...update }: UserUpdate,
      selected: User[]
    ): Promise<any> => {
      try {
        if (!(selected || [])?.length) throw new Error('handleUpdateUserBulk: 0 rows selected');
        if (Object.values(update || {}).length === 0) throw new Error('handleUpdateUserBulk: update contains 0 values');

        //Get user updates
        const {
          groups: originalGroups,
          airportCodes: originalPermissions,
          licenses: originalLicenses,
          ...original
        } = (selected || [])?.find((node: User): boolean => node.id === update?.id) || {};

        if (!Object.values(original || {}).length) throw new Error('handleUpdateUserBulk: original contains 0 values');

        const [{ avatar: _avatar, ...partial }] = getDiff(original, update); // getdiff 0 idx returns the update partial

        const bundle = (selected || []).map((node: User): User => ({ ...partial, id: node?.id }));
        const userUpdates = generateUpdateBulkPayload(bundle);

        // Format relational user data
        // Update License
        const updateLicenses = (licenses || [])
          .filter((node: License): boolean => !!node.id)
          .filter((node: License): boolean => {
            const originalLicenseTemp = originalLicenses.find((el: License): boolean => el.id === node.id);
            const temp = runQueryKeys(validateLicense.keys, getDiff(originalLicenseTemp, node)[0]);
            return Object.values(temp || {}).length > 0;
          })
          .map((node: License): License => {
            const originalLicenseTemp = originalLicenses.find((el: License): boolean => el.id === node.id);
            const temp = runQueryKeys(validateLicense.keys, getDiff(originalLicenseTemp, node)[0]);
            return { ...temp, id: node.id };
          });

        // Create License
        const createLicenses = (licenses || []).filter((node: License): boolean => !node.id);

        // Delete License
        const deleteLicenses = (originalLicenses || [])
          .filter((node: License): boolean => !(licenses || []).filter((el: any): boolean => el?.id === node?.id).length)
          .map(({ id }: License): DeleteLicenseInput => ({ id }));

        // Create permissionHasUser
        const createPermissionHasUser = (permissions || []).filter(
          (node: any): boolean =>
            !(originalPermissions || []).filter((el: AirportHasUser): boolean => (el?.id || el) === (node?.id || node)).length
        );

        // Delete permissionHasUser
        const deletePermissionHasUser = (originalPermissions || []).filter(
          (node: AirportHasUser): boolean => !(permissions || []).filter((el: any): boolean => (el?.id || el) === (node?.id || node)).length
        );

        // Create GroupHasUser
        const createGroupHasUser = (groups || []).filter(
          (node: any): boolean => !(originalGroups || []).filter((el: Group): boolean => (el?.id || el) === (node?.id || node)).length
        );

        // Delete GroupHasUser
        const deleteGroupHasUser = (originalGroups || []).filter(
          (node: Group): boolean => !(groups || []).filter((el: any): boolean => (el?.id || el) === (node?.id || node)).length
        );

        // Format update payload
        const updateUserProfileInputs: RunUpdateUserProfileInput[] = (userUpdates || []).map(
          ({ value, ...node }: UpdateUserInput, idx: number): RunUpdateUserProfileInput => {
            const query = (node.query || [])[0] || {};
            const output: RunUpdateUserProfileInput = {
              ...node,
            };

            output.createPermissionHasUserBulk = [];
            output.deletePermissionHasUserBulk = [];
            output.createGroupHasUserBulk = [];
            output.deleteGroupHasUserBulk = [];

            (query.id?.[0].values || []).forEach((userId: string): void => {
              // we only want to create license if we are updating one user
              if (idx === 0 && (selected || [])?.length === 1) {
                output.createLicenseBulk = createLicenses.map((node: License): License => ({ ...node, userId }));
                output.updateLicenseBulk = generateUpdateBulkPayload(updateLicenses);
                output.deleteLicenseBulk = deleteLicenses;
              }

              output.createPermissionHasUserBulk = [
                ...output.createPermissionHasUserBulk,
                ...createPermissionHasUser.map((el: any): CreatePermissionHasUserInput => ({ permissionId: el, userId })),
              ];
              output.deletePermissionHasUserBulk = [
                ...output.deletePermissionHasUserBulk,
                ...deletePermissionHasUser.map((el: AirportHasUser): DeletePermissionHasUserInput => ({ permissionId: el.id, userId })),
              ];
              output.createGroupHasUserBulk = [
                ...output.createGroupHasUserBulk,
                ...createGroupHasUser.map((el: any): CreateGroupHasUserInput => ({ groupId: el, userId })),
              ];
              output.deleteGroupHasUserBulk = [
                ...output.deleteGroupHasUserBulk,
                ...deleteGroupHasUser.map((el: Group): DeleteGroupHasUserInput => ({ groupId: el.id, userId })),
              ];
            });

            return {
              ...output,
              updateUserValues: value,
            };
          }
        );

        const noChangesMade = Object.entries(updateUserProfileInputs?.[0] || {})
          .filter(([key]): boolean => key.toLowerCase() !== 'query')
          .every(([, value]): boolean => (Array.isArray(value) ? !value?.length : !Object.keys(value)?.length));

        if (noChangesMade) return createNotification('No changes made', Toast.Type.WARNING, 'Update User Profile');

        // exec update
        return await updateUserProfileBulk(updateUserProfileInputs);
      } catch (err) {
        log.error(err);
      }
    };

    const handleReportProblem = async (input: { message: string; images?: string[] }): Promise<any> => {
      if (!input?.message) {
        return createNotification("Can't report problem without a message.", Toast.Type.WARNING, 'Report Problem');
      }
      return await reportProblem(input?.message, input?.images);
    };

    return {
      __name: 'useUsers',
      // Queries
      createUserBulk,
      deleteUserBulk,
      // CRUD Handlers
      handleCreateUserBulk,
      handleDeleteUserBulk,
      handleUpdateUserBulk,
      // Utils
      handleReportProblem,
      validateUser,
      runQueryKeys,
    };
    // End Methods
  });
  return [state, setState];
};

export default useUsers;
export { UserContext, useUsersContext };
