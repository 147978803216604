import { Button } from 'react-bootstrap';
import { ReactNode } from 'react';
import useDoubleKeyDown from '@/hooks/useDoubleKeyDown';
import useLocale from '@/hooks/useLocale';

export type GroupFooterProps = {
  onAdd?: false | (() => void);
  onCancel?: false | (() => void);
  onSave?: false | (() => void);
  locale?: Record<string, string>;
  loading?: boolean;
};
const GroupFooter = ({ onAdd, onCancel, onSave, locale: customLocale, loading }: GroupFooterProps): ReactNode => {
  const locale = useLocale(customLocale);

  const handleSave = (): void => {
    if (!onSave) return;
    (document.activeElement as HTMLElement).blur();
    onSave();
  };

  useDoubleKeyDown('Enter', handleSave);

  return (
    <div className="SettingsPageGroup-Footer">
      {onAdd !== undefined && (
        <Button
          name="SUBMIT_ADD"
          variant="green"
          className="text-white"
          onClick={onAdd ? onAdd : undefined}
          disabled={onAdd === false || loading}
        >
          {locale('ADD NEW')}
        </Button>
      )}
      {onCancel !== undefined && (
        <Button
          name="CANCEL_CHANGES"
          variant="secondary"
          onClick={onCancel ? onCancel : undefined}
          disabled={onCancel === false || loading}
        >
          {locale('CANCEL')}
        </Button>
      )}
      {onSave !== undefined && (
        <Button name="SUBMIT_SAVE" variant="primary" onClick={handleSave} disabled={onSave === false || loading}>
          {!loading && locale('SAVE')}
          {loading && <i className="fa fa-spinner fa-pulse" />}
        </Button>
      )}
    </div>
  );
};

export default GroupFooter;
