import { MONTH_END, MONTH_START } from '@/constants';

import ClientDropdown from '@/components/ClientDropdown';
import EnumDropdown from '@/components/EnumDropdown';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import { InvoiceStatusEnum } from '@/models/gen/graphql';
import { onEnter } from '@/utils';
import useForm from '@/hooks/useForm';

export type InvoiceHistoryFiltersProps = {
  selected: string[];
  onSubmit: (filters: InvoiceHistoryFilters) => void;
  onArchive: () => void;
  onSearch: (search: string) => void;
};
export interface InvoiceHistoryFilters {
  from: typeof MONTH_START;
  to: typeof MONTH_END;
  search: string;
  payerProviderId: string;
  status: string[];
}
const initInvoiceHistoryFilters: InvoiceHistoryFilters = {
  from: MONTH_START,
  to: MONTH_END,
  search: '',
  payerProviderId: null,
  status: [InvoiceStatusEnum.Active],
};

export const InvoiceHistoryFilters = ({ selected = [], onSubmit, onArchive, onSearch }: InvoiceHistoryFiltersProps): JSX.Element => {
  const [filters, , setFilters] = useForm(initInvoiceHistoryFilters);

  const handleSearch = (search = ''): void => {
    setFilters((current: InvoiceHistoryFilters): InvoiceHistoryFilters => ({ ...current, search }));
    onSearch(search);
  };

  return (
    <Filters
      name="invoiceHistoryTable"
      value={filters}
      onSubmit={onSubmit}
      onReset={(): void => {
        setFilters(initInvoiceHistoryFilters);
        handleSearch();
      }}
      submitOnMount
      primary={({ values, onChange }): JSX.Element => {
        const { from, to, payerProviderId, status } = values;
        return (
          <>
            <FormField name="daterange" type="daterange" value={[from, to]} onChange={onChange.dateRange('from', 'to')} condensed />
            <ClientDropdown
              name="payerProviderId"
              onChange={(value) => onChange({ target: { name: 'payerProviderId', value } })}
              value={payerProviderId}
            />
            <EnumDropdown
              name="status"
              value={status}
              onChange={(value) => onChange({ target: { name: 'status', value } })}
              options={{ locale: { 'Select...': 'Status' } }}
              enum={InvoiceStatusEnum}
            />
          </>
        );
      }}
      controls={({ values, onChange }): JSX.Element => {
        const { search } = values;
        return (
          <>
            <FormField
              prepend={<i className="sv sv-magnifier fs-4" />}
              id={'filters-searchbar'}
              name="search"
              value={search || ''}
              onChange={onChange}
              onBlur={() => handleSearch(search)}
              onKeyDown={onEnter(() => handleSearch(search))}
              placeholder="Search"
              condensed
              inline
            />
          </>
        );
      }}
      alternate={(): JSX.Element => {
        return (
          <>
            <FormButton
              to="/invoices/0"
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="create"
              variant="outline-gray"
            >
              Create Invoice
            </FormButton>
            <FormButton
              icon={<i className="sv sv-box {font-size:1.5rem;}" />}
              feedback={`Archive Invoice${selected?.length > 1 ? 's' : ''}`}
              variant="outline-gray"
              onClick={onArchive}
              disabled={selected?.length === 0}
              name="ARCHIVE_INVOICES"
            />
          </>
        );
      }}
    />
  );
};
