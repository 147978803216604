import './styles.scss';

import { Button, ButtonProps, Card, Col, Container, Form, Row } from 'react-bootstrap';
import React, { HTMLAttributes, useMemo, useRef } from 'react';

import FileType from '../../models/FileType';
import FormButton from '@/components/FormButton';
import Logger from '../../utils/logs';
import { TextAreaField } from '@/components/TextArea';
import useForm from '../../hooks/useForm';
import useUsers from '../../hooks/useUsers';

const log = Logger.of('ReportProblem');

type ReportProblemFormState = {
  images: FileType.Image[];
  message: string;
};

const MAX_FILE_SIZE_MB = 0.5;
const TOTAL_FILE_SIZE_MB = MAX_FILE_SIZE_MB;
const initReportProblemFormState: ReportProblemFormState = { images: [], message: '' };

const ReportProblemForm = (): JSX.Element => {
  const [state, onChange, setState] = useForm(initReportProblemFormState);
  const { images, message } = state;

  const totalMegabytes = useMemo((): number => {
    let count = 0;
    images.forEach((img: FileType.Image): number => (count += img.size));
    return count;
  }, [images]);

  const [, { handleReportProblem }] = useUsers();

  const onRemoveImage =
    (i: number): any =>
    (): void => {
      setState((current: ReportProblemFormState): ReportProblemFormState => {
        const temp = current?.images || [];
        temp.splice(i, 1);
        return {
          ...current,
          images: temp,
        };
      });
    };

  const handleSubmit = async (): Promise<void> => {
    if (totalMegabytes > TOTAL_FILE_SIZE_MB) {
      log.warn(`FileType.Images uploaded exceeds the maximum limit (${totalMegabytes} / ${TOTAL_FILE_SIZE_MB} MB).`).notify({
        message: `Oops! It seems like the total size of the images you uploaded exceeds the maximum limit (${totalMegabytes} / ${TOTAL_FILE_SIZE_MB} MB).
      Please reduce the file sizes or select fewer images to continue.`,
      });
      return;
    }
    await handleReportProblem({ message, images: images.map((img: FileType.Image): string | ArrayBuffer => img.src) });
    setState(initReportProblemFormState);
  };

  const handleUploadFile = (event: any): void => {
    const { files } = event.target;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const megabytes = file.size / (1024 * 1024);

      // Check the file size before proceeding
      if (megabytes > MAX_FILE_SIZE_MB) {
        log.warn(`File ${file.name} exceeds the maximum file size (${megabytes.toFixed(2)} / ${MAX_FILE_SIZE_MB} MB).`).notify({
          message: `File ${file.name} exceeds the maximum file size (${megabytes.toFixed(2)} / ${MAX_FILE_SIZE_MB} MB).`,
        });
        continue; // Skip to the next file
      }

      const reader = new FileReader();
      reader.onloadend = (): void => {
        setState(
          (current: ReportProblemFormState): ReportProblemFormState => ({
            ...current,
            images: [...(current?.images || []), { src: reader.result, size: megabytes, name: file.name }],
          })
        );
      };
      if (file) reader.readAsDataURL(file);
    }
  };

  return (
    <div className="p-4">
      <Card className="ReportProblem primary">
        <Container>
          <Card.Header>
            <h3>REPORT A PROBLEM</h3>
          </Card.Header>
          <Card.Body className="d-flex flex-column gap-2">
            <Row>
              <Col>
                <p>Provide a very detailed explanation of the issue and what you were doing when it happened.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaField
                  label="Message"
                  value={message}
                  onChange={(value: string): void => onChange({ target: { name: 'message', value } })}
                  maxLength={200}
                  feedback={`${message.length} / 200`}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <UploadImageButton onChange={handleUploadFile}>Upload Images</UploadImageButton>
              </Col>
              <Col className="d-flex flex-grow-1 justify-content-end gap-2">
                <Button onClick={(): void => window.history.back()} variant="secondary" name="CANCEL">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} variant="primary" name="SUBMIT">
                  Save
                </Button>
              </Col>
            </Row>
            {!!images.length && (
              <>
                <Row>
                  <Col>
                    <Form.Label>Images:</Form.Label>
                  </Col>
                </Row>
                <Row>
                  {images.map(
                    (img: FileType.Image, s: number): JSX.Element => (
                      <ImageDisplay key={s} src={img?.src} onDelete={onRemoveImage(s)} />
                    )
                  )}
                </Row>
              </>
            )}
          </Card.Body>
        </Container>
      </Card>
    </div>
  );
};

type UploadImageButtonProps = {
  onChange: HTMLAttributes<HTMLInputElement>['onChange'];
  children?: React.ReactNode;
};

const UploadImageButton = ({ onChange, children = 'Upload' }: UploadImageButtonProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <FormButton
        name="UPLOAD_IMAGE"
        variant="outline-primary"
        icon={<i className="sv sv-upload2 me-2 {font-size:1.5rem;}" />}
        onClick={(): void => ref.current.click()}
      >
        {children}
      </FormButton>
      <input name="images" type="file" multiple className="d-none" onChange={onChange} ref={ref} />
    </>
  );
};

type ImageDisplayProps = {
  src: ArrayBuffer | string;
  onDelete: ButtonProps['onClick'];
};
const ImageDisplay = ({ src, onDelete }: ImageDisplayProps): JSX.Element => (
  <Col xs="auto">
    <div
      className="ReportProblem-Image rounded border border-gray text-end"
      style={{
        backgroundImage: `url(${src})`,
      }}
    >
      <Button onClick={onDelete} variant="light" className="border-primary rounded-circle">
        <i className="sv sv-cross" />
      </Button>
    </div>
  </Col>
);

export default ReportProblemForm;
