import './styles.scss';

import { ReactNode, forwardRef } from 'react';
import { Switch, SwitchProps } from 'antd';

import { getClasses } from '@/utils';
import useUuid from '@/hooks/useUuid';

export type SwitchInputProps = { label?: ReactNode; className?: string } & SwitchProps;

const SwitchInput = ({ label, className, ...switchProps }: SwitchInputProps, ref) => {
  const uuid = useUuid();

  return (
    <div className={getClasses('SwitchInput', className)}>
      <label className="SwitchInput-Label" htmlFor={switchProps?.id || uuid}>
        <small>{label}</small>
      </label>
      <div className="SwitchInput-Input">
        <Switch {...switchProps} id={switchProps?.id || uuid} ref={ref} />
      </div>
    </div>
  );
};

export default forwardRef(SwitchInput);
