import EnumDropdown, { EnumDropdownMulti, EnumDropdownMultiProps, EnumDropdownProps } from '@/components/EnumDropdown';
import Field, { FieldProps } from '@/components/Field';

import { ReactNode } from 'react';
import { TripTypeEnum } from '@/models/gen/graphql';

type TripTypeDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  name?: string;
};
type TriptTypeDropdownMultiProps = Omit<EnumDropdownMultiProps, 'enum' | 'name'> & {
  name?: string;
};
const defaultLocale = { 'Select...': 'Type' };
const Enum = Object.values(TripTypeEnum).reduce((acc, val) => {
  // ensures keys and values are capitalized
  acc[val.toUpperCase()] = val.toUpperCase();
  return acc;
}, {});

const TripTypeDropdown = ({ name = 'type', ...props }: TripTypeDropdownProps): ReactNode => {
  return <EnumDropdown {...props} enum={Enum} name={name} options={{ locale: props?.options?.locale || defaultLocale }} />;
};

export const TripTypeDropdownMulti = ({ name = 'type', ...props }: TriptTypeDropdownMultiProps): ReactNode => {
  return <EnumDropdownMulti {...props} enum={Enum} name={name} options={{ locale: props?.options?.locale || defaultLocale }} />;
};

export const TripTypeDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & TripTypeDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <TripTypeDropdown {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default TripTypeDropdown;
