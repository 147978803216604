import { createNotification, handleError } from '@/utils/custom';

import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { UndeleteTripBulkDocument } from '@/api/queries';
import { UndeleteTripBulkInput } from '@/models/gen/graphql';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type UndeleteTripBulkGraphApiResponse = GraphApiResponse<typeof UndeleteTripBulkDocument>;

const title = 'Undelete Trips';

const undeleteTripBulkResponseSelector = (res: UndeleteTripBulkGraphApiResponse): number =>
  !res.errors ? res?.undeleteTripBulk?.undeleted || 0 : undefined;

const [runUndeleteTripBulk] = graphApi(UndeleteTripBulkDocument, {
  onError: (res: UndeleteTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const undeleteTripBulk = async (tripIds: string[]): Promise<number> => {
  const input: UndeleteTripBulkInput = { tripIds };

  const res = await runUndeleteTripBulk({ input });
  const selected = undeleteTripBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useUndeleteTripBulk = createGraphApiHook(undeleteTripBulk);

export default undeleteTripBulk;
