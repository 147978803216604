import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import { Trip } from '@/models/gen/graphql';
import { useEditFcrModal } from '@/pages/Trips/EditFcr';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

const FcrCell = (): JSX.Element => {
  const { data: { id, servicerIataAirlineCode, flightNumber, scheduled, fcrCount, fcrs = [] } = {} } = useSimpleTableRowContext<Trip>();
  const count = fcrCount ?? (fcrs?.length || 0);
  const showEditFcrModal = useEditFcrModal(({ setState }) => setState);
  const { onRefetch } = useTripsTableContext();

  const onClick = () =>
    showEditFcrModal({
      show: true,
      tripId: id,
      servicerIataAirlineCode: servicerIataAirlineCode,
      flightNumber: `${flightNumber}`,
      scheduled: scheduled,
      onSubmit: onRefetch,
    });

  return (
    <SimpleTableCell name="FCR" className="w-sm-fixed d-print-none">
      <Tooltip content="Complaints">
        <Button variant="icon" onClick={onClick}>
          <i className={`sv sv-fcr fs-5 ${count ? 'text-purple' : 'text-primary'}`} />
        </Button>
      </Tooltip>
    </SimpleTableCell>
  );
};

export default FcrCell;
