import { Badge, Button } from 'react-bootstrap';
import { FlagWithTrip, Trip } from '@/models/gen/graphql';

import { FLAG_DISPLAY_MAX_COUNT } from '@/constants';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import { getClasses } from '@/utils';
import { pixelsToRem } from '@/utils/custom';
import { useCallback } from 'react';
import { useEditCommunicationModal } from '@/pages/Trips/EditCommunication';
import { useEditFlagModal } from '@/pages/Trips/EditFlag';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

const BADGE_REM_SIZE = pixelsToRem(4 + FLAG_DISPLAY_MAX_COUNT);

const FlagAndCommunicationCell = (): JSX.Element => {
  return (
    <SimpleTableCell name="FLAG_COMM" className="w-100 d-flex justify-content-between gap-2 w-sm-fixed d-print-none">
      <FlagsButton />
      <CommunicationButton />
    </SimpleTableCell>
  );
};

const FlagsButton = (): JSX.Element => {
  const { onRefetch } = useTripsTableContext();
  const { data: { id: tripId, servicerIataAirlineCode, flightNumber, scheduled, flags = [] } = {} } = useSimpleTableRowContext<Trip>();
  const showEditFlagModal = useEditFlagModal(({ setState }) => setState);
  const onClickFlag = useCallback(
    (): void =>
      showEditFlagModal({
        show: true,
        tripId,
        servicerIataAirlineCode,
        flightNumber: `${flightNumber}`,
        scheduled,
        onSubmit: onRefetch,
      }),
    [flightNumber, onRefetch, scheduled, servicerIataAirlineCode, showEditFlagModal, tripId]
  );

  return (
    <Tooltip content="Flags">
      <Button
        variant="icon"
        name="FLAGS_BUTTON"
        className={getClasses('Flags', flags?.length > 1 ? `position-relative {left:-${flags.length === 2 ? 0.25 : 0.5}rem}` : undefined)}
        onClick={onClickFlag}
      >
        {!flags?.length && <i className="sv sv-flag-empty" />}
        {!!flags?.length &&
          flags
            .slice(0, FLAG_DISPLAY_MAX_COUNT)
            .map(
              (flag: FlagWithTrip, f: number): React.JSX.Element => (
                <i
                  className={`sv sv-flag-filled {left:${pixelsToRem(f * 5)}rem;z-index:${Math.round(FLAG_DISPLAY_MAX_COUNT / (f + 1))};}`}
                  style={{ color: flag?.color }}
                  key={f}
                />
              )
            )}
        {flags?.length > FLAG_DISPLAY_MAX_COUNT && (
          <Badge className={`{z-index:10;left:${BADGE_REM_SIZE}rem;}`}>+{flags?.length - FLAG_DISPLAY_MAX_COUNT}</Badge>
        )}
      </Button>
    </Tooltip>
  );
};

const CommunicationButton = (): JSX.Element => {
  const { onRefetch } = useTripsTableContext();
  const { data: { id, servicerIataAirlineCode, flightNumber, scheduled, offset, communicationCount = 0, communications = [] } = {} } =
    useSimpleTableRowContext<Trip>();
  const hasCommunication = !!(communicationCount ?? communications?.length);

  const showEditCommunicationModal = useEditCommunicationModal(({ setState }) => setState);
  const onClickCommunication = useCallback(
    () =>
      showEditCommunicationModal({
        show: true,
        tripId: id,
        offset,
        servicerIataAirlineCode,
        flightNumber: `${flightNumber}`,
        scheduled,
        onSubmit: onRefetch,
      }),
    [flightNumber, id, offset, onRefetch, scheduled, servicerIataAirlineCode, showEditCommunicationModal]
  );
  return (
    <Tooltip content="Communications">
      <Button variant="icon" name="COMMUNICATIONS_BUTTON" onClick={onClickCommunication} className="{padding-top:0.5rem;}">
        <i className={`sv sv-message2 fs-5 ${hasCommunication ? 'text-info' : ''}`} />
      </Button>
    </Tooltip>
  );
};

export default FlagAndCommunicationCell;
