import {
  DATETIME_FE_FORMAT,
  DATETIME_FE_FORMAT_FULL,
  DATETIME_FE_FORMAT_SHORT,
  DATETIME_FORMAT,
  DATETIME_INPUT_FORMAT,
  DATETIME_RFC_FORMAT,
  DATE_FE_FORMAT,
  DATE_FORMAT,
  DATE_INPUT_FORMAT,
  EOD_MINUTES,
  TIME_FORMAT,
  TIME_FORMAT_FULL,
} from '../constants';

import Logger from './logs';
import { Validation } from './validations';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

const log = Logger.of('Datetime');

export class Datetime {
  private _asDayjs: dayjs.Dayjs = dayjs();
  private _asTimestamp: number = this._asDayjs.valueOf();
  private _asDate: Date = new Date(this._asTimestamp);
  private _error: boolean = false;
  private _input: any = undefined; // this is for the sole purpose of error logging
  private _hasError(): boolean {
    // Commenting this out because it floods the console too much.
    // if (this._error) log.warn('Invalid date.', this._input);
    return this._error;
  }
  constructor(str: string | number | Date | dayjs.Dayjs = dayjs().valueOf()) {
    Object.keys(this)
      .filter((key: string): boolean => key.startsWith('_'))
      .forEach((key: string): any => Object.defineProperty(this, key, { enumerable: false }));
    this.set(str);
  }
  format(template: string = ''): string {
    if (this._hasError()) return '';
    return this.asDayjs().format(template);
  }
  set(input: string | number | Date | dayjs.Dayjs): Datetime {
    let str = null;
    switch (typeof input) {
      case 'string':
        str = input.replace(/-/g, '/');
        break;
      default:
        str = input === undefined ? dayjs().valueOf() : input;
        break;
    }
    this._input = str; // track input value to know what triggered hasError
    this._asDayjs = dayjs(str);
    this._asTimestamp = this._asDayjs.valueOf();
    this._asDate = new Date(this._asTimestamp);
    if (isNaN(this._asTimestamp)) this._error = true;
    return this;
  }
  // takes a date string and converts it to UTC
  setAsUTC(input: string): Datetime {
    this.set(dayjs(appendUtcTimezoneToDateTime(input)).utc(false));
    return this;
  }
  clone(): Datetime {
    return new Datetime(this._asTimestamp);
  }
  add(amount: number, unit: dayjs.ManipulateType): Datetime {
    return this.set(this.asDayjs().add(amount, unit).format(DATETIME_RFC_FORMAT));
  }
  subtract(amount: number, unit: dayjs.ManipulateType): Datetime {
    return this.set(this.asDayjs().subtract(amount, unit).format(DATETIME_RFC_FORMAT));
  }
  applyOffset(offset: string): Datetime {
    const minutes = offsetToMinutes(offset);
    if (minutes === undefined) {
      console.error('Invalid offset format');
      return this;
    }
    return this.add(minutes, 'minutes');
  }
  diff(input: string | number | Date | dayjs.Dayjs = dayjs().valueOf(), unit: dayjs.ManipulateType): number {
    return this.asDayjs().diff(input, unit);
  }
  diffUtc(input: string | number | Date | dayjs.Dayjs = dayjs().valueOf(), unit: dayjs.ManipulateType): number {
    if (typeof input === 'string') input = appendUtcTimezoneToDateTime(input);
    const inputUtc = dayjs(input).utc(false);
    return this.asUtc().diff(inputUtc, unit);
  }
  isSameUtc(input: string | number | Date | dayjs.Dayjs = dayjs().valueOf(), unit: dayjs.ManipulateType): boolean {
    if (typeof input === 'string') input = appendUtcTimezoneToDateTime(input);
    const inputUtc = dayjs(input).utc(false);
    return this.asUtc().isSame(inputUtc, unit);
  }
  startOf(unit: dayjs.ManipulateType): Datetime {
    return this.set(this.asDayjs().startOf(unit).format(DATETIME_RFC_FORMAT));
  }
  endOf(unit: dayjs.ManipulateType): Datetime {
    return this.set(this.asDayjs().endOf(unit).format(DATETIME_RFC_FORMAT));
  }
  setTime(time: string): Datetime {
    if (!time) log.warn('setTime: time is undefined.');
    return this.set(`${this.dateInput} ${time}`);
  }
  setDate(date: string): Datetime {
    if (!date) log.warn('setDate: date is undefined.');
    date = dayjs(date.replace(/-/g, '/')).format(DATE_INPUT_FORMAT);
    return this.set(`${date} ${this.fullTime}`);
  }
  toString(): string {
    if (this._hasError()) return '';
    return this.format(DATETIME_RFC_FORMAT);
  }
  asTimestamp(): number {
    return this._asTimestamp;
  }
  asDate(): Date {
    return this._asDate;
  }
  asDayjs(): dayjs.Dayjs {
    return this._asDayjs;
  }
  asUtc(): dayjs.Dayjs {
    return dayjs(appendUtcTimezoneToDateTime(this.toString())).utc(false);
  }
  random(start: string | number | Date, end: string | number | Date): Datetime {
    start = this.set(start).asTimestamp();
    end = this.set(end).asTimestamp();
    return this.set(start + Math.random() * (end - start));
  }
  // converts the current UTC datetime to the local timezone
  toLocaleDatetime(): Datetime {
    this.set(dayjs(this._input).utc(false).toLocaleString());
    return this;
  }
  get day(): number {
    if (this._hasError()) return null;
    return this.asDayjs().day();
  }
  get date(): string {
    if (this._hasError()) return '';
    return this.format(DATE_FORMAT);
  }
  get dateInput(): string {
    if (this._hasError()) return '';
    return this.format(DATE_INPUT_FORMAT);
  }
  get frontendDate(): string {
    if (this._hasError()) return '';
    return this.format(DATE_FE_FORMAT);
  }
  get frontendDatetime(): string {
    if (this._hasError()) return '';
    return this.format(DATETIME_FE_FORMAT);
  }
  get frontendDatetimeShort(): string {
    if (this._hasError()) return '';
    return this.format(DATETIME_FE_FORMAT_SHORT);
  }
  get time(): string {
    if (this._hasError()) return '';
    return this.format(TIME_FORMAT);
  }
  get fullTime(): string {
    if (this._hasError()) return '';
    return this.format(TIME_FORMAT_FULL);
  }
  get datetime(): string {
    if (this._hasError()) return '';
    return this.format(DATETIME_FORMAT);
  }
  get datetimeInput(): string {
    if (this._hasError()) return '';
    return this.format(DATETIME_INPUT_FORMAT);
  }
  get fullFrontendDatetime(): string {
    if (this._hasError()) return '';
    return this.format(DATETIME_FE_FORMAT_FULL);
  }
}
/**
 * @deprecated Use new Datetime().random(start, end).
 */
export const randomDate = (start: string | number | Date, end: string | number | Date): Datetime => {
  start = new Datetime(start).asTimestamp();
  end = new Datetime(end).asTimestamp();
  return new Datetime(start + Math.random() * (end - start));
};
export const getDifferenceBetweenTwoDates = (a: string | number, b: string | number): number => {
  if (Validation.isNil(a) || Validation.isNil(b) || !Validation.isDate(a) || !Validation.isDate(b)) return null;
  const x = dayjs(a);
  const y = dayjs(b);
  return x.diff(y);
};
export const appendUtcTimezoneToDateTime = (input: string): string => {
  // checks if Z suffix is not present
  if (input && !input.match(/Z$/)) {
    input = input + 'Z'; // add Z suffix to indicate UTC
  }
  return input;
};

export const isDateRangeWithinThreshold = (
  startDate: string,
  endDate: string,
  threshold: number,
  unit: dayjs.ManipulateType = 'day'
): boolean => {
  if (!Validation.isDate(startDate) || !Validation.isDate(endDate)) return;
  const dayDiff = new Datetime(endDate).diff(startDate, unit);
  return dayDiff <= threshold;
};

export const findOverlappingDateRangeIndexes = (dateRanges: [string | null, string | null][] = []): number[] => {
  const overlappingIndexes: number[] = [];

  // Helper function to add or subtract days
  const updateDays = (dateStr: string, days: number): string => {
    return new Datetime(dateStr).add(days, 'day').toString();
  };

  // Basically builds an array of date ranges based on the provided
  // date ranges and update the dates based on null values.
  for (let i = 0; i < dateRanges.length; i++) {
    let [currentStart, currentEnd] = dateRanges[i];

    // Adjust start date if it's null by setting it's value to the previous date range's end date + 1
    if (!currentStart && i > 0) {
      currentStart = updateDays(dateRanges[i - 1][1]!, 1);
    }

    // Adjust end date if it's null by setting it's value to the previous date range's start date - 1
    if (!currentEnd) {
      if (i < dateRanges.length - 1) {
        currentEnd = updateDays(dateRanges[i + 1][0]!, -1);
      } else {
        currentEnd = new Datetime().endOf('day').toString(); // Use today's EOD if it's the last rate
      }
    }

    // Replace the current date range with the adjusted values
    dateRanges[i] = [currentStart, currentEnd];
  }

  // Now find overlaps using the adjusted date ranges
  for (let i = 0; i < dateRanges.length; i++) {
    for (let j = i + 1; j < dateRanges.length; j++) {
      if (isDateRangeOverlap(dateRanges[i], dateRanges[j])) {
        overlappingIndexes.push(i, j);
      }
    }
  }
  return Array.from(new Set(overlappingIndexes));
};
// Helper function to add/subtract days
export const isDateRangeOverlap = ([from1, to1]: [string, string], [from2, to2]: [string, string]): boolean => {
  const start1 = new Datetime(from1).asTimestamp();
  const end1 = new Datetime(to1).asTimestamp();
  const start2 = new Datetime(from2).asTimestamp();
  const end2 = new Datetime(to2).asTimestamp();
  return start1 < end2 && end1 > start2;
};

export const minutesToTime = (minutes: string | number = 0): string => {
  minutes = parseInt(`${minutes}`);
  if (minutes < 0 || minutes > EOD_MINUTES) return;
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};
export const timeToMinutes = (time: string = '00:00'): number => {
  const parts = time.split(':').map(Number);
  if (parts.length < 2 || parts.length > 3) return;
  const [hours, minutes] = parts;
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59 || isNaN(hours) || isNaN(minutes)) return;
  return hours * 60 + minutes;
};

export const offsetToMinutes = (offset: string): number | undefined => {
  if (!offset) return;
  // Check if the offset matches the expected format
  const match = offset.match(/^([+-]?)(\d{2}):(\d{2})$/);

  if (!match?.length) return;

  // Extract the sign, hours, and minutes from the offset string
  const sign = match[1] === '-' ? -1 : 1; // Default to positive if no sign is provided
  const hours = parseInt(match[2]);
  const minutes = parseInt(match[3]);

  // Calculate the total number of minutes
  return sign * (hours * 60 + minutes); // total minutes
};

export const isDateBetween = (currentDate: string, start: string, end: string): boolean => {
  try {
    const currentAsDate = new Datetime(currentDate);
    const startAsDate = new Datetime(start);
    const endAsDate = new Datetime(end);
    return currentAsDate.asDayjs().isBetween(startAsDate.asDayjs(), endAsDate.asDayjs(), 'day', '[]');
  } catch (err) {
    console.error(err);
  }
};
