// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RouteContent {
  position: relative;
  flex-grow: 1;
}
.RouteContent #Sidebar-Left,
.RouteContent #Sidebar-Right {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4rem;
  pointer-events: none;
}
.RouteContent #Sidebar-Left .btn,
.RouteContent #Sidebar-Right .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  pointer-events: all;
}
.RouteContent #Sidebar-Left {
  left: 0;
}
.RouteContent #Sidebar-Right {
  right: 0;
}

.NotAllowed {
  position: relative;
  flex-grow: 1;
  padding: 1rem;
  font-size: clamp(1rem, 3rem, 20px);
  font-weight: bold;
  color: var(--bs-primary);
}
.NotAllowed .home-navigation a {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
}
.NotAllowed .not-allowed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  height: 100%;
}
.NotAllowed .not-allowed-content {
  background-image: url("/public/assets/images/Skyhop_Global_Logo_horizontalAsset 1@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  height: 75%;
  display: flex;
  width: clamp(200px, 100%, 900px);
}`, "",{"version":3,"sources":["webpack://./src/components/ProtectedRoute/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;AAAE;;EAEE,kBAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;EACA,oBAAA;AAEJ;AADI;;EACE,qCAAA;EACA,wCAAA;EACA,mBAAA;AAIN;AADE;EACE,OAAA;AAGJ;AADE;EACE,QAAA;AAGJ;;AACA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kCAAA;EACA,iBAAA;EACA,wBAAA;AAEF;AACI;EACE,aAAA;EACA,qBAAA;EACA,SAAA;EACA,cAAA;EACA,qBAAA;EACA,yBAAA;AACN;AAGE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;AADJ;AAIE;EACE,0FAAA;EACA,wBAAA;EACA,4BAAA;EACA,+BAAA;EACA,WAAA;EACA,aAAA;EACA,gCAAA;AAFJ","sourcesContent":[".RouteContent {\n  position: relative;\n  flex-grow: 1;\n}\n.RouteContent #Sidebar-Left,\n.RouteContent #Sidebar-Right {\n  position: absolute;\n  top: 0;\n  height: 100%;\n  width: 4rem;\n  pointer-events: none;\n}\n.RouteContent #Sidebar-Left .btn,\n.RouteContent #Sidebar-Right .btn {\n  border-top-right-radius: 0 !important;\n  border-bottom-right-radius: 0 !important;\n  pointer-events: all;\n}\n.RouteContent #Sidebar-Left {\n  left: 0;\n}\n.RouteContent #Sidebar-Right {\n  right: 0;\n}\n\n.NotAllowed {\n  position: relative;\n  flex-grow: 1;\n  padding: 1rem;\n  font-size: clamp(1rem, 3rem, 20px);\n  font-weight: bold;\n  color: var(--bs-primary);\n}\n.NotAllowed .home-navigation a {\n  display: flex;\n  align-items: baseline;\n  gap: 1rem;\n  color: inherit;\n  text-decoration: none;\n  text-transform: uppercase;\n}\n.NotAllowed .not-allowed-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  gap: 1rem;\n  height: 100%;\n}\n.NotAllowed .not-allowed-content {\n  background-image: url(\"/public/assets/images/Skyhop_Global_Logo_horizontalAsset 1@2x.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center top;\n  height: 75%;\n  display: flex;\n  width: clamp(200px, 100%, 900px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
