import { createNotification, handleError } from '@/utils/custom';

import { DeleteTripHardBulkDocument } from '@/api/queries';
import { DeleteTripHardBulkInput } from '@/models/gen/graphql';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type DeleteTripHardBulkGraphApiResponse = GraphApiResponse<typeof DeleteTripHardBulkDocument>;

const title = 'Permanently Delete Trips';

const deleteTripHardBulkResponseSelector = (res: DeleteTripHardBulkGraphApiResponse): number =>
  !res.errors ? res?.deleteTripHardBulk?.deleted || 0 : undefined;

const [runDeleteTripHardBulk] = graphApi(DeleteTripHardBulkDocument, {
  onError: (res: DeleteTripHardBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const deleteTripHardBulk = async (tripIds: string[]): Promise<number> => {
  const input: DeleteTripHardBulkInput = { tripIds };

  const res = await runDeleteTripHardBulk({ input });
  const selected = deleteTripHardBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useDeleteTripHardBulk = createGraphApiHook(deleteTripHardBulk);

export default deleteTripHardBulk;
