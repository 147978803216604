import { ConnectionDetails, QueryInputType, queryInput } from '@/utils/custom';
import { RateGroup, SortDirectionEnum, Trip, TripStatusEnum, TripTableFormatEnum, TripTableSearch } from '@/models/gen/graphql';
import { SEARCH_TRIPS_TABLE_PAGE_SIZE, useSearchTripsAndPriorityTripsTable } from '@/api/services/trips/searchTrips';
import TripFilters, { TripFiltersRefMethods, TripsFiltersState, initTripsFiltersState } from '@/features/Trips/components/TripFilters';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useTripTableState, { DEFAULT_TRIP_SORTING, TripSortColumnEnum, getSelectedTrips } from '@/features/Trips/components/TripsTable/hook';

import ChatWidget from '@/components/ChatWidget';
import DeleteTripsModal from '@/pages/Trips/components/DeleteTripsModal';
import EditRatesForm from '@/components/EditRatesForm';
import HasPermission from '@/components/HasPermission';
import HtmlTripsTable from '@/features/Trips/components/TripsTable';
import PageInfo from '@/components/PageInfo';
import TripSettingsModal from '@/features/Trips/components/TripSettingsModal';
import TripsTableShortcuts from '@/features/Trips/components/TripsTable/TripsTableShortcuts';
import { Validation } from '@/utils/validations';
import deleteTripBulk from '@/api/services/trips/deleteTripBulk';
import deleteTripHardBulk from '@/api/services/trips/deleteTripHardBulk';
import equal from 'fast-deep-equal/es6/react';
import undeleteTripBulk from '@/api/services/trips/undeleteTripBulk';
import { useEditCombineModal } from '@/pages/Trips/EditCombine';
import { useEditCommunicationModal } from '@/pages/Trips/EditCommunication';
import { useEditCompletionModal } from '@/pages/Trips/EditCompletion';
import { useEditFcrModal } from '@/pages/Trips/EditFcr';
import { useEditFlagModal } from '@/pages/Trips/EditFlag';
import useInterval from '@/hooks/useInterval';
import { useRatesReportModal } from '@/components/RateReportModal';
import { useTripModalState } from '@/features/Trips/components/TripModal';
import useTripSettings from '@/features/Trips/components/TripSettingsModal/hook';
import { zeroPadFlightNumber } from '@/utils/numbers';

const HtmlTripPage = (): React.JSX.Element => {
  // queries
  const [
    { data: { rows = [], priorityRows = [], totalCount = 0, hasNextPage = false, endCursor = '0' } = {}, loading },
    { refetch, fetchMore, setData },
  ] = useSearchTripsAndPriorityTripsTable();

  // state
  const setState = useTripTableState(({ setState }) => setState);
  const onSetTrips = useTripTableState(({ state }) => state.onSetTrips);
  const tripState = useTripTableState(({ state }) => state.trips);
  const sorting = useTripTableState(({ state }) => state.sorting);
  const onDeleteRow = useTripTableState(({ state }) => state.onDeleteRow);
  const onUndeleteRow = useTripTableState(({ state }) => state.onUndeleteRow);
  const tripsPagePollInterval = useTripSettings(({ state }) => state.tripsPagePollInterval);

  const setColumns = useTripSettings(({ state }) => state.setColumns);

  const [search, setSearch] = useState<string>(initTripsFiltersState.search);
  const [lastFormat, setLastFormat] = useState<TripTableFormatEnum>(initTripsFiltersState.format);
  const [showDeleteTripsModal, setShowDeleteTripsModal] = useState<boolean>(false);
  const [showRatesModal, setShowRatesModal] = useState<boolean>(false);
  //ref
  const lastFilters = useRef<TripsFiltersState>(null);
  const lastSorting = useRef<Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>>(null);
  const tripFiltersRef = useRef<TripFiltersRefMethods>(null);

  const handleRefetch = useCallback(async (): Promise<void> => {
    lastTrips.current = [];
    refetch();
  }, [refetch]);
  const onFilterSubmit = useCallback(
    async (filters: TripsFiltersState): Promise<void> => {
      lastTrips.current = [];
      // refetch if filters didn't changed
      if (equal(filters, lastFilters.current) && equal(sorting, lastSorting.current)) return handleRefetch();
      const tripSearch = convertTripsFiltersStateToQuery(filters, sorting);
      // fetch trips table
      refetch({ query: tripSearch, format: filters.format }, { pageSize: SEARCH_TRIPS_TABLE_PAGE_SIZE });
      lastFilters.current = filters;
      lastSorting.current = sorting;
      setLastFormat(filters.format);
      // clear selected state on search
      setState((current) => ({
        ...current,
        selected: new Map(),
      }));
    },
    [refetch, handleRefetch, setState, sorting]
  );
  const onFetchMore = useCallback(async (): Promise<void> => {
    if (loading) return;
    const tripSearch = convertTripsFiltersStateToQuery(lastFilters.current, sorting);
    fetchMore(
      { query: tripSearch, format: lastFilters.current.format },
      {
        page: Math.round((parseInt(endCursor) || 0) / SEARCH_TRIPS_TABLE_PAGE_SIZE),
        merge: true,
      }
    );
  }, [fetchMore, endCursor, sorting, loading]);
  const onReset = async (input: TripsFiltersState): Promise<void> => {
    setState((current) => ({
      ...current,
      sorting: DEFAULT_TRIP_SORTING,
      selected: new Map(), // clear selected state on reset
    }));
    setSearch(input.search);
    onFilterSubmit(input);
  };

  // modals
  const showTripModal = useTripModalState(({ setState }) => setState);
  const showRateReportModal = useRatesReportModal(({ setState }) => setState);

  const onEditTrip = useCallback(
    (data?: Partial<Trip>): void => {
      const selected = !data ? getSelectedTrips() : [data];
      showTripModal({
        show: true,
        tab: 'trip',
        data: data || selected?.[0] || {},
        selected,
        onSubmit: () => {
          // clear selected state on trip modal submit
          setState((current) => ({ ...current, selected: new Map() }));
          handleRefetch();
        },
      });
    },
    [handleRefetch, showTripModal, setState]
  );
  const onEditRateReport = useCallback(
    (selected: string[]): void =>
      showRateReportModal({
        show: true,
        tripIds: selected,
        onSubmit: async () => {
          // clear selected state on trip modal submit
          setState((current) => ({ ...current, selected: new Map() }));
          handleRefetch();
        },
      }),
    [handleRefetch, showRateReportModal, setState]
  );
  const onEditRate = useCallback((): void => setShowRatesModal(true), []);

  const onDeleteTrips = useCallback(
    (tripIds: string[], format: TripTableFormatEnum = lastFormat, isHardDelete: boolean = false): void => {
      // we will only strike though the trip if the format is ALL or DELETED otherwise we will remove the trip from state
      // and clear selected trips
      const softDelete = !isHardDelete && [TripTableFormatEnum.All, TripTableFormatEnum.Deleted].includes(format);
      const idsToUpdate = new Set(tripIds);
      if (!softDelete) {
        // if the format is not current update the query data state and remove the trips
        const deleteTripsFromData = (
          current: ConnectionDetails<Trip> & { priorityRows?: Trip[] }
        ): ConnectionDetails<Trip> & { priorityRows?: Trip[] } => {
          const rows = current.rows || [];
          const priorityRows = current.priorityRows || [];
          let updatedCount = 0;

          // Iterate backwards to avoid array shifting issues during splice
          for (let i = rows.length - 1; i >= 0; i--) {
            if (idsToUpdate.has(rows[i].id)) {
              rows.splice(i, 1); // Remove the item from the array
              updatedCount++;
            }
          }
          // Iterate backwards to avoid array shifting issues during splice
          for (let i = priorityRows.length - 1; i >= 0; i--) {
            if (idsToUpdate.has(priorityRows[i].id)) {
              priorityRows.splice(i, 1); // Remove the item from the array
            }
          }

          current.totalCount = Math.max((current.totalCount || 0) - updatedCount, 0);
          current.endCursor = `${Math.max(parseInt(current.endCursor || '0') - updatedCount, 0)}`;
          return current; // Return the modified object
        };

        setData(deleteTripsFromData);
      }
      // update zustand trips table state
      onDeleteRow(idsToUpdate, softDelete);
    },
    [onDeleteRow, setData, lastFormat]
  );

  const onUndeleteTrips = useCallback(
    (tripIds: string[]): void => {
      const idsToUpdate = new Set(tripIds);

      const undeleteTripsInData = (
        current: ConnectionDetails<Trip> & { priorityRows?: Trip[] }
      ): ConnectionDetails<Trip> & { priorityRows?: Trip[] } => {
        const rows = current.rows || [];
        const priorityRows = current.priorityRows || [];

        // Set deletedAt to null
        for (let i = 0; i < rows.length; i++) {
          if (idsToUpdate.has(rows[i].id) && rows[i].deletedAt) {
            rows[i] = { ...rows[i], deletedAt: null };
          }
        }

        for (let i = 0; i < priorityRows.length; i++) {
          if (idsToUpdate.has(priorityRows[i].id) && priorityRows[i].deletedAt) {
            priorityRows[i] = { ...priorityRows[i], deletedAt: null };
          }
        }

        return current;
      };

      setData(undeleteTripsInData);

      // update zustand trips table state
      onUndeleteRow(idsToUpdate);
    },
    [onUndeleteRow, setData]
  );

  const handleDelete = useCallback(
    async (values: any, comment: string, selected: Trip[]): Promise<void> => {
      const selectedTripIds = selected.map((trip: Trip): string => trip.id);
      // call delete
      const res = await deleteTripBulk(comment, selectedTripIds);
      if (!res) return; // no deletes
      // update state
      onDeleteTrips(selectedTripIds, lastFormat);
    },
    [onDeleteTrips, lastFormat]
  );

  const handleHardDelete = useCallback(
    async (tripIds: string[]): Promise<void> => {
      setState((current) => ({ ...current, shortcutsDisabled: true }));
      // call hard delete
      const res = await deleteTripHardBulk(tripIds);
      if (!res) return setState((current) => ({ ...current, shortcutsDisabled: false }));
      // update state
      onDeleteTrips(tripIds, lastFormat, true);
    },
    [onDeleteTrips, lastFormat, setState]
  );

  const handleUndelete = useCallback(
    async (tripIds: string[]): Promise<void> => {
      setState((current) => ({ ...current, shortcutsDisabled: true }));
      // Call method to "undelete" trips
      const res = await undeleteTripBulk(tripIds);
      if (!res) return setState((current) => ({ ...current, shortcutsDisabled: false }));

      // Update state
      onUndeleteTrips(tripIds);
    },
    [onUndeleteTrips, setState]
  );

  const handleHideRates = useCallback(
    (fetchTable: boolean = true): void => {
      setShowRatesModal(false);
      if (!fetchTable) return;
      // clear selected state on rate modal submit
      setState((current) => ({ ...current, selected: new Map() }));
      handleRefetch();
    },
    [handleRefetch, setState]
  );

  // FE search
  const onSearch = (val: string): void => setSearch(val);

  const lastTrips = useRef<Trip[]>([]);
  useEffect(() => {
    const result = lastFormat === TripTableFormatEnum.Current ? [...(rows || []), ...(priorityRows || [])] : rows || [];
    if (equal(lastTrips.current, result)) return;
    // update zustand trips state
    lastTrips.current = result;
    onSetTrips(result);
  }, [rows, priorityRows, lastFormat, onSetTrips]);

  // convert rows to table
  const priorityTripIds = useMemo((): string[] => {
    if (lastFormat !== TripTableFormatEnum.Current) return [];
    const [_priorityTrips, priorityTripIds] = getTripsAndIds(priorityRows, tripState, search);
    return priorityTripIds;
  }, [priorityRows, tripState, search, lastFormat]);

  const lastTripIds = useRef<string[]>([]);
  const [trips, tripIds] = useMemo((): [Map<string, Trip>, string[]] => getTripsAndIds(rows, tripState, search), [rows, tripState, search]);

  // update selected state
  useEffect(() => {
    if (equal(lastTripIds.current, tripIds)) return;
    lastTripIds.current = tripIds;
    console.debug('updateSelectedState: running effect...');
    setState((current) => {
      if (!current.selected.size) return current;
      const selected = new Map();
      // loop through selected keys
      for (const tripId of current.selected.keys()) {
        // if row is in new rows, add to selected
        if (!trips.has(tripId)) continue;
        selected.set(tripId, trips.get(tripId));
      }
      console.debug('updateSelectedState: done');
      return { ...current, selected };
    });
  }, [setState, tripIds, trips]);

  const Shortcuts = useMemo(
    (): React.JSX.Element => (
      <TripsTableShortcuts
        deleteAll={(): void => {
          setState((current) => ({ ...current, selected: trips }));
          setShowDeleteTripsModal(true);
        }}
        uaCancels={(): void => {
          tripFiltersRef.current.quickFilter(
            (current: TripsFiltersState): TripsFiltersState => ({ ...current, format: TripTableFormatEnum.United })
          );
        }}
        zeroRates={(): void => {
          tripFiltersRef.current.quickFilter(
            (current: TripsFiltersState): TripsFiltersState => ({ ...current, format: TripTableFormatEnum.ZeroRates })
          );
        }}
        undeleteTrips={(tripIds: string[]): void => {
          handleUndelete(tripIds);
        }}
        hardDeleteTrips={(tripIds: string[]): void => {
          handleHardDelete(tripIds);
        }}
      />
    ),
    [handleUndelete, handleHardDelete, setState, trips]
  );

  const autoRefresh = useCallback(() => {
    // while polling if submit fired we early return
    console.debug('autoRefresh: refreshing trips table...');
    if (
      useTripModalState.getState().state.show ||
      useEditCompletionModal.getState().state.show ||
      useEditFcrModal.getState().state.show ||
      useEditFlagModal.getState().state.show ||
      useEditCommunicationModal.getState().state.show ||
      useEditCombineModal.getState().state.show ||
      useRatesReportModal.getState().state.show ||
      showDeleteTripsModal ||
      showRatesModal
    ) {
      console.debug('autoRefresh: skipping');
      return;
    }
    handleRefetch();
  }, [handleRefetch, showDeleteTripsModal, showRatesModal]);
  // refetch at interval
  useInterval(autoRefresh, tripsPagePollInterval);

  // set to state refetch on mount
  useEffect((): void => {
    setState((current) => ({ ...current, refetch: handleRefetch }));
  }, [handleRefetch, setState]);

  useLayoutEffect((): void => {
    setColumns();
  }, [setColumns]);

  const showPriorityTable = lastFormat === TripTableFormatEnum.Current && !!priorityTripIds.length;

  return (
    <>
      <TripFilters
        onSubmit={onFilterSubmit}
        onReset={onReset}
        onSearch={onSearch}
        sorting={sorting}
        ref={tripFiltersRef}
        onDelete={(): void => setShowDeleteTripsModal(true)}
        onCreate={(): void => onEditTrip({ status: TripStatusEnum.Active, fromManifest: 0 })}
        onEdit={(): void => onEditTrip()}
        loading={loading}
      />
      <PageInfo>
        Total Trips: {tripIds.length} / {totalCount}
      </PageInfo>
      {loading && (
        <PageInfo>
          <i className="fa fa-spinner fa-pulse" />
        </PageInfo>
      )}
      <div className="TripsTable d-flex flex-column align-items-center {gap:2.8rem;padding-top:2rem;padding-bottom:2.8rem} {width:100%}>div">
        {showPriorityTable && (
          <div className="PriorityTrips">
            <HtmlTripsTable
              title="Late Outbound / Flagged Trips"
              rows={priorityTripIds}
              onEditTrip={onEditTrip}
              onEditRateReport={onEditRateReport}
              onEditRate={onEditRate}
              onDeleteTrips={onDeleteTrips}
              onRefetch={handleRefetch}
            />
          </div>
        )}
        <div className="Trips">
          <HtmlTripsTable
            title="All Trips"
            shortcuts={Shortcuts}
            rows={tripIds}
            onEditTrip={onEditTrip}
            onEditRateReport={onEditRateReport}
            onEditRate={onEditRate}
            onDeleteTrips={onDeleteTrips}
            onRefetch={handleRefetch}
            fetchMore={hasNextPage ? onFetchMore : undefined}
          />
        </div>
      </div>
      <DeleteTripsModal
        show={showDeleteTripsModal}
        onHide={(): void => setShowDeleteTripsModal(false)}
        selected={showDeleteTripsModal ? getSelectedTrips().filter((trip: Trip): boolean => !trip.deletedAt) : null}
        formValues={{}}
        onDelete={handleDelete}
      />
      <EditRatesForm
        show={showRatesModal}
        value={(showRatesModal && getRateFormValueFromSelectedTrip({ ...getSelectedTrips()[0] })) || undefined}
        selected={(showRatesModal && [formatRateGroupFromTrip({ ...getSelectedTrips()[0] })]) || undefined}
        onSubmit={handleHideRates}
        onHide={handleHideRates}
        modal
        drawer
        as="drawer"
      />
      <ChatWidget />
      <TripSettingsModal />
    </>
  );
};

const getTripsAndIds = (tripRows: Trip[], tripState: Map<string, Trip>, search: string): [map: Map<string, Trip>, keys: string[]] => {
  if (!tripRows?.length) return [new Map(), []];
  const updatedRows = tripRows.map((row) => tripState.get(row.id) || row);
  // filter rows based on FE search
  const trips: Map<string, Trip> = searchTripsTableColumns(updatedRows, search);
  // format row ids for the table
  return [trips, Array.from(trips.keys())];
};

// takes the rows and search and returns the trips ids that match
const searchTripsTableColumns = (rows: Trip[], search: string): Map<string, Trip> => {
  const output: Map<string, Trip> = new Map();
  const lowerSearchTerm = search.toLowerCase();
  for (let i = 0; i < rows.length; i++) {
    const node = rows[i];
    // if nothing to search then add all
    if (!search) {
      output.set(node.id, node);
      continue;
    }
    // if something to search then add only if search matches
    // limit search to searchable columns
    const searchableColumns = [
      node.type,
      node.scheduled,
      node.trackFlight ?? '',
      node.kind,
      node.airportCode,
      node.servicerIataAirlineCode ?? '',
      zeroPadFlightNumber(node.flightNumber),
      node.loopName ?? '',
      node.pilots ?? 0,
      node.attendants ?? 0,
      node.driver?.employeeId ?? '',
      node.driver?.fullName ?? '',
      node.puLocation?.name ?? '',
      node.doLocation?.name ?? '',
      node.rate?.rate ?? '',
      node.vehicle?.trackingId ?? '',
      node.payerProvider?.displayName ?? '',
    ]
      .join(' ')
      .toLowerCase();

    const match = searchableColumns.includes(lowerSearchTerm);
    if (!match) continue;
    output.set(node.id, node);
  }
  return output;
};
const getRateFormValueFromSelectedTrip = (trip: Trip): Partial<RateGroup> | string => {
  if (trip.rate?.rateGroupId) return trip.rate.rateGroupId;
  return formatRateGroupFromTrip(trip);
};
const formatRateGroupFromTrip = (
  input: Trip
): Pick<
  Partial<RateGroup>,
  'id' | 'thisLocationId' | 'thatLocationId' | 'airportCode' | 'payerProviderId' | 'thisLocation' | 'thatLocation' | 'payerProvider'
> => ({
  id: input.rate?.rateGroupId,
  thatLocationId: input.puLocationId,
  thisLocationId: input.doLocationId,
  airportCode: input.airportCode,
  payerProviderId: input.payerProviderId,
  thatLocation: input.puLocation,
  thisLocation: input.doLocation,
  payerProvider: input.payerProvider,
});

const convertTripsFiltersStateToQuery = (
  filters: TripsFiltersState,
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch[] => {
  // default query
  const query: TripTableSearch = {
    latestScheduled: queryInput.date([filters.from, filters.to]),
  };

  // destructure whatever keys don't map to TripTableSearch
  const { search: _search, format: _format, from: _from, to: _to, ...remainingFilters } = filters || {};
  // loop through rest of the filters
  for (const filterKey in remainingFilters) {
    if (!Validation.isTruthy(remainingFilters[filterKey])) continue;
    const filterValue = remainingFilters[filterKey];

    let key = filterKey as keyof TripTableSearch;
    if (filterKey === 'flightNumber') key = isLoopName(filterValue) ? 'loopName' : 'flightNumber';
    if (filterKey === 'rateAmount' && !HasPermission.check('allowSearchTripsByRate')) continue;

    // if there is a value, add it to the query
    const { value, type } = convertValueToQueryInput(filterKey, filterValue);

    query[key] = queryInput(value, type);
  }

  const result = applySortingToTripQuery(query, sorting);

  return [result];
};

const convertValueToQueryInput = (key: string, value: any): { type: QueryInputType; value: any } => {
  const type = QueryInputType.OR;
  if (key === 'rateAmount' && (parseFloat(`${value}`) === 0 || value === 'NO_RATE')) return { type: QueryInputType.INORNULL, value: [0.0] };
  if (value === null) return { type: QueryInputType.ISNULL, value: [] };
  return { type, value };
};

const applySortingToTripQuery = (
  query: TripTableSearch,
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>
): TripTableSearch => {
  if (!sorting?.length) return query;
  const output = { ...query };

  for (let i = 0; i < sorting.length; i++) {
    const { column, direction } = sorting[i];
    if (!direction) continue;
    const { values = [], type = QueryInputType.DEFAULT } = output[column] || ({} as any); // TODO: fix this any
    output[column] = queryInput(values, type, direction, i);
  }

  return output;
};

const isLoopName = (value: string): boolean => /\D/g.test(value) || value.length > 4;

export default HtmlTripPage;
