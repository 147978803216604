import Header from '@/components/Header';
import NotificationWrapper from '@/components/Notification/NotificationWrapper';
import useLocale from '@/hooks/useLocale';

const NotAllowed = () => {
  const locale = useLocale({
    'Back To Homepage': 'Back To Homepage',
    'You do not have access to this page.': 'You do not have access to this page.',
    'Please contact support@skyhoptechnologies.com for more support.': 'Please contact support@skyhoptechnologies.com for more support.',
  });
  return (
    <>
      <Header />
      <NotificationWrapper />
      <div className="NotAllowed">
        <div className="home-navigation">
          <a href="/">
            <i className="fa fa-chevron-left"></i>
            {locale('Back To Homepage')}
          </a>
        </div>
        <div className="not-allowed-container">
          <div className="not-allowed-content"></div>
          <p>
            {locale('You do not have access to this page.')}
            <br /> {locale('Please contact support@skyhoptechnologies.com for more support.')}
          </p>
        </div>
      </div>
    </>
  );
};

export default NotAllowed;
