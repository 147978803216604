import { ChangeEvent, ReactNode, Ref, TextareaHTMLAttributes, forwardRef } from 'react';
import Field, { FieldProps } from '@/components/Field';

import { getClasses } from '@/utils';

type TextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
  options?: {
    disableResize?: boolean;
  };
};

const TextAreaWithRef = (
  { onChange, isValid, isInvalid, isDirty, ...textareaProps }: TextAreaProps,
  ref: Ref<HTMLTextAreaElement>
): ReactNode => (
  <textarea
    ref={ref}
    {...textareaProps}
    style={{ resize: textareaProps?.options?.disableResize ? 'none' : 'both' }}
    className={getClasses(
      'TextArea',
      'form-control',
      isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined,
      isDirty ? 'is-dirty' : undefined,
      textareaProps?.className
    )}
    onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => onChange?.(event.target.value)}
  />
);

const TextArea = forwardRef(TextAreaWithRef);

type TextAreaFieldOptions = {
  field?: {
    className?: string;
  };
};
type TextAreaFieldProps = Omit<FieldProps, 'children'> &
  TextAreaProps & {
    options?: TextAreaFieldOptions;
  };

export const TextAreaField = ({ label, feedback, valid, dirty, required, ...inputProps }: TextAreaFieldProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} {...inputProps?.options?.field} dirty={dirty}>
      <TextArea {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default TextArea;
