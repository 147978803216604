import EnumDropdown, { EnumDropdownProps } from '@/components/EnumDropdown';
import Field, { FieldProps } from '@/components/Field';

import { ReactNode } from 'react';
import { StopTypeEnum } from '@/models/gen/graphql';

type TripStopTypeDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  name?: string;
};

const TripStopTypeDropdown = ({ name = 'tripStopType', ...props }: TripStopTypeDropdownProps): ReactNode => {
  return (
    <EnumDropdown
      {...props}
      enum={StopTypeEnum}
      name={name}
      options={{ locale: props?.options?.locale || { 'Select...': 'Type', Pu: 'P/U', Do: 'D/O' }, ...(props?.options || {}) }}
    />
  );
};

export const TripStopTypeDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & TripStopTypeDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <TripStopTypeDropdown {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default TripStopTypeDropdown;
