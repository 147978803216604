import useEventListener from '@/hooks/useEventListener';
import { useRef } from 'react';

const useDoubleKeyDown = (key: string, callback: (event: KeyboardEvent) => void, target: HTMLElement = document.body): void => {
  const lastKeyDown = useRef<number>(0);

  const handleKeydown = (event: KeyboardEvent): void => {
    if (event.key !== key) return;

    const now = new Date().getTime();
    if (now - lastKeyDown.current < 500) callback?.(event);
    lastKeyDown.current = now;
  };

  useEventListener('keydown', handleKeydown, target);
};

export default useDoubleKeyDown;
