import { ConnectionDetails, QueryInputType, getBulkValues, onEnter, parsePhoneNumber, queryInput } from '@/utils';
import React, { useRef, useState } from 'react';
import { SEARCH_VEHICLE_WITH_AIRPORT_PAGE_SIZE, useSearchVehiclesTable } from '@/api/services/vehicles/searchVehicles';
import { SortDirectionEnum, Vehicle } from 'models/gen/graphql';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';
import { handleCreateVehicleBulk, handleUpdateVehicleBulk } from '@/api/services/vehicles';

import { AirportGroupDropdown } from '@/components/AirportDropdown';
import { Badge } from 'react-bootstrap';
import ConfirmationButton from '@/components/ConfirmationButton';
import Dropdown from '@/components/Dropdown';
import EditVehiclesModal from 'components/EditVehiclesModal';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import PageInfo from '@/components/PageInfo';
import { STATUSES } from '@/constants';
import deleteVehicleBulk from '@/api/services/vehicles/deleteVehicleBulk';
import { getClasses } from '@/utils/strings';

type VehicleTableState = {
  selected: string[];
  editing: boolean;
  creating: boolean;
  search: string;
  sorting: { column: string; direction: string };
};
type VehicleTableFilters = {
  airportCode: [];
  active: string;
};

const initVehiclesTableState: VehicleTableState = {
  selected: [],
  editing: false,
  creating: false,
  search: '',
  sorting: {
    column: undefined,
    direction: undefined,
  },
};
const initVehiclesTableFilters: VehicleTableFilters = {
  airportCode: [],
  active: '1',
};
const VehiclesTable = (): JSX.Element => {
  const [state, setState] = useState(initVehiclesTableState);
  const { creating, editing, selected, search, sorting } = state;

  const [{ data, loading }, { fetch: searchVehiclesTable, fetchMore }] = useSearchVehiclesTable();
  const { rows, hasNextPage, totalCount } = data || {};
  const searchableColumns = ['licensePlate', 'vin', 'trackingId', 'airportCodes', 'location.name', 'phoneNumber', 'active'];
  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows, search, searchableColumns });
  const lastQuery = useRef();

  const generateQuery = (filters: any): any => {
    const result = {
      active: queryInput(filters?.active !== undefined ? [filters?.active === '1' ? 1 : 0] : null),
      createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc, 0),
    };
    if (filters?.airportCode?.length > 0) result['airportCode'] = queryInput(filters?.airportCode || []);
    return result;
  };
  const onHide = async (): Promise<void> => {
    setState((current: VehicleTableState): VehicleTableState => ({ ...current, editing: false, creating: false, selected: [] }));
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Vehicle>> => {
    if (loading || !hasNextPage) return;
    return await fetchMore(lastQuery.current, {
      page: Math.round(after / SEARCH_VEHICLE_WITH_AIRPORT_PAGE_SIZE),
      merge: true,
    });
  };

  const onSubmit = async (filters: any): Promise<void> => {
    const query = generateQuery(filters);
    searchVehiclesTable(query);
    lastQuery.current = query;
  };

  return (
    <>
      <PageInfo>
        {(filteredRows || []).length} / {totalCount} Vehicles
      </PageInfo>
      <Filters
        name="vehicleFilters"
        value={initVehiclesTableFilters}
        onSubmit={onSubmit}
        onReset={(): void => setState((current: VehicleTableState): VehicleTableState => ({ ...current, search: '' }))}
        submitOnMount={true}
        primary={({ values, onChange }): JSX.Element => {
          const { airportCode, active } = values;
          return (
            <>
              <AirportGroupDropdown
                name="airportCode"
                value={airportCode}
                onChange={(value) => onChange({ target: { name: 'airportCode', value } })}
                options={{ locale: { 'Select...': 'Airport' } }}
              />
              <Dropdown
                name="active"
                value={active}
                onChange={(value) => onChange({ target: { name: 'active', value } })}
                items={STATUSES.default.map(({ id, displayName }) => ({
                  value: `${id}`,
                  label: displayName,
                  keywords: displayName.split(' '),
                }))}
                options={{ locale: { 'Select...': 'Status' } }}
              />
            </>
          );
        }}
        controls={({ values, onChange }): JSX.Element => {
          const { search } = values;
          return (
            <>
              <FormField
                prepend={<i className="sv sv-magnifier fs-4" />}
                id={'filters-searchbar'}
                name="search"
                value={search || ''}
                onChange={onChange}
                onBlur={(): void => setState((current: VehicleTableState): VehicleTableState => ({ ...current, search }))}
                onKeyDown={onEnter((): void => {
                  setState((current: VehicleTableState): VehicleTableState => ({ ...current, search }));
                })}
                placeholder="Search"
                style={{ maxWidth: 300 }}
                condensed
                inline
              />
            </>
          );
        }}
        alternate={(): JSX.Element => (
          <>
            <FormButton
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="CREATE_VEHICLE"
              variant="outline-gray"
              onClick={(): void => setState((current: VehicleTableState): VehicleTableState => ({ ...current, creating: true }))}
            >
              Add Vehicle
            </FormButton>
            <FormButton
              icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
              name="EDIT_VEHICLE"
              variant="outline-gray"
              onClick={(): void => setState((current: VehicleTableState): VehicleTableState => ({ ...current, editing: true }))}
              disabled={selected.length === 0}
            >
              {selected.length > 1 ? 'Bulk Edit' : 'Edit'}
              {selected.length > 1 && (
                <Badge className="ms-2" pill bg="success">
                  {selected.length}
                </Badge>
              )}
            </FormButton>
            <ConfirmationButton
              icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
              tooltip={
                <>
                  Delete
                  {selected.length > 1 && (
                    <Badge className="ms-2" pill bg="success">
                      {selected.length}
                    </Badge>
                  )}
                </>
              }
              name="DELETE_VEHICLE"
              variant="outline-gray"
              onConfirm={async (): Promise<any> => {
                await deleteVehicleBulk(selected);
                await onHide();
              }}
              disabled={selected.length === 0}
            />
          </>
        )}
      />
      <VirtualTable
        name="vehicle"
        loading={loading}
        data={filteredRows || []}
        selected={selected}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        header={{
          licensePlate: 'Tag',
          vin: 'VIN',
          trackingId: 'Vehicle No.',
          airportCodes: 'Airport',
          location: { name: 'Exchange Place' },
          phoneNumber: 'Phone',
          active: 'Status',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void =>
              setState(
                (current: VehicleTableState): VehicleTableState => ({
                  ...current,
                  selected: Array.from(new Set([data?.id])),
                  editing: true,
                })
              )
            }
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="licensePlate"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('licensePlate')}
            />
            <DynamicCell
              selector="vin"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('vin')}
            />
            <DynamicCell
              selector="trackingId"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 7)"
              sorting={makeSortable('trackingId')}
            />
            <DynamicCell
              selector="airportCodes"
              placeholder="--"
              className="text-center"
              width="calc(100% / 7)"
              render={({ value }: { value: any }): string =>
                typeof value === 'string'
                  ? value
                  : (value || []).map(({ airportCode }: { airportCode: string }): string => airportCode).join(', ')
              }
              sorting={makeSortable('airportCodes')}
            />
            <DynamicCell
              selector="location.name"
              placeholder="--"
              className="text-center"
              width="calc(100% / 7)"
              sorting={makeSortable('location.name')}
            />
            <DynamicCell
              selector="phoneNumber"
              placeholder="--"
              className="text-center"
              width="calc(100% / 7)"
              render={({ value }: { value: string }): string => (_type !== 'header' ? parsePhoneNumber(value, ' ') : value)}
              sorting={makeSortable('phoneNumber')}
            />
            <DynamicCell
              selector="active"
              placeholder="--"
              className="text-center"
              width="calc(100% / 7)"
              render={({ value }: { value: number }): 'Active' | 'Inactive' => (Number(value) ? 'Active' : 'Inactive')}
              sorting={makeSortable('active')}
            />
          </VirtualTableRow>
        )}
      />
      <EditVehiclesModal
        show={editing || creating}
        data={
          editing ? (filteredRows || []).find((node: Vehicle): boolean => node.id === selected[0]) : creating ? { active: 0 } : undefined
        }
        title={`${editing ? 'Edit' : 'Create'} Vehicle`}
        onHide={onHide}
        onSubmit={async (updates: any): Promise<any> => {
          const selectedVehicles = {};
          rows.forEach((node: Vehicle): void => {
            if (selected.includes(node.id)) selectedVehicles[node.id] = node;
          });
          if (editing) {
            await handleUpdateVehicleBulk(updates, selectedVehicles);
          } else {
            await handleCreateVehicleBulk(updates);
          }
          onHide();
          searchVehiclesTable(lastQuery.current);
        }}
        options={{
          fields: editing ? getBulkValues((filteredRows || []).filter((node: Vehicle): boolean => selected.includes(node.id))) : undefined,
        }}
        loading={false}
        name="editVehicle"
      />
    </>
  );
};

export default VehiclesTable;
