import { DropdownItem, DropdownOptions } from '@/components/Dropdown';
import Field, { FieldProps } from '@/components/Field';
import { Location, SortDirectionEnum } from '@/models/gen/graphql';
import QueryDropdown, { QueryDropdownProps } from '../QueryDropdown';
import { QueryInput, QueryInputType, queryInput } from '@/utils';
import React, { ReactNode, forwardRef, useCallback, useEffect, useRef } from 'react';

import { Button } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { selectLocationFilter } from '@/api/services/locations/searchLocations';
import { useGlobalEditLocationModalState } from '@/components/GlobalEditLocationModal';

export type LocationQueryDropdownProps = Omit<QueryDropdownProps, 'query'> & {
  airports?: string[];
  query?: Record<string, QueryInput>[];
  options?: LocationQueryDropdownOptions;
  onCreate?: (location: Location) => void;
};

export type LocationQueryDropdownOptions = DropdownOptions & {
  showAirportPrefix?: boolean;
  autoSelectLocation?: string;
};

const LocationQueryDropdown = (() => {
  const LocationQueryDropdown = (
    { name = 'location', airports = [], query, options: allOptions, onCreate, ...queryDropdownProps }: LocationQueryDropdownProps,
    ref
  ): ReactNode => {
    const showEditLocationModal = useGlobalEditLocationModalState(({ setState }) => setState);
    const isDirty = useRef<boolean>(false);
    const lastItems = useRef<DropdownItem[]>(null);
    const { autoSelectLocation, showAirportPrefix, ...queryDropdownOptions } = allOptions || {};

    const handleCreateLocation = (): void => {
      showEditLocationModal({
        show: true,
        data: undefined,
        onSubmit: undefined,
        onAfterSubmit: async (_update, newLocation) => {
          await ref.current?.refreshItems();
          onCreate?.(newLocation);
        },
        onHide: undefined,
      });
    };

    const handleChange = (value: string): void => {
      isDirty.current = true;
      queryDropdownProps.onChange?.(value, undefined);
    };

    const handleAutoSelect = (items = []) => {
      if (!autoSelectLocation || !!queryDropdownProps?.value) return;
      const option = items.find((item) => item.label.trim() === autoSelectLocation);
      if (!option || option.value === queryDropdownProps?.value) return;
      queryDropdownProps?.onChange?.(option.value, undefined);
    };

    const getItems = useCallback(async (): Promise<DropdownItem[]> => {
      const res = await selectLocationFilter(
        query || [
          {
            airportCode: airports?.length > 0 ? queryInput(airports, QueryInputType.OR, SortDirectionEnum.Asc) : null,
            name: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc),
          },
        ]
      );
      const items = (res?.rows || []).map(
        ({ id, name, displayName }: Location): DropdownItem => ({
          label: showAirportPrefix !== false ? displayName : name,
          keywords: name.split(' '),
          value: id,
        })
      );
      lastItems.current = items;
      handleAutoSelect(items);
      return items;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, airports, showAirportPrefix]);

    useEffect(() => {
      if (!autoSelectLocation) return;
      getItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSelectLocation]);

    return (
      <QueryDropdown
        {...queryDropdownProps}
        onChange={handleChange}
        ref={ref}
        options={{
          ...queryDropdownOptions,
          indicators: (
            <>
              {onCreate && (
                <Tippy content="Create New Location">
                  <Button variant="icon" onClick={handleCreateLocation} tabIndex={-1}>
                    <i className="fa fa-plus" />
                  </Button>
                </Tippy>
              )}
              {queryDropdownOptions?.indicators || null}
            </>
          ),
        }}
        name={name}
        query={getItems}
      />
    );
  };
  return forwardRef(LocationQueryDropdown);
})();

export type LocationQueryDropdownField = Omit<FieldProps, 'children'> & LocationQueryDropdownProps;

export const LocationQueryDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...locationQueryDropdownProps
}: LocationQueryDropdownField): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <LocationQueryDropdown {...locationQueryDropdownProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default LocationQueryDropdown;
