import React, { useCallback, useMemo } from 'react';
import useAirlineTripSettings, { AirlineColumnKeyEnum } from '@/features/AirlineTrips/components/TripSettingsModal/hook';
import useAirlineTripTableState, { AirlineTripSortColumnEnum } from '@/features/AirlineTrips/components/TripsTable/hook';

import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import { SimpleTableColumnsProps } from '@/components/SimpleTable';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import SimpleTableSortCell from '@/components/SimpleTable/SimpleTableSortCell';
import { SortDirectionEnum } from '@/models/gen/graphql';

const TripTableHeader = (_rows: SimpleTableColumnsProps): React.JSX.Element => {
  const columns = useAirlineTripSettings(({ state }) => new Map(state.columns));
  const sorting = useAirlineTripTableState(({ state }) => state.sorting);
  const onSort = useAirlineTripTableState(({ state }) => state.onSort);
  const onSortOnly = useAirlineTripTableState(({ state }) => state.onSortOnly);

  const columnSortMap = useMemo(
    () =>
      sorting.reduce((acc, curr) => {
        acc[curr.column] = curr.direction;
        return acc;
      }, {}) as Record<AirlineTripSortColumnEnum, SortDirectionEnum>,
    [sorting]
  );

  const handleSort = useCallback(
    (column: AirlineTripSortColumnEnum): React.MouseEventHandler<HTMLButtonElement> =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        // is user holding shift key
        if (event.shiftKey) return onSort(column);
        return onSortOnly(column);
      },
    [onSort, onSortOnly]
  );
  return (
    <SimpleTableRow header>
      {columns.get(AirlineColumnKeyEnum.TYPE) && (
        <SimpleTableCell name="TYPE" className="w-md-fixed">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.type)}
            direction={columnSortMap[AirlineTripSortColumnEnum.type]}
          >
            TYPE
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_DATE) && (
        <SimpleTableCell name="SCHEDULED_DATE" className="w-sm-grow">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.latestScheduled)}
            direction={columnSortMap[AirlineTripSortColumnEnum.latestScheduled]}
          >
            DATE
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_TIME) && (
        <SimpleTableCell name="SCHEDULED_TIME" className="w-sm-grow">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.latestScheduledUtc)}
            direction={columnSortMap.latestScheduledUtc}
          >
            SCH
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.ETA) && (
        <SimpleTableCell name="ETA" className="w-md-fixed">
          <SimpleTableSortCell onSort={handleSort(AirlineTripSortColumnEnum.eta)} direction={columnSortMap[AirlineTripSortColumnEnum.eta]}>
            ETA
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.ACTUAL) && (
        <SimpleTableCell name="ACTUAL" className="w-sm-grow">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.actual)}
            direction={columnSortMap[AirlineTripSortColumnEnum.actual]}
          >
            ACT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.AIRPORT_CODE) && (
        <SimpleTableCell name="AIRPORT_CODE" className="w-md-fixed">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.airportCode)}
            direction={columnSortMap[AirlineTripSortColumnEnum.airportCode]}
          >
            CITY
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.IATA_CODE) && (
        <SimpleTableCell name="IATA_CODE" className=" w-sm-fixed">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.servicerIataAirlineCode)}
            direction={columnSortMap.servicerIataAirlineCode}
          >
            AL
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.FLIGHT_NUMBER) && (
        <SimpleTableCell name="FLIGHT_NUMBER">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.flightNumber)}
            direction={columnSortMap[AirlineTripSortColumnEnum.flightNumber]}
          >
            FLT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.PILOT) && (
        <SimpleTableCell name="PILOT" className="w-sm-fixed">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.pilots)}
            direction={columnSortMap[AirlineTripSortColumnEnum.pilots]}
          >
            PLT
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.ATTENDANT) && (
        <SimpleTableCell name="ATTENDANT" className="w-sm-fixed">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.attendants)}
            direction={columnSortMap[AirlineTripSortColumnEnum.attendants]}
          >
            FA
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.PU_LOCATION) && (
        <SimpleTableCell name="PU_LOCATION" className="w-lg-grow">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.puLocationId)}
            direction={columnSortMap[AirlineTripSortColumnEnum.puLocationId]}
          >
            P/U
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
      {columns.get(AirlineColumnKeyEnum.DO_LOCATION) && (
        <SimpleTableCell name="DO_LOCATION" className="w-lg-grow">
          <SimpleTableSortCell
            onSort={handleSort(AirlineTripSortColumnEnum.doLocationId)}
            direction={columnSortMap[AirlineTripSortColumnEnum.doLocationId]}
          >
            D/O
          </SimpleTableSortCell>
        </SimpleTableCell>
      )}
    </SimpleTableRow>
  );
};
export default React.memo(TripTableHeader);
