import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';
import { SimpleTableColumnsProps, useSimpleTableContext } from '@/components/SimpleTable';

import { LoadingBlur } from '@/components/LoadingSpinner';
import SimpleTableControls from '@/components/SimpleTable/SimpleTableControls';
import { getClasses } from '@/utils';

export type SimpleTableContainerProps = {
  header?: JSX.Element | ((props: SimpleTableColumnsProps) => JSX.Element);
  rows?: string[];
} & HTMLAttributes<HTMLDivElement>;

const SimpleTableContainer = ({ children, ...divProps }: SimpleTableContainerProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const { loading } = useSimpleTableContext();

  return (
    <div className={getClasses('SimpleTable-Content', divProps.className)} ref={ref}>
      <SimpleTableControls />
      <div className="SimpleTable-Body">
        <LoadingBlur loading={loading} />
        {children}
      </div>
    </div>
  );
};

export default forwardRef(SimpleTableContainer);
