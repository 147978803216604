import EnumDropdown, { EnumDropdownProps } from '@/components/EnumDropdown';
import Field, { FieldProps } from '@/components/Field';

import { ReactNode } from 'react';
import { TripStatusEnum } from '@/models/gen/graphql';

type TripStatusDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  name?: string;
};

const TripStatusDropdown = ({ name = 'tripStatus', ...props }: TripStatusDropdownProps): ReactNode => {
  return (
    <EnumDropdown {...props} enum={TripStatusEnum} name={name} options={{ locale: props?.options?.locale || { 'Select...': 'Status' } }} />
  );
};

export const TripStatusDropdownField = ({
  label,
  feedback,
  valid,
  dirty,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & TripStatusDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} dirty={dirty}>
      <TripStatusDropdown {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default TripStatusDropdown;
