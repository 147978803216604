import './styles.scss';

import Field, { FieldProps } from '@/components/Field';
import { HTMLAttributes, createContext, forwardRef, useCallback, useContext, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { Button } from 'react-bootstrap';
import CalendarWeek from '@/components/Calendar/CalendarWeek';
import NumberInput from '@/components/NumberInput';
import { TODAY } from '@/constants';
import { getClasses } from '@/utils';

export type CalendarProps = {
  value?: string | string[];
  multiple?: number | boolean;
  range?: number | boolean;
  onChange: (date?: string, value?: string[]) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>;
export type DayObject = { date: string; day: string; month: string; year: string };
export type CalendarContextType = {
  month: Dayjs;
  startDate?: DayObject;
  endDate?: DayObject;
  setStartDate?: (date: DayObject) => void;
  setEndDate?: (date: DayObject) => void;
  selected: string[];
  multiple?: number | boolean;
  range?: number | boolean;
  onChange: (day: Dayjs) => void;
};
const initCalendarContext: CalendarContextType = {
  month: undefined,
  startDate: undefined,
  endDate: undefined,
  setStartDate: undefined,
  setEndDate: undefined,
  selected: [],
  multiple: false,
  range: false,
  onChange: (): void => {},
};
const CalendarContext = createContext<CalendarContextType>(initCalendarContext);
export const useCalendarContext = (): CalendarContextType => {
  const context = useContext(CalendarContext);
  if (context === undefined) throw new Error('useCalendarContext must be used within a CalendarProvider');
  return context;
};

const Calendar = forwardRef(function Calendar(
  { value, onChange, isValid, isInvalid, isDirty, multiple, range, ...divProps }: CalendarProps,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  value = (Array.isArray(value) ? value : [value]).filter(isValidDatetimeString);
  const [date, setDate] = useState<Dayjs>(dayjs(value?.[0] ?? undefined));
  const lastClick = useRef<number>(0);
  const month = useMemo<Dayjs[][]>((): Dayjs[][] => {
    const from = date.clone();
    const start = from.startOf('month').startOf('week');
    const day = dayjs(start);
    return Array.from({ length: 5 }, (_week: unknown, weekIndex: number): Dayjs[] =>
      Array.from({ length: 7 }, (_day: unknown, dayIndex: number): Dayjs => day.add(dayIndex + weekIndex * 7, 'day'))
    );
  }, [date]);

  const [startDate, endDate] = useMemo<DayObject[]>((): DayObject[] => {
    const dates = (value || [TODAY]).map?.(
      (date: string): DayObject => ({
        date,
        day: dayjs(date).format('DD'),
        month: dayjs(date).format('MMMM'),
        year: dayjs(date).format('YYYY'),
      })
    );
    const start = dates?.shift?.();
    const end = dates?.pop?.();
    return [start, end];
  }, [value]);

  const handleChange = useCallback(
    (day: Dayjs): void => {
      let start = lastClick.current === 0 ? day : dayjs(startDate?.date || endDate?.date || day.format('YYYY-MM-DD'));
      let end = lastClick.current === 1 ? day : dayjs(endDate?.date || startDate?.date || day.format('YYYY-MM-DD'));
      if (start.isAfter(end)) [start, end] = [end, start];
      const diff = Math.abs(end.diff(start, 'day')) - 1; // Subtract 1 to account for the first date.
      const dates = Array.from({ length: diff }, (_: unknown, i: number): string => start.add(i + 1, 'day').format('YYYY-MM-DD'));
      const range = Array.from(new Set([start.format('YYYY-MM-DD'), ...dates, end.format('YYYY-MM-DD')]));
      const result = range.sort((a: string, b: string): number => new Date(a).getTime() - new Date(b).getTime());
      lastClick.current = (lastClick.current + 1) % 2; // Cap the click count at 1.
      onChange(day.format('YYYY-MM-DD'), result);
    },
    [startDate, endDate, onChange]
  );

  const calendarContext = useMemo<CalendarContextType>(
    (): CalendarContextType => ({ month: date, startDate, endDate, selected: value, multiple, range, onChange: handleChange }),
    [date, startDate, endDate, value, multiple, range, handleChange]
  );

  const onChangeYear = (value: number): void => setDate((current: Dayjs): Dayjs => current.set('year', value));
  const onSubtractMonth = (): void => setDate((current: Dayjs): Dayjs => current.subtract(1, 'month'));
  const onAddMonth = (): void => setDate((current: Dayjs): Dayjs => current.add(1, 'month'));

  const dates = useMemo<JSX.Element[]>(
    (): JSX.Element[] => month.map((week: Dayjs[], index: number): JSX.Element => <CalendarWeek dates={week} key={index} />),
    [month]
  );

  const classes = getClasses(
    'Calendar-New',
    divProps.className,
    isValid !== undefined && isValid !== false ? 'is-valid' : undefined,
    isInvalid !== undefined && isInvalid !== false ? 'is-invalid' : undefined,
    isDirty !== undefined && isDirty !== false ? 'is-dirty' : undefined
  );

  return (
    <CalendarContext.Provider value={calendarContext}>
      <div
        className={classes}
        /* Enable Focus
          This allows non-focusable elements to receive focus and be tabbed to.
        */ // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        ref={ref}
      >
        <div className="Calendar-Header">
          <div className="Calendar-Month">
            <span>{date.format('MMMM')}</span>
          </div>
          <div className="Calendar-Year">
            <NumberInput value={parseInt(date.format('YYYY'))} onChange={onChangeYear} tabIndex={-1} />
          </div>
          <div className="Calendar-Controls">
            <Button variant="icon" size="sm" onClick={onSubtractMonth} tabIndex={-1}>
              <i className="fa fa-chevron-left" />
            </Button>
            <Button variant="icon" size="sm" onClick={onAddMonth} tabIndex={-1}>
              <i className="fa fa-chevron-right" />
            </Button>
          </div>
        </div>
        <div className="Calendar-Body">
          <CalendarWeek />
          {dates}
        </div>
      </div>
    </CalendarContext.Provider>
  );
});

export const CalendarField = forwardRef(function CalendarField(
  { className, label, feedback, valid, dirty, required, ...calendarProps }: CalendarProps & FieldProps,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  return (
    <Field className={className} label={label} feedback={feedback} valid={valid} dirty={dirty} required={required}>
      <Calendar {...calendarProps} isValid={valid === true} isInvalid={valid === false} isDirty={dirty === true} ref={ref} />
    </Field>
  );
});

export const isValidDatetimeString = (value: string): boolean => {
  try {
    const date = new Date(value);
    return !isNaN(date.getTime());
  } catch (err) {
    console.warn(err.message || err);
    return false;
  }
};

export default Calendar;
