import {
  ComponentProps,
  ElementType,
  ReactNode,
  RefObject,
  createContext,
  forwardRef,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { useIsVisibleWithRef } from '@/hooks/useIsVisible';

export type VisibleContextValue = {
  isVisible: boolean;
};
export const VisibleContext = createContext<VisibleContextValue>({ isVisible: false });
export const useVisibleContext = (): VisibleContextValue => useContext(VisibleContext);

export type VisibleProps = {
  children: ReactNode;
  as?: ElementType;
  fallback?: ReactNode;
  disabled?: boolean;
  options?: Partial<IntersectionObserverInit> | ((ref: RefObject<HTMLElement>) => Partial<IntersectionObserverInit>);
};
const Visible = (
  { children, fallback = null, as: As = 'div', disabled, options, ...asProps }: VisibleProps & ComponentProps<typeof As>,
  outerRef: RefObject<typeof As>
): ReactNode => {
  const mounted = useRef<boolean>(false);
  const innerRef = useRef<RefObject<ComponentProps<typeof As>>>(null);
  const currentRef = outerRef || innerRef;
  const visible = useIsVisibleWithRef([currentRef], options);
  const isVisible = mounted.current && (visible || (disabled !== undefined && disabled !== false));
  const visibleContextValue = useMemo((): VisibleContextValue => ({ isVisible }), [isVisible]);

  useLayoutEffect((): (() => void) => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  }, []);

  return (
    <As {...asProps} ref={currentRef}>
      <VisibleContext.Provider value={visibleContextValue}>
        {isVisible && children}
        {!isVisible && fallback}
      </VisibleContext.Provider>
    </As>
  );
};

export default forwardRef(Visible);
