import { DATETIME_FE_FORMAT, DATE_FE_FORMAT } from '@/constants';
import { Datetime, formatDollars, queryInput } from '@/utils';
import { Invoice, InvoiceDocument, InvoiceSearch } from '@/models/gen/graphql';
import React, { useEffect, useRef, useState } from 'react';
import { SEARCH_INVOICE_PAGE_SIZE, useSearchInvoices } from '@/api/services/invoices/searchInvoices';
import VirtualTable, { DynamicCell, FormatCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import { ConnectionDetails } from '@/utils/custom';
import { InvoiceHistoryFilters } from '@/pages/InvoiceHistory/InvoiceHistoryFilters';
import PageInfo from '@/components/PageInfo';
import Tippy from '@tippyjs/react';
import equal from 'fast-deep-equal/es6/react';
import { getClasses } from '@/utils/strings';
import runArchiveInvoices from '@/api/services/invoices/runArchiveInvoices';
import runDownloadLatestInvoiceDocument from '@/api/services/invoices/runDownloadLatestInvoiceDocument';
import useConfirmation from '@/hooks/useConfirmation';
import useHotkeys from '@/hooks/useHotkeys';
import { useNavigate } from 'react-router-dom';
import usePage from '@/hooks/usePage';

interface InvoiceHistoryState {
  sorting: { column: string; direction: string };
  selected: string[];
  rows: Invoice[];
  search: string;
}

const initInvoiceHistoryState: InvoiceHistoryState = {
  sorting: { column: 'createdAt', direction: 'desc' },
  selected: [],
  rows: [],
  search: '',
};
const InvoiceHistoryTable = () => {
  const [state, setState] = useState(initInvoiceHistoryState);
  const { sorting, selected, search } = state;
  const [page, setPage] = usePage('invoiceHistory');
  const [{ data, loading }, { fetch: searchInvoices, refetch, fetchMore }] = useSearchInvoices();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const navigate = useNavigate();
  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows, search });

  useHotkeys('Invoice History', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: (): any => (document.querySelector('.Filters input[name="search"]') as HTMLElement)?.focus?.(),
    },
    'shift+ctrl+c': {
      name: 'Create Invoice',
      description: 'Navigate to Create Invoice page',
      action: (): any => createInvoice(),
    },
    'shift+ctrl+r': {
      name: 'Refresh Table',
      description: 'Refreshes the Invoice History table',
      action: (): any => refetch([lastQuery.current]),
    },
  });

  const confirmArchiveInvoices = useConfirmation({
    Body: {
      message: `Archiving ${selected?.length} invoice(s). Is this ok?`,
    },
    Footer: {
      resolve: 'Yes',
      reject: 'Cancel',
    },
  });

  const handleDownloadInvoice = async (invoiceId: number): Promise<void> => {
    const { url, error } = await runDownloadLatestInvoiceDocument(invoiceId);
    if (error) return;
    window.open(url, '_blank', 'noreferrer');
  };

  const handleArchiveInvoices = async () => {
    if (selected?.length > 0) {
      try {
        await confirmArchiveInvoices();
        const response = await runArchiveInvoices(selected);
        await refetch([lastQuery.current]);
        setState((current: InvoiceHistoryState): InvoiceHistoryState => ({ ...current, selected: [] }));
        return response;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const openInvoice = (invoiceId: number = 0): void => navigate(`/invoices/${invoiceId}`);

  const createInvoice = () => {
    window.localStorage.removeItem('invoice-preview-filters');
    openInvoice(0);
  };
  const lastQuery = useRef<InvoiceSearch>(page?.query);
  const onSubmit = async (filters) => {
    const { from, to, payerProviderId, status } = filters;
    // TODO: Move this logic to the service.
    const query: InvoiceSearch = {
      createdAt: queryInput.date([new Datetime(from).toString(), new Datetime(to).toString()], true),
      payerProviderId: queryInput(payerProviderId),
      status: status?.length ? queryInput(status || []) : null,
    };
    await (equal(lastQuery.current, query) ? refetch([query]) : searchInvoices([query]));
    lastQuery.current = query;
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Invoice>> => {
    if (loading || !hasNextPage) return;
    return await fetchMore([lastQuery.current], {
      page: Math.round(after / SEARCH_INVOICE_PAGE_SIZE),
      merge: true,
    });
  };

  const onLeavePage = (): void => {
    setPage((current) => ({
      ...(current || {}),
      query: lastQuery.current,
    }));
  };

  useEffect((): (() => void) => {
    return onLeavePage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageInfo>
        {(filteredRows || []).length} / {totalCount} Invoices
      </PageInfo>
      <InvoiceHistoryFilters
        selected={selected}
        onSubmit={onSubmit}
        onArchive={handleArchiveInvoices}
        onSearch={(val) => setState((current) => ({ ...current, search: val }))}
      />
      <VirtualTable
        name="invoiceHistory"
        data={filteredRows}
        loading={loading}
        selected={selected}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        header={{
          id: '#',
          name: 'Name',
          invoiced: 'Inv Date',
          startDatetime: 'Start',
          endDatetime: 'End',
          provider: { displayName: 'Client' },
          airports: 'Airport',
          grandTotal: 'Amount',
          due: 'Due',
          creator: { fullName: 'Creator' },
          download: <i className="sv sv-download2 fs-4" />,
          updatedAt: 'Amended',
          documents: [{ sentAt: 'Sent', sender: { fullName: 'Sent By' } }],
          createdAt: 'Created',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void => openInvoice(data?.id)}
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell selector="id" placeholder="--" className="text-center alternate" width="8rem" sorting={makeSortable('id')} />
            <FormatCell
              selector="invoiced"
              placeholder="--"
              className="text-center"
              width="8rem"
              sorting={makeSortable('invoiced')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <FormatCell
              selector="startDatetime"
              placeholder="--"
              className="text-center"
              width="8rem"
              sorting={makeSortable('startDatetime')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <FormatCell
              selector="endDatetime"
              placeholder="--"
              className="text-center"
              width="8rem"
              sorting={makeSortable('endDatetime')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <DynamicCell
              selector="provider.displayName"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 10)"
              sorting={makeSortable('provider.displayName')}
            />
            <DynamicCell
              selector="airports"
              placeholder="--"
              className="text-center alternate text-nowrap"
              width="7rem"
              sorting={makeSortable('airports')}
              render={({ value }: { value: string[] }): string => (Array.isArray(value) ? (value || []).join(', ') : value)}
            />
            <DynamicCell
              selector="grandTotal"
              placeholder="--"
              className="text-center"
              width="calc(100% / 14)"
              sorting={makeSortable('grandTotal')}
              render={({ data }) => `$${formatDollars(data?.grandTotal)}`}
            />
            <FormatCell
              selector="due"
              placeholder="--"
              className="text-center"
              width="8rem"
              sorting={makeSortable('due')}
              format={(value: string): string => new Datetime(value).format(DATE_FE_FORMAT)}
            />
            <DynamicCell
              selector="creator.fullName"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 10)"
              sorting={makeSortable('creator')}
            />
            <DynamicCell
              selector="download"
              placeholder="--"
              className="text-center alternate"
              width="4rem"
              render={({ data }: { data: Invoice }): JSX.Element => (
                <Tippy content="Download Invoice">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <i className="sv sv-download2 fs-4 pointer" onClick={() => handleDownloadInvoice(data?.id)} onKeyDown={() => {}} />
                </Tippy>
              )}
            />
            <FormatCell
              selector="updatedAt"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 14)"
              sorting={makeSortable('updatedAt')}
              format={(value: string): string => new Datetime().setAsUTC(value).toLocaleDatetime().format(DATETIME_FE_FORMAT)}
            />
            <DynamicCell
              selector="documents.-1.sentAt"
              placeholder="--"
              className="text-center alternate"
              width="4rem"
              render={({ data }: { data: Invoice }): JSX.Element => {
                const { documents } = data || {};
                // converts a datetime string that is in UTC to the local datetime string
                const lastSentDocument = [...(documents || [])].reverse().find((document: InvoiceDocument) => !!document?.sentAt);
                const sentAt = lastSentDocument
                  ? new Datetime().setAsUTC(lastSentDocument?.sentAt).toLocaleDatetime().frontendDatetime
                  : '';

                return (
                  <Tippy content={`${sentAt ? `Last sent at ${sentAt}` : 'Not Yet Sent'}`}>
                    <i
                      className={`fa-regular fa-envelope pointer ${sentAt ? 'text-success' : 'text-gray opacity-25'} {font-size:1.5rem}`}
                    ></i>
                  </Tippy>
                );
              }}
            />
            <DynamicCell
              selector="documents.-1.sender.fullName"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 10)"
              sorting={makeSortable('documents.-1.sender.fullName')}
            />
            <FormatCell
              selector="createdAt"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 14)"
              sorting={makeSortable('createdAt')}
              format={(value: string): string => new Datetime().setAsUTC(value).toLocaleDatetime().frontendDatetime}
            />
          </VirtualTableRow>
        )}
      />
    </>
  );
};

export default InvoiceHistoryTable;
