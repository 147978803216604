import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import Tooltip from '@/components/Tooltip';
import { Trip } from '@/models/gen/graphql';
import { useCallback } from 'react';
import { useEditCombineModal } from '@/pages/Trips/EditCombine';
import { useSimpleTableRowContext } from '@/components/SimpleTable/SimpleTableRow';
import { useTripsTableContext } from '@/features/Trips/components/TripsTable';

const CombineCell = (): JSX.Element => {
  const { data: { id, servicerIataAirlineCode, flightNumber, scheduled, stopCount, stops = [], combineId, combineType } = {} } =
    useSimpleTableRowContext<Trip>();
  const showEditCombineModal = useEditCombineModal(({ setState }) => setState);
  const { onRefetch } = useTripsTableContext();

  const onClick = useCallback(
    () =>
      showEditCombineModal({
        show: true,
        servicerIataAirlineCode: servicerIataAirlineCode,
        flightNumber: flightNumber,
        scheduled: scheduled,
        combineId: combineId,
        tripId: id,
        onSubmit: onRefetch,
      }),
    [showEditCombineModal, servicerIataAirlineCode, flightNumber, scheduled, combineId, id, onRefetch]
  );
  return (
    <SimpleTableCell name="COMBINE" className="w-sm-fixed d-print-none">
      {(stopCount ?? stops?.length) > 0 && (
        <Tooltip content="Loop">
          <i className="sv sv-sync fs-5 text-cyan {text-shadow:-0.02rem|0|var(--bs-cyan),|0|0.02rem|var(--bs-cyan),|0.02rem|0|var(--bs-cyan),|0|-0.02rem|var(--bs-cyan);}" />
        </Tooltip>
      )}
      {!(stopCount ?? stops?.length) && (
        <Tooltip content="Combine">
          <Button variant="icon" onClick={onClick}>
            <i className={`sv sv-combine-icon fs-5 ${combineId || !!combineType ? 'text-green' : 'text-secondary'}`} />
          </Button>
        </Tooltip>
      )}
    </SimpleTableCell>
  );
};
export default CombineCell;
