import { HTMLAttributes, RefObject, createContext, forwardRef, useContext, useMemo } from 'react';

import Visible from '@/components/Visible';
import { getClasses } from '@/utils';
import { useSimpleTableContext } from '@/components/SimpleTable';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';

export type SimpleTableRowProps = { data?: unknown; header?: boolean } & HTMLAttributes<HTMLDivElement>;
export type SimpleTableRowContextValue = {
  id: string;
  data: unknown;
  setData: (update: unknown) => void;
  isSelected: boolean;
};
export const SimpleTableRowContext = createContext({
  id: undefined,
  data: undefined,
  setData: (_update: unknown): void => {},
  isSelected: false,
});
export const useSimpleTableRowContext = <T,>(): SimpleTableRowContextValue & { data: T } =>
  useContext<SimpleTableRowContextValue & { data: T }>(SimpleTableRowContext);

const ROW_VISIBLE_SETTINGS = { rootMargin: `${window.visualViewport.height}px 0px` };

const SimpleTableRow = ({ data, header, ...divProps }: SimpleTableRowProps, ref: RefObject<HTMLDivElement>): JSX.Element => {
  const { printing } = useSimpleTableContext();
  const isSelected = useTripTableState(({ state }) => !!state.selected.get(divProps.id));
  const simpleTableContextValue = useMemo(
    (): SimpleTableRowContextValue & { data: typeof data } => ({ id: divProps.id, data, setData: (): void => {}, isSelected }),
    [data, divProps.id, isSelected]
  );

  return (
    <SimpleTableRowContext.Provider value={simpleTableContextValue}>
      <Visible
        id={data?.['id'] || divProps?.id || undefined}
        {...divProps}
        className={getClasses('SimpleTable-Row', divProps?.className)}
        disabled={(header !== undefined && header !== false) || printing}
        options={ROW_VISIBLE_SETTINGS}
        ref={ref}
      />
    </SimpleTableRowContext.Provider>
  );
};

export default forwardRef(SimpleTableRow);
