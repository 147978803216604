import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

import Sticky from 'react-sticky-el';
import { getClasses } from '@/utils';

export type SimpleTableHeaderProps = {
  title?: string;
  shortcuts?: JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

const SimpleTableHeader = forwardRef(function SimpleTableHeader(
  { title, shortcuts, children, ...divProps }: SimpleTableHeaderProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  return (
    <div {...divProps} className={getClasses('SimpleTable-Header', divProps.className)} ref={ref}>
      {(title || shortcuts) && (
        <Sticky scrollElement=".RouteContent" boundaryElement=".SimpleTable">
          <div className="SimpleTable-Titlebar">
            {title && <div className="SimpleTable-Title">{title}</div>}
            {shortcuts && <div className="SimpleTable-Shortcuts">{shortcuts}</div>}
          </div>
        </Sticky>
      )}
      {children && (
        <Sticky scrollElement=".RouteContent" boundaryElement=".SimpleTable">
          <div className="SimpleTable-Columns">{children}</div>
        </Sticky>
      )}
    </div>
  );
});

export default SimpleTableHeader;
