// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportProblem {
  padding: 0 0 1rem 0;
}
.ReportProblem .container {
  padding: 0 100px;
}
.ReportProblem .ReportProblem-Image {
  position: relative;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
}
.ReportProblem .ReportProblem-Image > button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
}
.ReportProblem .TextArea {
  height: 100px;
  resize: none !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ReportProblem/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AAEJ;AADI;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAGN;AAAE;EACE,aAAA;EACA,uBAAA;AAEJ","sourcesContent":[".ReportProblem {\n  padding: 0 0 1rem 0;\n}\n.ReportProblem .container {\n  padding: 0 100px;\n}\n.ReportProblem .ReportProblem-Image {\n  position: relative;\n  width: 100px;\n  height: 100px;\n  background-size: cover;\n  background-position: center;\n}\n.ReportProblem .ReportProblem-Image > button {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: -10px;\n  right: -10px;\n  width: 20px;\n  height: 20px;\n}\n.ReportProblem .TextArea {\n  height: 100px;\n  resize: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
