import { TrackFlight, TrackFlightLabelEnum, TrackFlightTypeEnum, UpdateResponse, UpdateTrackFlightBulkInput } from '@/models/gen/graphql';
import { Validation, generateUpdateBulkPayload, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { UpdateTrackFlightBulkDocument } from '@/api/queries';
import graphApi from '@/api';

type UpdateTrackFlightBulkGraphApiResponse = GraphApiResponse<typeof UpdateTrackFlightBulkDocument>;

const title = 'Update Track Flights';

const updateTrackFlightValidator = new Validation.Validator({
  'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  scheduled: (val: any): Validation.Validity => ({ valid: !!val }),
  actual: (val: any): Validation.Validity => ({ valid: !!val }),
  arrivalGate: (val: any): Validation.Validity => ({ valid: !!val }),
  departureGate: (val: any): Validation.Validity => ({ valid: !!val }),
  servicerIataAirlineCode: (val: any): Validation.Validity => ({ valid: !!val }),
  number: (val: any): Validation.Validity => ({ valid: Validation.isNumber(val) }),
  type: (val: TrackFlightTypeEnum): Validation.Validity => ({ valid: !!val }),
  trackerProvider: (val: any): Validation.Validity => ({ valid: val === 'portal', defaultValue: 'portal' }), // always set to portal
  lastPingTime: (val: any): Validation.Validity => ({ valid: !!val }),
  nextPingTime: (val: any): Validation.Validity => ({ valid: !!val }),
  status: (val: any): Validation.Validity => ({ valid: !!val }),
  label: (val: TrackFlightLabelEnum): Validation.Validity => ({ valid: !!val }),
  departureTimezone: (val: any): Validation.Validity => ({ valid: !!val }),
  arrivalTimezone: (val: any): Validation.Validity => ({ valid: !!val }),
  departureUtcOffsetHours: (val: any): Validation.Validity => ({ valid: !!val }),
  arrivalUtcOffsetHours: (val: any): Validation.Validity => ({ valid: !!val }),
  arrivalTerminal: (val: any): Validation.Validity => ({ valid: !!val }),
  departureTerminal: (val: any): Validation.Validity => ({ valid: !!val }),
  tripId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
});

const updateTrackFlightBulkResponseSelector = (res: UpdateTrackFlightBulkGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.updateTrackFlightBulk?.response || [] : undefined;

const [runUpdateTrackFlightBulk] = graphApi(UpdateTrackFlightBulkDocument, {
  onError: (res: UpdateTrackFlightBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateTrackFlightBulk = async (trackFlights: TrackFlight[]): Promise<UpdateResponse[]> => {
  const validatedTrackFlights = trackFlights?.map(
    (trackFlight: TrackFlight): TrackFlight => updateTrackFlightValidator.partial(trackFlight)
  );
  const input: UpdateTrackFlightBulkInput = { updates: generateUpdateBulkPayload(validatedTrackFlights) };
  const res = await runUpdateTrackFlightBulk({ input });
  return updateTrackFlightBulkResponseSelector(res);
};

export default updateTrackFlightBulk;
